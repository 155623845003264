import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'roctavian-eumea-portal-cancel-patient-registration-dialog',
  templateUrl: './cancel-patient-registration-dialog.component.html',
  styleUrls: ['./cancel-patient-registration-dialog.component.scss'],
})
export class CancelPatientRegistrationDialogComponent {
  @Output() onYesClicked = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<CancelPatientRegistrationDialogComponent>
  ) {}

  confirm() {
    this.onYesClicked.emit();
    this.reference.close(true);
  }
}
