import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { LocaleService } from '../../../shared/services/locale.service';

@Component({
  selector: 'roctavian-eumea-portal-resources-page',
  templateUrl: './resources-page.component.html',
  styleUrls: ['./resources-page.component.scss'],
})
export class ResourcesPageComponent implements OnInit {
  constructor(
    private translate: AppTranslationService,
    private localeService: LocaleService
  ) {}

  ngOnInit() {}

  get haemdifferntlyUrl(): string {
    return this.translate.getTranslation('ResourcesPage.haemDifferentlyUrl');
  }

  get bioMarinHaemUrl(): string {
    return this.translate.getTranslation('ResourcesPage.bioMarinHaemUrl');
  }

  get haemDifferentlyImgName(): string {
    return `/assets/images/${this.translate.getTranslation(
      'ResourcesPage.haemDifferentlyImgName'
    )}`;
  }

  get bioMarinHaemImgName(): string {
    return `/assets/images/${this.translate.getTranslation(
      'ResourcesPage.bioMarinHaemImgName'
    )}`;
  }

  get isSourcesPageAvailable(): boolean {
    if (!this.localeService.ipBasedCountry) {
      return false;
    }

    if (
      this.localeService.ipBasedCountry &&
      (this.localeService.ipBasedCountry.name.toLowerCase() == 'ar' ||
       this.localeService.ipBasedCountry.name.toLowerCase() == 'fr' ||
        this.translate.getTranslation('ResourcesPage.haemDifferentlyUrl') == '')
    ) {
      return false;
    }

    return true;
  }
}
