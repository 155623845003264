import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppTranslationService, SystemKeyService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { GenderTypes } from '../../patient/enums/gender-types.enum';
import { getStatusChangeReasonName } from '../constants';
import { ChangeReasonTypes, StatusTypes } from '../enums';
import { Attestation, PagedQuery, Status } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UtilityService implements OnDestroy {
  private loadedSystemKeysSubjectSubscription: Subscription;
  private localStorage: Storage = localStorage;
  private sessionStorage: Storage = sessionStorage;

  constructor(
    private systemKeyService: SystemKeyService,
    private translate: AppTranslationService
  ) {}

  ngOnDestroy() {
    this.loadedSystemKeysSubjectSubscription.unsubscribe();
  }

  getCurrentStatus(statuses: Status[]): Status {
    if (!statuses) {
      return null;
    }

    return statuses.find(x => x.isCurrent);
  }

  getStakeholderStatus(status: Status): string {
    return this.getStatusName(status ? status.statusType : StatusTypes.Unknown);
  }

  getStatusName(statusType: StatusTypes): string {
    switch (statusType) {
      case StatusTypes.Pending:
        return this.translate.getTranslation('Common.Status.Pending');
      case StatusTypes.Registered:
        return this.translate.getTranslation('Common.Status.Registered');
      case StatusTypes.PreRegistered:
        return this.translate.getTranslation('Common.Status.PreRegistered');
      case StatusTypes.Inactive:
        return this.translate.getTranslation('Common.Status.Inactive');
      case StatusTypes.Incomplete:
        return this.translate.getTranslation('Common.Status.Incomplete');
      case StatusTypes.LabTestIncomplete:
        return this.translate.getTranslation('Common.Status.Incomplete');
      case StatusTypes.LabTestComplete:
        return this.translate.getTranslation('Common.Status.LabTestComplete');
      case StatusTypes.LabTestOrdered:
        return this.translate.getTranslation('Common.Status.LabTestOrdered');
      case StatusTypes.LabTestSampleShipped:
        return this.translate.getTranslation('Common.Status.LabTestSampleShipped');
      case StatusTypes.LabTestSampleReceived:
        return this.translate.getTranslation('Common.Status.LabTestSampleReceived');
      case StatusTypes.LabTestResultReceived:
        return this.translate.getTranslation('Common.Status.LabTestResultReceived');
      case StatusTypes.LabTestCancelled:
        return this.translate.getTranslation('Common.Status.LabTestCancelled');
      case StatusTypes.Unknown:
      default:
        return this.translate.getTranslation('Common.Status.Unknown');
    }
  }

  getStatusChangeReason(status: Status): string {
    return getStatusChangeReasonName(
      status ? status.changeReasonType : ChangeReasonTypes.Unknown
    );
  }

  getCurrentAttestation(attestations: Attestation[]): Attestation {
    if (!attestations) {
      return null;
    }

    return attestations.find(x => x.isCurrent);
  }

  toHttpParams(pagedQuery: PagedQuery): HttpParams {
    let params = new HttpParams();
    Object.keys(pagedQuery).forEach(key => {
      const value = pagedQuery[key];
      if (value === null || value === undefined) {
        return;
      }

      if (value instanceof Date) {
        params = params.append(key, pagedQuery[key].toDateString());
      }
      if (key == 'filterText') {
        params = params.append(key, pagedQuery[key]);
      } else {
        params = params.append(key, encodeURIComponent(pagedQuery[key]));
      }
    });

    return params;
  }

  getGenderName(genderType: GenderTypes): string {
    switch (genderType) {
      case GenderTypes.Unknown:
        return this.translate.getTranslation('Common.Unknown');
      case GenderTypes.Male:
        return this.translate.getTranslation('Common.Male');
      case GenderTypes.Female:
        return this.translate.getTranslation('Common.Female');
      case GenderTypes.Other:
        return this.translate.getTranslation('Common.Other');
      default:
        return '';
    }
  }

  hasDialogBeenSeen() {
    return JSON.parse(this.sessionStorage.getItem('firsttime'));
  }
  markDialogAsSeen() {
    this.sessionStorage.setItem('firsttime', 'true');
  }

  hasCookieBeenSeen() {
    return JSON.parse(this.localStorage.getItem('cookiestatement'));
  }
  markCookieAsSeen() {
    this.localStorage.setItem('cookiestatement', 'true');
  }
}
