import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PatientPrescriber } from '../../../patient/models/patient-prescriber.model';
import { PrescriberDelegate } from '../../../prescriber-delegate/models/prescriber-delegate.model';
import { PrescriberDelegateService } from '../../../prescriber-delegate/services/prescriber-delegate.service';
import { PagedQuery } from '../../../shared';
import { UtilityService } from '../../../shared/services/utility.service';

@Component({
  selector: 'prescriber-delegate-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PrescriberDelegatePatientListComponent implements OnInit, OnDestroy {
  pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  private authenticatedPrescriberDelegate: PrescriberDelegate;

  displayedColumns = [
    'name',
    'id',
    'birthDate',
    'stakeholder.attestations.hasConsent',
    'stakeholder.statuses.statusType',
  ];

  constructor(
    public prescriberDelegateService: PrescriberDelegateService,
    public utilityService: UtilityService
  ) {}

  refreshList() {
    if (this.authenticatedPrescriberDelegate) {
      this.prescriberDelegateService.setPatientPrescribersPageByPrescriberDelegateId(
        this.pagedQuery,
        this.authenticatedPrescriberDelegate.id
      );
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.prescriberDelegateService.loggedInPrescriberDelegateSubject.subscribe(
        prescriberDelegate => {
          this.authenticatedPrescriberDelegate = prescriberDelegate;
          this.refreshList();
        }
      )
    );

    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberDelegateService.patientPrescriberDelegatePagedListSubject.next(
      new PaginatedList<PatientPrescriber>()
    );
  }

  onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }
}
