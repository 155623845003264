import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSort, PageEvent } from '@angular/material';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LaboratoryUserClient } from '../../../laboratory-user/clients/laboratory-user.client';
import { LabTest } from '../../../laboratory-user/models/lab-test.model';
import { PrescriberLab } from '../../../prescriber/models/prescriber-lab.model';
import { PrescriberService } from '../../../prescriber/services/prescriber.service';
import { PagedQuery } from '../../../shared';
import { UtilityService } from '../../../shared/services/utility.service';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';
import { PrescriberDelegateService } from '../../services/prescriber-delegate.service';

@Component({
  selector: 'prescriber-delegate-lab-order-list',
  templateUrl: './lab-order-list.component.html',
  styleUrls: ['./lab-order-list.component.scss'],
})
export class LabOrderListComponent implements OnInit, OnDestroy {
  prescriberDelegate: PrescriberDelegate;
  prescriberOfficeLabs: PrescriberLab[] = [];
  pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  displayedColumns = [
    'patient.stakeholder.id',
    'patient.currentLabTest.id',
    'patient.stakeholder.firstName',
    'patient.stakeholder.lastName',
    'prescriber.stakeholder.firstName',
    'prescriber.stakeholder.lastName',
    'prescriber.id',
    'dateAdded',
    'stakeholder.statuses.statusType',
    'specimenCollectionDateTime',
    'menu',
  ];

  constructor(
    public utilityService: UtilityService,
    private labUserClient: LaboratoryUserClient,
    public prescriberService: PrescriberService,
    public prescriberDelegateService: PrescriberDelegateService
  ) {}

  private refreshList() {
    if (this.prescriberOfficeLabs.length === 0) {
      return;
    }
    
    this.prescriberService.setLabsTestPageForPrescriberDelegate(
      this.pagedQuery,
      this.prescriberDelegate.id
    );
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.prescriberDelegateService.loggedInPrescriberDelegateSubject.subscribe(
        prescriberDelegate => {
          this.prescriberDelegate = prescriberDelegate;

          if (
            this.prescriberDelegate &&
            this.prescriberDelegate.prescriberDelegatePrescribers
          ) {
            this.prescriberDelegate.prescriberDelegatePrescribers.forEach(presc => {
              if (presc.prescriber.prescriberLabs) {
                presc.prescriber.prescriberLabs.forEach(lab => {
                  if (lab.isPrescriberOffice) {
                    this.prescriberOfficeLabs.push(lab);
                  }
                });
              }
            });
          }

          this.refreshList();
        }
      )
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.labTestPagedListSubject.next(new PaginatedList<LabTest>());
  }

  onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'patient.stakeholder.id':
        this.pagedQuery.sortColumn = 'patient.stakeholder.externalId';
        break;
      case 'patient.currentLabTest.id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      case 'prescriber.id':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.externalId';
        break;
      case 'dateAdded':
        this.pagedQuery.sortColumn = 'stakeholder.addDate';
        break;
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn =
          'stakeholder.statuses.FirstOrDefault(IsCurrent).statusType';
        break;
      case 'stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn =
          'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  printLabTest(labTestId: string) {
    this.labUserClient.downloadShipmentLabel(labTestId);
  }
}
