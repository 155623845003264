import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'portal-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private translate: AppTranslationService) {}

  ngOnInit() {}

  get privacyPolicyHref(): string {
    return `assets/pdf/${this.translate.getTranslation('Common.PrivacyPolicyPdfName')}`;
  }
}
