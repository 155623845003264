import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'prescriber-delegate-my-patients-page',
  templateUrl: './my-patients-page.component.html',
  styleUrls: ['./my-patients-page.component.scss'],
})
export class PrescriberDelegateMyPatientsPageComponent implements OnInit {
  isLaboratoryDelegateUser: boolean = false;

  constructor(private translate: AppTranslationService) {}

  ngOnInit() {}

  get patientFormPath(): string {
    return `/assets/pdf/${this.translate.getTranslation(
      'Common.PatientRegistrationFormPath'
    )}`;
  }

  get labFormPath(): string {
    return `/assets/pdf/${this.translate.getTranslation(
      'Common.LabRegistrationFormPath'
    )}`;
  }
}
