import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { Subscription } from 'rxjs';
import { PatientService } from '../../service/PatientService';

@Component({
  selector: 'roctavian-eumea-portal-register-patient-page',
  templateUrl: './register-patient-page.component.html',
  styleUrls: ['./register-patient-page.component.scss'],
})
export class RegisterPatientPageComponent implements OnInit, OnDestroy {
  isLabUser = false;

  isConsentStepComplete = false;
  isPatientConsentFormUploaded = false
  isConsentStepInProgress = false;

  isLabInformationStepInProgress = false;
  isLabInformationStepComplete = false;

  isPatientInformationStepComplete = false;
  isPatientInformationStepInProgress = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private patientService: PatientService,
  ) {}

  ngOnInit() {
    this.refreshStepFlags();
    this.subscriptions.push(
      this.router.events.subscribe(routerEvent => {
        this.refreshStepFlags();
      })
    );

    if (this.authService.claimsPrincipal.hasRole('LaboratoryUser')) {
      this.isLabUser = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private refreshStepFlags() {
    this.patientService.patientConsentFormUploaded.subscribe(isUploaded=>
      this.isPatientConsentFormUploaded = isUploaded)
    this.isConsentStepComplete =
      this.router.url.indexOf('/complete') !== -1 ||
      this.router.url.indexOf('/lab-information') !== -1;
    this.isConsentStepInProgress = this.router.url.indexOf('/consent') !== -1;

    this.isLabInformationStepComplete = this.router.url.indexOf('/complete') !== -1;
    this.isLabInformationStepInProgress =
      this.router.url.indexOf('/lab-information') !== -1;

    this.isPatientInformationStepComplete =
      this.isConsentStepInProgress ||
      this.isLabInformationStepInProgress ||
      this.isConsentStepComplete ||
      this.isLabInformationStepComplete;
    this.isPatientInformationStepInProgress =
      this.router.url.indexOf('/patient-information') !== -1;
  }
}
