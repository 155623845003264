import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PatientClient } from '../clients/patient.client';
import { PatientPagedQuery } from '../models/patient-paged-query.model';
import { Patient } from '../models/patient.model';

@Injectable({
  providedIn: 'root',
})
export class PatientService implements OnDestroy {
  pagedListSubject = new BehaviorSubject<PaginatedList<Patient>>(
    new PaginatedList<Patient>()
  );
  pagedListDocumentsSubject = new BehaviorSubject<PaginatedList<Document>>(
    new PaginatedList<Document>()
  );
  isLoadingSubject = new BehaviorSubject<boolean>(false);

  selectedPatientSubject = new BehaviorSubject<Patient>(null);
  patientConsentFormUploaded = new BehaviorSubject<boolean> (false);

  private getPagedListSubscription: Subscription;
  private getPagedListDocumentsSubscription: Subscription;
  private getPatientSubscription: Subscription;

  constructor(
    private patientClient: PatientClient,
    private translationService: AppTranslationService,
    private httpClient: HttpClient
  ) {}

  setPage(pagedQuery: PatientPagedQuery) {
    this.isLoadingSubject.next(true);

    if (this.getPagedListSubscription) {
      this.getPagedListSubscription.unsubscribe();
      this.getPagedListSubscription = null;
    }

    this.getPagedListSubscription = this.patientClient
      .getPagedList(pagedQuery)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            return;
          }

          this.pagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.isLoadingSubject.next(false);
        }
      );
  }

  ngOnDestroy() {
    if (this.getPagedListSubscription) {
      this.getPagedListSubscription.unsubscribe();
    }

    if (this.getPatientSubscription) {
      this.getPatientSubscription.unsubscribe();
    }
  }

  clearService() {
    this.pagedListSubject.next(new PaginatedList<Patient>());
    this.pagedListDocumentsSubject.next(new PaginatedList<Document>());
    this.selectedPatientSubject.next(null);
  }
}
