import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import {
  Outcome,
  PaginatedList,
  ValueOutcome,
} from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { PatientPrescriber } from '../../patient/models/patient-prescriber.model';
import { PagedQuery } from '../../shared';
import { UtilityService } from '../../shared/services/utility.service';
import {
  PrescriberDelegate,
  PrescriberDelegatePrescriber,
} from '../models/prescriber-delegate.model';

@Injectable({
  providedIn: 'root',
})
export class PrescriberDelegateClient {
  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) {}

  createPrescriberDelegate(
    prescriberDelegate: PrescriberDelegate
  ): Observable<ValueOutcome<string>> {
    return this.httpClient.post<ValueOutcome<string>>(
      Endpoints.prescriberDelegate,
      prescriberDelegate
    );
  }

  updatePrescriberDelegate(
    prescriberDelegate: PrescriberDelegate
  ): Observable<Outcome> {
    return this.httpClient.put<Outcome>(
      Endpoints.prescriberDelegate,
      prescriberDelegate
    );
  }

  getPrescriberDelegate(
    prescriberDelegateId: string
  ): Observable<ValueOutcome<PrescriberDelegate>> {
    const url = `${Endpoints.prescriberDelegate}/${prescriberDelegateId}`;
    return this.httpClient.get<ValueOutcome<PrescriberDelegate>>(url);
  }

  getPrescriberDelegateByEmail(
    email: string
  ): Observable<ValueOutcome<PrescriberDelegate>> {
    const url = `${
      Endpoints.prescriberDelegate
    }/getByEmail?email=${encodeURIComponent(email)}`;
    return this.httpClient.get<ValueOutcome<PrescriberDelegate>>(url);
  }

  getEmailDuplicateCheck(email: string): Observable<ValueOutcome<boolean>> {
    const url = `${
      Endpoints.prescriberDelegate
    }/duplicateEmailCheck?email=${encodeURIComponent(email)}`;
    return this.httpClient.get<ValueOutcome<boolean>>(url);
  }

  getPrescriberDelegatePrescriberListPaged(
    prescriberDelegateId: string,
    pagedQuery: PagedQuery
  ): Observable<ValueOutcome<PaginatedList<PrescriberDelegatePrescriber>>> {
    const url = `${
      Endpoints.prescriberDelegate
    }/${prescriberDelegateId}/prescriber`;
    return this.httpClient.get<
      ValueOutcome<PaginatedList<PrescriberDelegatePrescriber>>
    >(url, { params: this.utilityService.toHttpParams(pagedQuery) });
  }

  getPatientPrescriberDelegateListPaged(
    pagedQuery: PagedQuery,
    prescriberDelegateId: string
  ): Observable<ValueOutcome<PaginatedList<PatientPrescriber>>> {
    const url = `${
      Endpoints.prescriberDelegate
    }/${prescriberDelegateId}/prescriber-patient`;
    return this.httpClient.get<ValueOutcome<PaginatedList<PatientPrescriber>>>(
      url,
      {
        params: this.utilityService.toHttpParams(pagedQuery),
      }
    );
  }
}
