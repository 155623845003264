import { User } from '@roctavian-abstractions/identity';
import { StakeholderTypes } from '../enums/stakeholder-types.enum';
import { Auditable } from '../interfaces/auditable.interface';
import { Address } from './address.model';
import { Attestation } from './attestation.model';
import { Status } from './status.model';

export class Stakeholder implements Auditable {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  statuses: Status[];
  address: Address;
  attestations: Attestation[];
  notes: string;
  email: string;
  phone: string;
  fax: string;
  user: User;
  stakeholderType: StakeholderTypes;
  addUser: User;
  addDate: Date;
  editUser: User;
  editDate: Date;
  localeId: string;
  externalId: number;
}
