import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MaterialModule, PipeModule, CoreDirectivesModule } from '@roctavian-abstractions/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule, MatInputModule, MatDatepickerModule } from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    CoreDirectivesModule,
    SharedModule,
    MatExpansionModule,
    MatInputModule,
    MatDatepickerModule
  ],
  exports: []
})
export class LabModule { }
