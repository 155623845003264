import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSelectChange } from '@angular/material';
import { Router } from '@angular/router';
import { AppTranslationService, Locale } from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { LaboratoryUserService } from '../../modules/laboratory-user/service/laboratory-user.service';
import { PrescriberService } from '../../modules/prescriber/services/prescriber.service';
import { LeavingSiteDialogComponent } from '../../modules/public-insecure/components/leaving-site-dialog/leaving-site-dialog.component';
import { LocaleService } from '../../modules/shared/services/locale.service';
import { StakeholderService } from '../../modules/shared/services/stakeholder.service';

@Component({
  selector: 'portal-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;
  collapsed: boolean = true;
  isPrescriber: boolean = false;
  isPrescriberDelegate: boolean = false;

  private subscriptions = new Array<Subscription>();
  selectedLocaleCode: Locale;

  get displayName() {
    const userInfo = this.authService.userInfo;
    return `${userInfo.firstName} ${userInfo.lastName}`;
  }

  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    public translationService: AppTranslationService,
    public stakeholderService: StakeholderService,
    public prescriberService: PrescriberService,
    public laboratoryUserService: LaboratoryUserService,
    public localeService: LocaleService,
    private ccService: NgcCookieConsentService,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.translationService.languageChanged$.subscribe(language => {
        this.selectedLocaleCode = this.translationService.supportedLocaleList.find(
          x => x.localeCode == language
        );
        this.configureCookieConsent();
      })
    );

    this.configureCookieConsent();

    this.subscriptions.push(
      this.authService.isLoggedIn().subscribe(authenticated => {
        this.isAuthenticated = authenticated;

        if (authenticated) {
          this.isPrescriber = this.authService.claimsPrincipal.hasRole('Prescriber');
          this.isPrescriberDelegate = this.authService.claimsPrincipal.hasRole(
            'PrescriberDelegate'
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  toggleMenu() {
    this.collapsed = !this.collapsed;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  localeSelectionChanged(selectionChange: MatSelectChange) {
    if (!selectionChange.value) {
      return;
    }

    const locale: Locale = selectionChange.value;
    if (!locale) {
      return;
    }
    this.translationService.setLocaleById(locale.id);
  }

  configureCookieConsent() {
    this.translationService
      .getTranslationAsync([
        'cookie.header',
        'cookie.message',
        'cookie.dismiss',
        'cookie.allow',
        'cookie.deny',
        'cookie.link',
        'cookie.policy',
      ])
      .subscribe(data => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];

        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());
      });
  }

  instructionsForUseHref() {
    const dialogRef = this.matDialog.open(LeavingSiteDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      width: '500px',
    });
  }

  safetyDataHref() {
    const dialogRef = this.matDialog.open(LeavingSiteDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      width: '500px',
    });
  }

  get prescriberInformationHref(): string {
    return this.translationService.getTranslation(
      'Navbar.PrescriberInformationPdf'
    );
  }

}
