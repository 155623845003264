import { Injectable } from '@angular/core';
import {
  AppTranslationService,
  Country,
  CountryClient,
  Locale,
} from '@roctavian-abstractions/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { IPAddressClient } from '../clients/ip-address.client';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  ipAddressSubject = new BehaviorSubject<string>(null);
  ipBasedCountrySubject = new BehaviorSubject<Country>(null);
  supportedCountryListSubject = new BehaviorSubject<Country[]>([]);

  constructor(
    private ipAddressClient: IPAddressClient,
    private translate: AppTranslationService,
    private countryClient: CountryClient
  ) {}

  loadIPAddress() {
    this.ipAddressClient.getIPAddress().subscribe(response => {
      this.ipAddressSubject.next(response.ip);

      forkJoin({
        getCountryListOutcome: this.countryClient.getSupportedCountryList(),
        getCountryByIpOutcome: this.countryClient.getCountryByIp(response.ip),
      }).subscribe(results => {
        this.supportedCountryListSubject.next(results.getCountryListOutcome.value);
        this.ipBasedCountrySubject.next(results.getCountryByIpOutcome.value);

        //set the default locale to en-de if the user country is not supported
        const defaultCountry = this.supportedCountryList.find(
          country => country.name === 'DE'
        );
        const defaultLocaleId = defaultCountry.locales.find(
          locale => locale.localeCode === 'en-de'
        ).id;
        if (!this.ipBasedLocale.isSupported) {
          this.translate.setLocaleById(defaultLocaleId);
        }

        const currentLocale = this.translate.getCurrentLocale();
        if (
          this.isUserCountrySupported &&
          this.ipBasedCountry.locales.filter(x => x.id == currentLocale.id).length == 0
        ) {
          this.translate.setLocaleById(this.ipBasedLocale.id);
        }
      });
    });
  }

  get ipBasedCountry(): Country {
    return this.ipBasedCountrySubject.value;
  }

  get isUserCountrySupported(): boolean {
    if (!this.ipBasedCountry) {
      return false;
    }

    return (
      this.supportedCountryList.filter(x => x.name == this.ipBasedCountry.name).length > 0
    );
  }

  get ipBasedLocale(): Locale {
    if (
      !this.ipBasedCountry ||
      !this.ipBasedCountry.locales ||
      this.ipBasedCountry.locales.length === 0
    ) {
      return null;
    }

    let locale = this.ipBasedCountry.locales.find(x => x.isDefault);
    if (!locale) {
      locale = this.ipBasedCountry.locales[0];
    }
    return locale;
  }

  getLocaleByCountry(countryId: string): Locale {
    if (!countryId) {
      return null;
    }

    const country = this.supportedCountryListSubject.value.find(
      c => c.id.toLowerCase() == countryId.toLowerCase()
    );

    if (!country || !country.locales || country.locales.length === 0) {
      return null;
    }

    let locale = country.locales.find(x => x.isDefault);
    if (!locale) {
      locale = country.locales[0];
    }

    return locale;
  }

  get supportedCountryList(): Country[] {
    return this.supportedCountryListSubject.value;
  }
}
