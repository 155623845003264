import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { LabUser, LabUserLab } from '../../laboratory-user/models/lab-user.model';
import { Prescriber } from '../../prescriber/models/prescriber.model';
import { PagedQuery } from '../../shared';
import { UtilityService } from '../../shared/services/utility.service';
import { LabDuplicateCheckQuery } from '../models/lab-duplicate-check-query.model';
import { LabPagedQuery } from '../models/lab-paged-query.model';
import { LabPrescriber } from '../models/lab-prescriber.model';
import { LabTest } from '../models/lab-test.model';
import { Lab } from '../models/lab.model';

@Injectable({
  providedIn: 'root',
})
export class LabClient {
  constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

  getPagedList(pagedQuery: LabPagedQuery): Observable<ValueOutcome<PaginatedList<Lab>>> {
    return this.httpClient.get<Outcome>(Endpoints.lab, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createLab(lab: Lab): Observable<ValueOutcome<string>> {
    return this.httpClient.post<Outcome>(Endpoints.lab, lab);
  }

  updateLab(lab: Lab): Observable<Outcome> {
    return this.httpClient.put<Outcome>(Endpoints.lab, lab);
  }

  getLab(id: string): Observable<ValueOutcome<Lab>> {
    const url = `${Endpoints.lab}/${id}`;
    return this.httpClient.get<Outcome>(url);
  }

  getLabDuplicates(
    pagedQuery: LabDuplicateCheckQuery
  ): Observable<ValueOutcome<PaginatedList<Lab>>> {
    const url = `${Endpoints.lab}/duplicate-check`;
    return this.httpClient.get<ValueOutcome<PaginatedList<Lab>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getLabUserListPaged(
    pagedQuery: PagedQuery,
    labId: string
  ): Observable<ValueOutcome<PaginatedList<LabUserLab>>> {
    const url = `${Endpoints.lab}/${labId}/LabUser`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabUserLab>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getUnassociatedLabUsersPaged(
    pagedQuery: PagedQuery
  ): Observable<ValueOutcome<PaginatedList<LabUser>>> {
    return this.httpClient.get<Outcome>(`${Endpoints.lab}/LabUser/GetUnassociated`, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPrescriberLabListPaged(
    pagedQuery: PagedQuery,
    labId: string
  ): Observable<ValueOutcome<PaginatedList<LabPrescriber>>> {
    const url = `${Endpoints.lab}/${labId}/prescribers`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabPrescriber>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPrescriber(prescriberId: string): Observable<ValueOutcome<Prescriber>> {
    const url = `${Endpoints.prescriber}/${prescriberId}`;
    return this.httpClient.get<Outcome>(url);
  }

  getLabTestResultDocumentStorageUri(
    labTestId: string
  ): Observable<ValueOutcome<string>> {
    const url = `${Endpoints.labTest}/${labTestId}/result/document-storage-uri`;
    return this.httpClient.get<ValueOutcome<string>>(url);
  }

  downloadLabExport() {
    const url = `${Endpoints.lab}/getAllLabs`;
  }

  createLabUserLabAssociation(labUserLab: LabUserLab): Observable<Outcome> {
    const url = `${Endpoints.lab}/LabUser/Associate`;
    return this.httpClient.post<Outcome>(url, labUserLab);
  }

  disassociateLabUser(labId: string, labUserId: string): Observable<Outcome> {
    const url = `${Endpoints.lab}/${labId}/LabUser/Disassociate/${labUserId}`;
    return this.httpClient.delete<Outcome>(url, {});
  }

  getExportFile() {
    const url = `${Endpoints.lab}/export`;

    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getLabTestsByLab(
    pagedQuery: PagedQuery,
    id: string
  ): Observable<ValueOutcome<PaginatedList<LabTest>>> {
    const url = `${Endpoints.labTest}/${id}/lab-tests`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabTest>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getLabsTestPageForPrescriberDelegate(
    pagedQuery: PagedQuery,
    id: string
  ): Observable<ValueOutcome<PaginatedList<LabTest>>> {
    const url = `${Endpoints.labTest}/${id}/lab-test-prescriber-delegate`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabTest>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }
}
