import { User } from '@roctavian-abstractions/identity';
import { ChangeReasonTypes } from '../enums/change-reason-types.enum';
import { StatusTypes } from '../enums/status-types.enum';
import { Auditable } from '../interfaces/auditable.interface';

export class Status implements Auditable {
  id: string;
  isCurrent: boolean;
  statusType: StatusTypes;
  changeReasonDescription: string;
  changeReasonType: ChangeReasonTypes;
  stakeholderId: string;

  addUser: User;
  addDate: Date;
  editUser: User;
  editDate: Date;
}
