import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { LaboratoryUserClient } from '../../../laboratory-user/clients/laboratory-user.client';

@Component({
  selector: 'roctavian-eumea-portal-complete-lab-order-page',
  templateUrl: './complete-lab-order-page.component.html',
  styleUrls: ['./complete-lab-order-page.component.scss'],
})
export class CompleteLabOrderPageComponent implements OnInit, OnDestroy {
  private labTestId: string;
  private subscriptions = new Array<Subscription>();

  constructor(
    private translate: AppTranslationService,
    private labUserClient: LaboratoryUserClient,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.labTestId = params['id'];
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  print() {
    this.labUserClient.downloadShipmentLabel(this.labTestId);
  }
}
