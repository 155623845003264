import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { forkJoin, Subscription } from 'rxjs';
import { StakeholderTypes } from '../../../shared/enums';
import { LocaleService } from '../../../shared/services/locale.service';
import { PrescriberClient } from '../../clients/prescriber.client';
import { Prescriber } from '../../models/prescriber.model';

@Component({
  selector: 'prescriber-registration-step-one',
  templateUrl: './registration-step-one.component.html',
  styleUrls: ['./registration-step-one.component.scss'],
})
export class RegistrationStepOneComponent implements OnInit, OnDestroy {
  isProcessing = false;

  @Output() formCompleted: EventEmitter<Prescriber> = new EventEmitter<Prescriber>();

  form: FormGroup;

  isCountryConfirmed = false;
  isDuplicateNationalIdFound = false;
  isDuplicateEmailFound = false;
  subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private client: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public localeService: LocaleService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.initializeDuplicateEmailCheck();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private initializeDuplicateEmailCheck() {
    this.subscriptions.push(
      this.form.get('stakeholder.email').valueChanges.subscribe(email => {
        this.performEmailDuplicateCheck();
      })
    );
  }

  private performEmailDuplicateCheck() {
    this.isDuplicateEmailFound = false;

    const email = this.form.get('stakeholder.email').value;
    if (!email || email.length < 1) {
      return;
    }

    this.subscriptions.push(
      this.client.getEmailDuplicateCheck(email).subscribe(outcome => {
        if (!outcome.success) {
          return;
        }

        if (outcome.value) {
          this.form.get('stakeholder.email').setErrors({ duplicateEmail: true });
        }
      })
    );
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      institution: this.formBuilder.group({
        stakeholder: this.formBuilder.group({
          address: this.formBuilder.group({
            countryId: this.formBuilder.control(null, Validators.required),
            name: this.formBuilder.control(null, Validators.required),
            address1: this.formBuilder.control(null, Validators.required),
            address2: this.formBuilder.control(null),
            city: this.formBuilder.control(null, Validators.required),
            postalCode: this.formBuilder.control(null, Validators.required),
          }),
        }),
      }),
      stakeholder: this.formBuilder.group({
        firstName: this.formBuilder.control(null, Validators.required),
        lastName: this.formBuilder.control(null, Validators.required),
        email: this.formBuilder.control(null, {
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
        }),
        confirmEmail: this.formBuilder.control(null, [
          Validators.required,
          Validators.email,
        ]),
        phone: this.formBuilder.control(null, [Validators.required]),
      }),
      alternateContact: this.formBuilder.group({
        firstName: this.formBuilder.control(null),
        lastName: this.formBuilder.control(null),
        email: this.formBuilder.control(null, Validators.email),
      }),
      nationalId: this.formBuilder.control(null, Validators.required),
    });
  }

  private getModel(): Prescriber {
    const prescriber: Prescriber = this.form.getRawValue();
    prescriber.stakeholder.stakeholderType = StakeholderTypes.Prescriber;
    return prescriber;
  }

  submit() {
    this.isDuplicateNationalIdFound = false;
    this.isDuplicateEmailFound = false;

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    const prescriber = this.getModel();

    const nationalIdDuplicateCheck = this.client.getNationalIdDuplicateCheck(
      prescriber.nationalId
    );
    const emailDuplicateCheck = this.client.getEmailDuplicateCheck(
      prescriber.stakeholder.email
    );

    this.subscriptions.push(
      forkJoin([nationalIdDuplicateCheck, emailDuplicateCheck]).subscribe(
        duplicateChecks => {
          this.isProcessing = false;
          const nationalIdDuplicateCheckOutcome = duplicateChecks[0];
          const emailDuplicateCheckOutcome = duplicateChecks[1];

          if (
            !nationalIdDuplicateCheckOutcome.success ||
            !emailDuplicateCheckOutcome.success
          ) {
            this.handleError(nationalIdDuplicateCheckOutcome);
            return;
          }

          this.isDuplicateNationalIdFound = nationalIdDuplicateCheckOutcome.value;
          this.isDuplicateEmailFound = emailDuplicateCheckOutcome.value;

          if (this.isDuplicateNationalIdFound || this.isDuplicateEmailFound) {
            return;
          }

          this.formCompleted.emit(this.getModel());
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
          this.formCompleted.emit(this.getModel());
        }
      )
    );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      3000
    );
  }
}
