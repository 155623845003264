import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, PageEvent } from '@angular/material';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UtilityService } from '../../../shared/services/utility.service';
import { LabUserLabPagedQuery } from '../../models/lab-user-lab-paged-query.model';
import { LabUserLab } from '../../models/lab-user.model';
import { LaboratoryUserService } from '../../service/laboratory-user.service';
import { AddLabUserDialogComponent } from '../add-lab-user-dialog/add-lab-user-dialog.component';

@Component({
  selector: 'lab-user-list',
  templateUrl: './lab-user-list.component.html',
  styleUrls: ['./lab-user-list.component.scss'],
})
export class LabUserListComponent implements OnInit, OnDestroy, OnChanges {
  loggedLabUser: LabUserLab = this.laboratoryUserService.loggedInLaboratoryUserLab;
  pagedQuery = new LabUserLabPagedQuery(1, 10, null, 'id', true, null);

  isLoading = this.laboratoryUserService.isLoading;
  filterText$ = new BehaviorSubject<string>(null);

  private subscriptions = new Array<Subscription>();

  constructor(
    public utilityService: UtilityService,
    public laboratoryUserService: LaboratoryUserService,
    private matDialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.laboratoryUserService.labUserPagedListSubject.next(
      new PaginatedList<LabUserLab>()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.loggedLabUser ||
      !changes.loggedLabUser.currentValue ||
      changes.loggedLabUser.currentValue === changes.loggedLabUser.previousValue
    ) {
      return;
    }

    this.refreshList();
  }

  refreshList() {
    let labId: string = null;
    if (this.loggedLabUser) {
      labId = this.loggedLabUser.labId;
      this.pagedQuery.excludeLabUserId = this.loggedLabUser.labUserId;
    }

    this.laboratoryUserService.setLabUserPage(this.pagedQuery, labId);
  }

  onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  addLabUser() {
    const dialogRef = this.matDialog.open(AddLabUserDialogComponent, {
      data: {},
      width: '800px',
    });

    this.subscriptions.push(
      dialogRef.componentInstance.onLabUserSaved.subscribe(() => {
        this.refreshList();
      })
    );
  }
}
