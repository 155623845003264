import { Stakeholder } from '../models';
import { StakeholderTypes } from './stakeholder-types.enum';

export enum AttestationTypes {
    Unknown = -1,

    PrescriberRegistration = 1,

    LabUserRegistration = 2,

    LabUserDelegateRegistration = 3,

    PrescriberDelegateRegistration = 4,
    PatientRegistration = 5
}

export function getAttestationTypeForStakeholder(stakeholder: Stakeholder): AttestationTypes {
    if (!stakeholder) {
        return AttestationTypes.Unknown;
    }

    switch (stakeholder.stakeholderType) {
        case StakeholderTypes.Prescriber:
            return AttestationTypes.PrescriberRegistration;
        case StakeholderTypes.PrescriberDelegate:
            return AttestationTypes.PrescriberDelegateRegistration;
        case StakeholderTypes.LaboratoryUser:
            return AttestationTypes.LabUserRegistration;
        case StakeholderTypes.LaboratoryUserDelegate:
            return AttestationTypes.LabUserDelegateRegistration;
        case StakeholderTypes.Patient:
            return AttestationTypes.PatientRegistration;
        default:
            return AttestationTypes.Unknown;
    }
}
