import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreDirectivesModule, PipeModule, MaterialModule } from '@roctavian-abstractions/core';
import { PublicInsecureRoutingModule } from './public-insecure-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginRegisterComponent } from './components/login-register/login-register.component';
import { SharedModule } from '../shared/shared.module';
import { ContactUsPageComponent, OrderSampleShippingKitCompletePageComponent, OrderSampleShippingKitPageComponent, ResourcesPageComponent } from './pages';
import { FirstVisitDialogComponent, LeavingSiteDialogComponent } from './components';

@NgModule({
  declarations: [
    HomePageComponent,
    LoginRegisterComponent,
    OrderSampleShippingKitPageComponent,
    ResourcesPageComponent,
    ContactUsPageComponent,
    OrderSampleShippingKitCompletePageComponent,
    FirstVisitDialogComponent,
    LeavingSiteDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    PublicInsecureRoutingModule,
    CoreDirectivesModule,
    SharedModule
  ],
  entryComponents: [FirstVisitDialogComponent, LeavingSiteDialogComponent]
})
export class PublicInsecureModule { }
