import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService, MessageService } from '@roctavian-abstractions/core';
import {
  AuthenticationService,
  SessionManagementEventHandler,
} from '@roctavian-abstractions/identity';

@Injectable()
export class RoctavianSessionManagementEventHandler extends SessionManagementEventHandler {
  constructor(
    private logger: LoggingService,
    private router: Router,
    private authService: AuthenticationService,
    private messageService: MessageService
  ) {
    super();
  }

  handleTimeout() {
    this.authService.logout();
    this.messageService.dismiss();
    this.router.navigate(['/']);
  }
}
