import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'roctavian-eumea-portal-add-prescriber-delegate-dialog',
  templateUrl: './add-prescriber-delegate-dialog.component.html',
  styleUrls: ['./add-prescriber-delegate-dialog.component.scss'],
})
export class AddPrescriberDelegateDialogComponent {
  @Output() onPrescriberDelegateSaved = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<AddPrescriberDelegateDialogComponent>
  ) {}

  prescriberDelegateSaved() {
    this.onPrescriberDelegateSaved.emit();
    this.reference.close(true);
  }
}
