import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '@roctavian-abstractions/core';
import {
  AuthenticationEventHandler,
  AuthenticationService,
} from '@roctavian-abstractions/identity';
import { PrescriberDelegateService } from './modules/prescriber-delegate/services/prescriber-delegate.service';
import { PrescriberService } from './modules/prescriber/services/prescriber.service';
import { StakeholderService } from './modules/shared/services/stakeholder.service';

@Injectable()
export class RoctavianAuthenticationEventHandler extends AuthenticationEventHandler {
  constructor(
    private logger: LoggingService,
    private router: Router,
    private authService: AuthenticationService,
    private prescriberService: PrescriberService,
    private stakeholderService: StakeholderService,
    private prescriberDelegateService: PrescriberDelegateService
  ) {
    super();
  }

  handleSuccessfulLogin() {
    this.stakeholderService.loadStakeholderByEmail(this.authService.userInfo.email);

    if (this.authService.claimsPrincipal.hasRole('Prescriber')) {
      this.prescriberService.loadAuthenticatedPrescriber();
      this.router.navigate(['/prescriber']);
    } else if (this.authService.claimsPrincipal.hasRole('LaboratoryUser')) {
      this.router.navigate(['/laboratory-user']);
    } else if (this.authService.claimsPrincipal.hasRole('PrescriberDelegate')) {
      this.prescriberDelegateService.loadAuthenticatedPrescriberDelegate();
      this.router.navigate(['/prescriber-delegate']);
    }
  }

  handleExpiredPassword() {
    this.router.navigate(['/expired']);
  }

  handleInitialLockout() {
    const message =
      'Create and register a custom AuthenticationEventHandler to handle the initial lockout event.';
    this.logger.log(message);
  }

  handleAccountLocked() {
    const message =
      'Create and register a custom AuthenticationEventHandler to handle the account locked event.';
    this.logger.log(message);
  }

  handleEmailNotConfirmed() {
    const message =
      'Create and register a custom AuthenticationEventHandler to handle the  email not confirmed event.';
    this.logger.log(message);
  }
}
