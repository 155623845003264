import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Lab } from '../../../lab/models/lab.model';
import { LabUserLab } from '../../../laboratory-user/models/lab-user.model';

@Component({
  selector: 'roctavian-eumea-portal-view-lab-details-dialog',
  templateUrl: './view-lab-details-dialog.component.html',
  styleUrls: ['./view-lab-details-dialog.component.scss'],
})
export class ViewLabDetailsDialogComponent {
  @Input() lab: Lab;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<ViewLabDetailsDialogComponent>
  ) {
    this.lab = data.lab;
  }

  get authorizedRepresentativeAssociation(): LabUserLab {
    if (!this.lab || !this.lab.authorizedRepresantatives) {
      return null;
    }

    return this.lab.authorizedRepresantatives.find(l => l.isAuthorizedRepresentative);
  }
}
