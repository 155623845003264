import { LabUserLab } from '../../laboratory-user/models/lab-user.model';
import { Stakeholder } from '../../shared/models/stakeholder.model';
import { LabPrescriber } from './lab-prescriber.model';

export class Lab {
  id: string;
  nationalId: string;
  verified: boolean;
  labName: string;

  stakeholder: Stakeholder;

  labPrescribers: LabPrescriber[];

  changeReasonDescription: string;

  authorizedRepresantatives: LabUserLab[];

  isPrescriberOffice: boolean;
}
