import { DatePipe } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { Outcome } from '@roctavian-abstractions/web';
import { EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LaboratoryUserClient } from '../../../laboratory-user/clients/laboratory-user.client';
import { LabUserLab } from '../../../laboratory-user/models/lab-user.model';
import { LaboratoryUserService } from '../../../laboratory-user/service/laboratory-user.service';
import { PrescriberDelegateClient } from '../../../prescriber-delegate/clients/prescriber-delegate.client';
import { PrescriberDelegate } from '../../../prescriber-delegate/models/prescriber-delegate.model';
import { PrescriberDelegateService } from '../../../prescriber-delegate/services/prescriber-delegate.service';
import { PrescriberClient } from '../../../prescriber/clients/prescriber.client';
import { Prescriber } from '../../../prescriber/models/prescriber.model';
import { PrescriberService } from '../../../prescriber/services/prescriber.service';
import { Address, PagedQuery } from '../../../shared';
import { StakeholderTypes } from '../../../shared/enums';
import { UtilityService } from '../../../shared/services/utility.service';
import { PatientClient } from '../../clients/patient.client';
import { GenderTypes } from '../../enums/gender-types.enum';
import { PatientPrescriber } from '../../models/patient-prescriber.model';
import { Patient } from '../../models/patient.model';
import { PatientService } from '../../service/PatientService';

@Component({
  selector: 'patient-register-patient-information',
  templateUrl: './register-patient-information.component.html',
  styleUrls: ['./register-patient-information.component.scss'],
})
export class RegisterPatientInformationComponent implements OnInit, OnDestroy {

  showMedicalRecordField = true;
  prescriberList: Prescriber[] = [];
  patientPrescribers: PatientPrescriber[] = [];

  loggedInPrescriber: Prescriber;
  loggedInPrescriberDelegate: PrescriberDelegate;
  loggedInLabUserLab: LabUserLab;
  private subscriptions = new Array<Subscription>();
  form: FormGroup;
  currentDate = new Date();
  genderTypes = GenderTypes;
  isProcessing = false;
  isReadonly = false;
  showNotFountHeader: boolean = true;

  constructor(
    private patientService: PatientService,
    private prescriberService: PrescriberService,
    private formBuilder: FormBuilder,
    public utilityService: UtilityService,
    private prescriberDelegateService: PrescriberDelegateService,
    private prescriberDelegateClient: PrescriberDelegateClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private router: Router,
    private patientClient: PatientClient,
    private authService: AuthenticationService,
    private laboratoryUserService: LaboratoryUserService,
    private laboratoryUserClient: LaboratoryUserClient,
    private prescriberClient: PrescriberClient,
    private datePipe: DatePipe,
    private _adapter: DateAdapter<any>,
    private translationService: AppTranslationService

  ) {}

  ngOnInit() {
    const currentLocale = this.translationService.getCurrentLocale();
    if (currentLocale && (currentLocale.localeCode === 'en-sa'||
    currentLocale.localeCode === 'en-kw'||
    currentLocale.localeCode === 'en-qa'||
    currentLocale.localeCode === 'en-ae')
    ){

      this._adapter.setLocale('en');
    }
    this.buildForm();

    this.subscriptions.push(
      this.prescriberService.loggedInPrescriberSubject.subscribe(prescriber => {
        this.loggedInPrescriber = prescriber;
        if (prescriber) {
          this.selectPrescriber(prescriber);
        }
      })
    );

    this.subscriptions.push(
      this.prescriberDelegateService.loggedInPrescriberDelegateSubject.subscribe(
        prescriberDelegate => {
          this.loggedInPrescriberDelegate = prescriberDelegate;
          if (!prescriberDelegate) {
            return;
          }

          this.loadPrescribersForDelegate(prescriberDelegate.id);
        }
      )
    );

    this.subscriptions.push(
      this.laboratoryUserService.loggedInLaboratoryUserLabSubject.subscribe(
        labUserLab => {
          this.loggedInLabUserLab = labUserLab;
          if (!labUserLab) {
            this.loadPrescribersForLabUser(null);
          } else {
            this.loadPrescribersForLabUser(labUserLab.labUserId);
          }
        }
      )
    );

    this.initializePrescriberAutocomplete();
    this.initializePatientAutocomplete();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.returnValue = false;
      event.preventDefault();
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      patientFilter: this.formBuilder.control(null),
      id: this.formBuilder.control(null),
      stakeholder: this.formBuilder.group({
        firstName: this.formBuilder.control(null, [Validators.required, Validators.maxLength(100)]),
        middleName: this.formBuilder.control(null),
        lastName: this.formBuilder.control(null, [Validators.required, Validators.maxLength(100)]),
      }),
      birthDate: this.formBuilder.control(null, Validators.required),
      gender: this.formBuilder.control(null, Validators.required),
      drugInclusion: this.formBuilder.control(null, Validators.requiredTrue),
      patientsPrescribers: this.formBuilder.array([
        this.formBuilder.group({
          prescriberFilter: new FormControl(null),
          prescriber: new FormControl(null),
        }),
      ]),
    });
  }

  cancel() {
    if (this.authService.claimsPrincipal.hasRole('Prescriber')) {
      this.router.navigate(['/prescriber', 'my-patients']);
    } else if (this.authService.claimsPrincipal.hasRole('LaboratoryUser')) {
      this.router.navigate(['laboratory-user', 'orders', 'list']);
    } else if (this.authService.claimsPrincipal.hasRole('PrescriberDelegate')) {
      this.router.navigate(['/prescriber-delegate', 'my-patients']);
    } else {
      this.router.navigate(['/patient']);
    }
  }

  submitForm() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;

    const patientId: string = this.form.get('id').value;
    if (patientId) {
      this.router.navigate(['/patient', 'register', patientId, 'lab-information'], {
        queryParams: { prescriberId: this.selectedPrescriber.id },
      });
      return;
    }

    this.subscriptions.push(
      this.patientClient.createPatient(this.patientModel).subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }

          if (this.loggedInLabUserLab) {
            this.router.navigate(
              ['/patient', 'register', outcome.value, 'lab-information'],
              { queryParams: { prescriberId: this.selectedPrescriber.id } }
            );
          } else if (this.loggedInPrescriberDelegate) {
            this.router.navigate(['/patient', 'register', outcome.value, 'consent'], {
              queryParams: { prescriberId: this.selectedPrescriber.id },
            });
          } else {
            this.router.navigate(['/patient', 'register', outcome.value, 'consent']);
          }
        },
        error => this.handleError(error)
      )
    );
  }

  selectPrescriber(prescriber: Prescriber) {
    const patientPrescriberAssociations = this.form.get(
      'patientsPrescribers'
    ) as FormArray;

    patientPrescriberAssociations.controls[0].get('prescriber').setValue(prescriber);

    if (this.loggedInLabUserLab) {
      this.refreshPatientList();
    }
  }

  private loadPrescribersForDelegate(prescriberDelegateId: string, filterText?: string) {
    this.prescriberList = [];
    if (!prescriberDelegateId) {
      return;
    }

    this.subscriptions.push(
      this.prescriberDelegateClient
        .getPrescriberDelegatePrescriberListPaged(
          prescriberDelegateId,
          new PagedQuery(1, 999, filterText, 'addDate', true)
        )
        .subscribe(
          outcome => {
            if (!outcome.success) {
              this.handleError(outcome);
              return;
            }

            outcome.value.items.forEach(prescriberDelegatePrescriber => {
              this.prescriberList.push(prescriberDelegatePrescriber.prescriber);
            });
          },
          error => {
            this.handleError(error);
          }
        )
    );
  }

  private loadPrescribersForLabUser(labUserId: string, filterText?: string) {
    this.prescriberList = [];
    if (!labUserId) {
      return;
    }

    this.subscriptions.push(
      this.laboratoryUserClient
        .getPrescriberPagedList(
          new PagedQuery(1, 999, filterText, 'addDate', true),
          labUserId
        )
        .subscribe(
          outcome => {
            if (!outcome.success) {
              this.handleError(outcome);
              return;
            }

            outcome.value.items.forEach(labUserPrescriber => {
              this.prescriberList.push(labUserPrescriber.prescriber);
            });
          },
          error => {
            this.handleError(error);
          }
        )
    );
  }

  get patientModel(): Patient {
    const patient: Patient = this.form.getRawValue();
    patient.birthDate = this.datePipe.transform(patient.birthDate, 'MM/dd/yyyy');
    patient.stakeholder.stakeholderType = StakeholderTypes.Patient;

    let prescriber: Prescriber;
    if (this.loggedInPrescriber) {
      prescriber = this.loggedInPrescriber;
    } else {
      prescriber = this.selectedPrescriber;
    }

    if (prescriber) {
      const patientPrescriber = new PatientPrescriber();
      patientPrescriber.prescriberId = prescriber.id;
      patientPrescriber.isCurrent = true;
      patient.patientsPrescribers = [patientPrescriber];

      patient.stakeholder.address = new Address();
      patient.stakeholder.address.countryId =
        prescriber.institution.stakeholder.address.countryId;
    }
    return patient;
  }

  setPatient(patient: Patient) {
    if (!patient) {
      this.toggleFormReadonlyStatus(false);
      return;
    }

    this.form.get('stakeholder.firstName').setValue(patient.stakeholder.firstName);

    this.form.get('stakeholder.lastName').setValue(patient.stakeholder.lastName);

    this.form.get('stakeholder.middleName').setValue(patient.stakeholder.middleName);

    this.form.get('birthDate').setValue(patient.birthDate);

    this.form.get('gender').setValue(patient.gender);

    this.form.get('id').setValue(patient.id);
    this.toggleFormReadonlyStatus(true);
  }

  toggleFormReadonlyStatus(isReadOnly: boolean) {
    if (isReadOnly) {
      this.showNotFountHeader = false;
      this.form.get('stakeholder.firstName').disable();
      this.form.get('stakeholder.lastName').disable();
      this.form.get('stakeholder.middleName').disable();
      this.form.get('birthDate').disable();
      this.form.get('gender').disable();
    } else {
      this.showNotFountHeader = true;
      this.form.get('stakeholder.firstName').setValue(null);
      this.form.get('stakeholder.lastName').setValue(null);
      this.form.get('stakeholder.middleName').setValue(null);
      this.form.get('birthDate').setValue(null);
      this.form.get('gender').setValue(null);
      this.form.get('id').setValue(null);

      this.form.get('stakeholder.firstName').enable();
      this.form.get('stakeholder.lastName').enable();
      this.form.get('stakeholder.middleName').enable();
      this.form.get('birthDate').enable();
      this.form.get('gender').enable();
    }
  }

  private handleError(outcome: Outcome) {
    this.isProcessing = false;
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
  }

  displayPrescriberFn(prescriber: Prescriber): string {
    if (!prescriber) {
      return '';
    }
    return `${prescriber.stakeholder.firstName} ${prescriber.stakeholder.lastName}`;
  }

  displayPatientFn(patient: Patient): string {
    if (!patient) {
      return '';
    }
    return `${patient.stakeholder.firstName} ${patient.stakeholder.lastName}`;
  }

  get showSelectPrescriberRequiredError(): boolean {
    const patientPrescriberAssociations = this.form.get(
      'patientsPrescribers'
    ) as FormArray;
    return patientPrescriberAssociations.controls[0]
      .get('prescriber')
      .hasError('required');
  }

  get selectedPrescriber(): Prescriber {
    const patientPrescriberAssociations = this.form.get(
      'patientsPrescribers'
    ) as FormArray;
    const prescriber: Prescriber = patientPrescriberAssociations.controls[0].get(
      'prescriber'
    ).value;
    if (!prescriber || !prescriber.id) {
      return null;
    }
    return prescriber;
  }

  private refreshPatientList() {
    this.patientPrescribers = [];

    if (!this.selectedPrescriber) {
      return;
    }

    this.subscriptions.push(
      this.prescriberClient
        .getPatientPrescriberListPaged(
          new PagedQuery(
            1,
            10,
            this.form.get('patientFilter').value,
            'patient.stakeholder.firstName',
            false
          ),
          this.selectedPrescriber.id
        )
        .subscribe(
          outcome => {
            if (!outcome.success) {
              this.handleError;
            }

            this.patientPrescribers = outcome.value.items;
          },
          error => this.handleError(error)
        )
    );
  }

  initializePrescriberAutocomplete() {
    const patientPrescriberAssociations = this.form.get(
      'patientsPrescribers'
    ) as FormArray;

    this.subscriptions.push(
      patientPrescriberAssociations.controls[0]
        .get('prescriberFilter')
        .valueChanges.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (
              !this.prescriberDelegateService.loggedInPrescriberDelegateSubject.value &&
              !this.laboratoryUserService.loggedInLaboratoryUserLabSubject.value
            ) {
              return EMPTY;
            }

            if (!searchText) {
              this.selectPrescriber(null);
            }

            if (this.prescriberDelegateService.loggedInPrescriberDelegateSubject.value) {
              this.loadPrescribersForDelegate(
                this.prescriberDelegateService.loggedInPrescriberDelegateSubject.value.id,
                searchText
              );
            } else if (
              this.laboratoryUserService.loggedInLaboratoryUserLabSubject.value
            ) {
              this.loadPrescribersForLabUser(
                this.laboratoryUserService.loggedInLaboratoryUserLabSubject.value
                  .labUserId,
                searchText
              );
            }

            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  private initializePatientAutocomplete() {
    this.subscriptions.push(
      this.form
        .get('patientFilter')
        .valueChanges.pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (!searchText) {
              this.setPatient(null);
            }

            this.refreshPatientList();

            return EMPTY;
          })
        )
        .subscribe()
    );
  }
}
