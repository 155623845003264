import { CommonModule, registerLocaleData } from '@angular/common';
import localearArAe from '@angular/common/locales/ar-AE';
import localeArKw from '@angular/common/locales/ar-KW';
import localearQa from '@angular/common/locales/ar-QA';
import localeArSA from '@angular/common/locales/ar-SA';
import localeDe from '@angular/common/locales/de';
import localeDeAt from '@angular/common/locales/de-AT';
import localeEnDe from '@angular/common/locales/en-DE';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEsEs from '@angular/common/locales/es';
import localeEsAR from '@angular/common/locales/es-AR';
import localeExtraArSA from '@angular/common/locales/extra/ar-SA';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeKw from '@angular/common/locales/kw';
import localePt from '@angular/common/locales/pt-PT';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatExpansionModule,
  MatMenuModule,
  MatProgressBarModule,
  MatSortModule,
  MatTableModule,
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CoreDirectivesModule, CoreModule, PipeModule } from '@roctavian-abstractions/core';
import { IdentityModule } from '@roctavian-abstractions/identity';
import { NgpSortModule } from 'ngp-sort-pipe';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { OrderModule } from 'ngx-order-pipe';
import { environment } from '../../../environments/environment';
import { FileUploadComponent } from './components';
import { FileDownloadComponent } from './components/file-download/file-download.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { UserSecuritySetupComponent } from './components/user-security-setup/user-security-setup.component';
import { httpInterceptorProviders } from './interceptors';
import { CompleteSecuritySetupPageComponent } from './pages/complete-security-setup-page/complete-security-setup-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { UserAttestationPageComponent } from './pages/user-attestation-page/user-attestation-page.component';
import { DocumentTypeStringPipe } from './pipes';
import { CountryBasedLocalePipe } from './pipes/country-based-locale.pipe';
import { PropertyEqualsPipe } from './pipes/property-equals.pipe';
import { EqualValidator } from './validators/equal.validator';

registerLocaleData(localeEnGB);
registerLocaleData(localeArSA, localeExtraArSA);
registerLocaleData(localeEsAR);
registerLocaleData(localeEnDe);
registerLocaleData(localeDe);
registerLocaleData(localeKw);
registerLocaleData(localeArKw);
registerLocaleData(localearQa);
registerLocaleData(localearArAe);
registerLocaleData(localeIt);
registerLocaleData(localePt);
registerLocaleData(localeFr);
registerLocaleData(localeEsEs);
registerLocaleData(localeDeAt);

@NgModule({
  declarations: [
    CompleteSecuritySetupPageComponent,
    CountryBasedLocalePipe,
    DocumentTypeStringPipe,
    EqualValidator,
    FileDownloadComponent,
    FileUploadComponent,
    ForgotPasswordComponent,
    ForgotPasswordPageComponent,
    PropertyEqualsPipe,
    ResetPasswordComponent,
    ResetPasswordFormComponent,
    ResetPasswordPageComponent,
    UserAttestationPageComponent,
    UserSecuritySetupComponent,
  ],
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(),
    RouterModule,
    PipeModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    CoreDirectivesModule,
    CoreModule.forRoot(environment),
    NgpSortModule,
    IdentityModule,
    MatMenuModule,
    MatTableModule,
    MatProgressBarModule,
    MatSortModule,
    OrderModule,
  ],
  exports: [
    CoreDirectivesModule,
    CoreModule,
    CountryBasedLocalePipe,
    DocumentTypeStringPipe,
    EqualValidator,
    FileDownloadComponent,
    FileUploadComponent,
    IdentityModule,
    MatSortModule,
    MatTableModule,
    NgpSortModule,
    NgxMaskModule,
    OrderModule,
    PipeModule,
    PropertyEqualsPipe,
    TranslateModule,
    UserSecuritySetupComponent,
  ],
  providers: [
    httpInterceptorProviders,
    CookieService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    CountryBasedLocalePipe,
  ],
})
export class SharedModule {}
