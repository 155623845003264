import { InjectionToken } from '@angular/core';

// this is a barrel file but adding this here temp before moving to abstracitons
export interface Endpoint {
    name: string;
    path: string;
}

export interface ApiConfig {
    name: string;
    root: string;
    endpoints: Array<Endpoint>;
}

export interface AppConfig {
    recaptchaKey: string;
    sessionTimeout: string;
    googleAnalyticsId: string;
    apis: Array<ApiConfig>;
    production: boolean;
    hmr: boolean;
    environmentName: string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('App Config');
