export enum StatusTypes {
    Unknown = -1,
    Pending = 1,
    Registered = 2,
    PreRegistered = 3,
    Inactive = 4,
    Incomplete = 5,
    LabTestIncomplete = 6,
    LabTestComplete = 7,
    LabTestOrdered = 8,
    LabTestSampleShipped = 9,
    LabTestSampleReceived = 10,
    LabTestResultReceived = 11,
    LabTestCancelled = 12
}
