import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { LaboratoryUserService } from '../../service/laboratory-user.service';

@Component({
  selector: 'roctavian-eumea-portal-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.scss'],
})
export class OrdersPageComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private translate: AppTranslationService,
    public laboratoryUserService: LaboratoryUserService
  ) {}

  ngOnInit() {}

  get isLabUserDelegate(): boolean {
    return (
      this.laboratoryUserService.loggedInLaboratoryUserLab &&
      this.laboratoryUserService.loggedInLaboratoryUserLab.labUser &&
      this.laboratoryUserService.loggedInLaboratoryUserLab.labUser.labUserDelegates &&
      this.laboratoryUserService.loggedInLaboratoryUserLab.labUser.labUserDelegates
        .length > 0
    );
  }

  get patientFormPath(): string {
    return `assets/pdf/${this.translate.getTranslation(
      'Common.PatientRegistrationFormPath'
    )}`;
  }

  get labFormPath(): string {
    return `assets/pdf/${this.translate.getTranslation(
      'LabUser.LabOrderList.LabOrderFormPath'
    )}`;
  }
}
