import { Component, OnDestroy, OnInit } from '@angular/core';
import { IdentityConfigClient } from '@roctavian-abstractions/identity';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roctavian-eumea-portal-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
})
export class ForgotPasswordPageComponent implements OnInit, OnDestroy {
  loading = true;
  showSuccessMessage = false;
  enableRecaptcha = false;
  private subscriptions = new Array<Subscription>();

  constructor(private client: IdentityConfigClient) {}

  ngOnInit() {
    this.subscriptions.push(
      this.client.getIdentityConfig().subscribe(config => {
        this.loading = false;
        this.enableRecaptcha = config.enableRecaptcha;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  handleSuccessfulSubmission() {
    this.showSuccessMessage = true;
  }
}
