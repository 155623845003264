import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatExpansionModule,
  MatInputModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import {
  CoreDirectivesModule,
  MaterialModule,
  PipeModule,
} from '@roctavian-abstractions/core';
import { LabModule } from '../lab/lab.module';
import { SharedModule } from '../shared/shared.module';
import { CancelPatientRegistrationDialogComponent } from './components/cancel-patient-registration-dialog/cancel-patient-registration-dialog.component';
import { EnterLabDetailsComponent } from './components/enter-lab-details/enter-lab-details.component';
import { LabTestDetailComponent } from './components/lab-test-detail/lab-test-detail.component';
import { PatientDetailComponent } from './components/patient-detail/patient-detail.component';
import { RegisterLabInformationComponent } from './components/register-lab-information/register-lab-information.component';
import { RegisterPatientCompleteComponent } from './components/register-patient-complete/register-patient-complete.component';
import { RegisterPatientConsentComponent } from './components/register-patient-consent/register-patient-consent.component';
import { RegisterPatientInformationComponent } from './components/register-patient-information/register-patient-information.component';
import { ViewLabDetailsDialogComponent } from './components/view-lab-details-dialog/view-lab-details-dialog.component';
import { PatientDetailPageComponent } from './pages/patient-detail-page/patient-detail-page.component';
import { RegisterPatientPageComponent } from './pages/register-patient-page/register-patient-page.component';
import { PatientRoutingModule } from './patient-routing.module';

@NgModule({
  declarations: [
    PatientDetailPageComponent,
    PatientDetailComponent,
    LabTestDetailComponent,
    ViewLabDetailsDialogComponent,
    RegisterPatientPageComponent,
    RegisterPatientInformationComponent,
    RegisterPatientConsentComponent,
    RegisterLabInformationComponent,
    EnterLabDetailsComponent,
    CancelPatientRegistrationDialogComponent,
    RegisterPatientCompleteComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    PatientRoutingModule,
    CoreDirectivesModule,
    SharedModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    LabModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
  entryComponents: [
    ViewLabDetailsDialogComponent,
    CancelPatientRegistrationDialogComponent,
  ],
  exports: [
    RegisterPatientInformationComponent,
    RegisterPatientConsentComponent,
  ],
})
export class PatientModule {}
