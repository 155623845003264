import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import { Country } from '@roctavian-abstractions/core';
import { ValueOutcome } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressClient {
  constructor(private httpClient: HttpClient) {}

  getCountryList(): Observable<ValueOutcome<Country[]>> {
    const url = `${Endpoints.address}/countryList`;
    return this.httpClient.get<ValueOutcome<Country[]>>(url);
  }
}
