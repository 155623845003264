import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { LabUserLab } from '../../models/lab-user.model';
import { RemoveLabUserDialogComponent } from '../remove-lab-user-dialog/remove-lab-user-dialog.component';

@Component({
  selector: 'lab-user-list-item',
  templateUrl: './lab-user-list-item.component.html',
  styleUrls: ['./lab-user-list-item.component.scss'],
})
export class LabUserListItemComponent implements OnInit, OnDestroy {
  @Input() labUserLab: LabUserLab;
  private subscriptions = new Array<Subscription>();
  @Output() onDisassociated = new EventEmitter();
  @Output() onEdited = new EventEmitter();

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  removeLabUserLab() {
    if (!this.labUserLab) {
      return;
    }

    const dialogRef = this.matDialog.open(RemoveLabUserDialogComponent, {
      data: {
        labUserLab: this.labUserLab,
      },
      width: '800px',
    });

    this.subscriptions.push(
      dialogRef.componentInstance.labUserDisassociated.subscribe(() => {
        this.onDisassociated.emit();
      })
    );
  }
}
