import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  AppTranslationService,
  Locale,
  MessageService,
  regexValidator,
} from '@roctavian-abstractions/core';
import {
  SecurityQuestionClient,
  SecurityQuestionModel,
} from '@roctavian-abstractions/identity';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { Address } from '../../models';
import { UserSecurityInformation } from '../../models/user-security-information.model';
import { CountryBasedLocalePipe } from '../../pipes/country-based-locale.pipe';
import { LocaleService } from '../../services/locale.service';

@Component({
  selector: 'portal-user-security-setup',
  templateUrl: './user-security-setup.component.html',
  styleUrls: ['./user-security-setup.component.scss'],
})
export class UserSecuritySetupComponent implements OnInit, OnDestroy, OnChanges {
  @Output() onFormCompleted: EventEmitter<UserSecurityInformation> = new EventEmitter<
    UserSecurityInformation
  >();
  @Input() stakeholderAddress: Address;

  private subscriptions = new Array<Subscription>();

  form: FormGroup;
  isSubmitted = false;
  hide: boolean = true;
  private allSecurityQuestions: SecurityQuestionModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private securityQuestionClient: SecurityQuestionClient,
    private messageService: MessageService,
    public translate: AppTranslationService,
    public localeService: LocaleService,
    private countryBasedLocalePipe: CountryBasedLocalePipe
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      localeId: this.formBuilder.control(null, Validators.required),
      securitySetup: this.formBuilder.group({
        securityQuestionOne: this.formBuilder.control(null, [Validators.required]),
        securityQuestionOneAnswer: this.formBuilder.control(null, [Validators.required]),
        securityQuestionTwo: this.formBuilder.control(null, [Validators.required]),
        securityQuestionTwoAnswer: this.formBuilder.control(null, [Validators.required]),
        securityQuestionThree: this.formBuilder.control(null, [Validators.required]),
        securityQuestionThreeAnswer: this.formBuilder.control(null, [
          Validators.required,
        ]),
        password: this.formBuilder.control(null, this.getPasswordValidators()),
        confirmPassword: this.formBuilder.control(null, this.getPasswordValidators()),
      }),
    });

    this.subscriptions.push(
      this.securityQuestionClient.getSecurityQuestions().subscribe(securityQuestions => {
        this.allSecurityQuestions = securityQuestions;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.stakeholderAddress ||
      !changes.stakeholderAddress.currentValue ||
      !this.form
    ) {
      return;
    }
  }

  submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isSubmitted = true;
    this.onFormCompleted.emit(this.getModel());
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      3000
    );
  }

  getModel(): UserSecurityInformation {
    const formValue = this.form.getRawValue();
    const model: UserSecurityInformation = formValue;
    model.localeId = this.form.get('localeId').value;

    return model;
  }

  private getPasswordValidators(): ValidatorFn[] {
    const activeValidators = [];
    activeValidators.push(Validators.required);
    activeValidators.push(Validators.minLength(8));
    activeValidators.push(regexValidator(new RegExp('[0-9]'), { digit: true }));
    activeValidators.push(regexValidator(new RegExp('[^a-zA-Z0-9]'), { alpha: true }));
    activeValidators.push(regexValidator(new RegExp('[a-z]'), { lower: true }));
    activeValidators.push(regexValidator(new RegExp('[A-Z]'), { upper: true }));

    return activeValidators;
  }

  get securityQuestionsLocale(): Locale {
    if (this.form && this.form.get('localeId') && this.form.get('localeId').value) {
      return this.translate.supportedLocaleList.find(
        x => x.id == this.form.get('localeId').value
      );
    } else if (
      this.stakeholderAddress &&
      this.stakeholderAddress.countryId &&
      this.localeService.supportedCountryList.find(
        x => x.id == this.stakeholderAddress.countryId
      )
    ) {
      const locales = this.localeService.supportedCountryList.find(
        x => x.id == this.stakeholderAddress.countryId
      ).locales;
      let locale = locales.find(x => x.isDefault);
      if (!locale) {
        locale = locales[0];
      }
      return locale;
    } else {
      return this.translate.supportedLocaleList[0];
    }
  }

  get group1SecurityQuestions(): SecurityQuestionModel[] {
    return this.allSecurityQuestions.filter(
      sq => sq.group == 1 && sq.localeId == this.securityQuestionsLocale.id
    );
  }
  get group2SecurityQuestions(): SecurityQuestionModel[] {
    return this.allSecurityQuestions.filter(
      sq => sq.group == 2 && sq.localeId == this.securityQuestionsLocale.id
    );
  }
  get group3SecurityQuestions(): SecurityQuestionModel[] {
    return this.allSecurityQuestions.filter(
      sq => sq.group == 3 && sq.localeId == this.securityQuestionsLocale.id
    );
  }
}
