import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'roctavian-eumea-portal-add-lab-user-dialog',
  templateUrl: './add-lab-user-dialog.component.html',
  styleUrls: ['./add-lab-user-dialog.component.scss'],
})
export class AddLabUserDialogComponent {
  @Output() onLabUserSaved = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<AddLabUserDialogComponent>
  ) {}

  labUserSaved() {
    this.onLabUserSaved.emit();
    this.reference.close(true);
  }
}
