import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import {
  AccountClient,
  IdentityConfigClient,
  PasswordConfigModel,
  ResetPasswordFormSubmitEvent,
  ResetPasswordModel,
} from '@roctavian-abstractions/identity';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roctavian-eumea-portal-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  @Input() token: string;
  @Input() username: string;
  private subscriptions = new Array<Subscription>();

  passwordConfig: PasswordConfigModel;
  loading = true;
  resetPasswordFailed = false;

  /**
   * Event that is emitted when the user's password has been reset successfully.
   */
  @Output() reset = new EventEmitter<any>();

  constructor(
    public accountClient: AccountClient,
    private service: MessageService,
    private configClient: IdentityConfigClient,
    private translate: AppTranslationService
  ) {}

  /**
   * When the component is initialize, hit the identity config endpoint
   *  to determine how passwords are configured in the application.
   */
  ngOnInit() {
    this.subscriptions.push(
      this.configClient.getIdentityConfig().subscribe(config => {
        this.passwordConfig = config.passwordConfig;
        this.loading = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Handles the form submission event by making a request to the
   * reset endpoint and attempting to reset the user's password.
   *
   * @param event The reset password form submission event.
   */
  handleFormSubmission(event: ResetPasswordFormSubmitEvent) {
    this.resetPasswordFailed = false;

    if (!this.username || !this.token) {
      const message = this.translate.getTranslation('Common.ErrorProcessingRequest');
      this.service.open(message, 5000);
      return;
    }

    const model = new ResetPasswordModel(
      this.username,
      this.token,
      event.newPassword,
      event.confirmationPassword
    );

    this.subscriptions.push(
      this.accountClient.resetPassword(model).subscribe(
        () => {
          this.reset.emit();
        },
        () => {
          this.resetPasswordFailed = true;
        }
      )
    );
  }
}
