import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { PrescriberDelegatePrescriber } from '../../../prescriber-delegate/models/prescriber-delegate.model';
import { RemoveOfficeContactAssociationDialogComponent } from '../remove-office-contact-association-dialog/remove-office-contact-association-dialog.component';

@Component({
  selector: 'office-contact-detail',
  templateUrl: './office-contact-detail.component.html',
  styleUrls: ['./office-contact-detail.component.scss'],
})
export class OfficeContactDetailComponent implements OnInit, OnDestroy {
  @Input() prescriberDelegatePrescriber: PrescriberDelegatePrescriber;
  private subscriptions = new Array<Subscription>();
  @Output() onDisassociated = new EventEmitter();
  @Output() onEdited = new EventEmitter();

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  removePrescriberDelegate() {
    if (!this.prescriberDelegatePrescriber) {
      return;
    }

    const dialogRef = this.matDialog.open(RemoveOfficeContactAssociationDialogComponent, {
      data: {
        prescriberDelegatePrescriber: this.prescriberDelegatePrescriber,
      },
      width: '800px',
    });

    this.subscriptions.push(
      dialogRef.componentInstance.prescriberDelegateDisassociated.subscribe(() => {
        this.onDisassociated.emit();
      })
    );
  }
}
