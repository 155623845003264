import { CommonModule } from '@angular/common';
import { RouterModule, CanActivate } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthenticationGuard } from '@roctavian-abstractions/identity';
import { PatientDetailPageComponent } from './pages/patient-detail-page/patient-detail-page.component';
import { RegisterPatientPageComponent } from './pages/register-patient-page/register-patient-page.component';
import { PrescriberGuard } from '../prescriber/guards/prescriber.guard';
import { RegisterPatientInformationComponent } from './components/register-patient-information/register-patient-information.component';
import { RegisterPatientConsentComponent } from './components/register-patient-consent/register-patient-consent.component';
import { RegisterLabInformationComponent } from './components/register-lab-information/register-lab-information.component';
import { RegisterPatientCompleteComponent } from './components/register-patient-complete/register-patient-complete.component';

const ROUTES = [
    {
        path: 'patient',
        children: [
            {
                path: 'register',
                component: RegisterPatientPageComponent,
                canAvtivate: [AuthenticationGuard, PrescriberGuard],
                children: [
                    { path: '', redirectTo: 'patient-information', pathMatch: 'full' },
                    { path: 'patient-information', component: RegisterPatientInformationComponent },
                    {
                        path: ':id',
                        children: [
                            { path: 'consent', component: RegisterPatientConsentComponent },
                            {
                                path: 'lab-information',
                                children: [
                                    { path: '', component: RegisterLabInformationComponent },
                                    { path: ':labOrderId', component: RegisterLabInformationComponent }
                                ]
                            },
                            { path: 'complete', component: RegisterPatientCompleteComponent },
                        ]
                    }
                ]
            },
            {
                path: ':id',
                children: [
                    { path: '', redirectTo: 'detail', pathMatch: 'full' },
                    { path: 'detail', component: PatientDetailPageComponent, canActivate: [AuthenticationGuard] }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class PatientRoutingModule { }
