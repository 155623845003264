import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-eumea-portal-register-patient-page',
  templateUrl: './register-patient-page.component.html',
  styleUrls: ['./register-patient-page.component.css']
})
export class RegisterPatientPageComponent implements OnInit {
  ngOnInit() {}
}
