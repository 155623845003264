import { User } from '@roctavian-abstractions/identity';

export class Auditable {
  addUser: User;

  addDate: Date;

  editUser: User;

  editDate: Date;
}
