import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, PageEvent } from '@angular/material';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AddPrescriberDelegateDialogComponent } from '../../../prescriber-delegate/components/add-prescriber-delegate-dialog/add-prescriber-delegate-dialog.component';
import { PrescriberDelegatePrescriber } from '../../../prescriber-delegate/models/prescriber-delegate.model';
import { PagedQuery } from '../../../shared';
import { UtilityService } from '../../../shared/services/utility.service';
import { Prescriber } from '../../models/prescriber.model';
import { PrescriberService } from '../../services/prescriber.service';

@Component({
  selector: 'prescriber-office-contact-list',
  templateUrl: './office-contact-list.component.html',
  styleUrls: ['./office-contact-list.component.scss'],
})
export class OfficeContactListComponent implements OnInit, OnDestroy, OnChanges {
  loggedPrescriber: Prescriber;
  pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  prescriberDelegatePagedCollection: PaginatedList<PrescriberDelegatePrescriber>;
  isLoading = false;
  filterText$ = new BehaviorSubject<string>(null);

  private subscriptions = new Array<Subscription>();

  constructor(
    public utilityService: UtilityService,
    private prescriberService: PrescriberService,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.prescriberService.prescriberDelegatePagedListSubject.subscribe(collection => {
        this.prescriberDelegatePagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.prescriberService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );

    this.subscriptions.push(
      this.prescriberService.loggedInPrescriberSubject.subscribe(prescriber => {
        this.loggedPrescriber = prescriber;
        this.refreshList();
      })
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.prescriberService.prescriberDelegatePagedListSubject.next(
      new PaginatedList<PrescriberDelegatePrescriber>()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.loggedPrescriber ||
      !changes.loggedPrescriber.currentValue ||
      changes.loggedPrescriber.currentValue === changes.loggedPrescriber.previousValue
    ) {
      return;
    }

    this.refreshList();
  }

  refreshList() {
    let prescriberId: string = null;
    if (this.loggedPrescriber) {
      prescriberId = this.loggedPrescriber.id;
    }

    this.prescriberService.setPrescriberDelegatePage(this.pagedQuery, prescriberId);
  }

  onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  addPrescriberDelegate() {
    const dialogRef = this.matDialog.open(AddPrescriberDelegateDialogComponent, {
      data: {},
      width: '800px',
    });

    this.subscriptions.push(
      dialogRef.componentInstance.onPrescriberDelegateSaved.subscribe(() => {
        this.refreshList();
      })
    );
  }
}
