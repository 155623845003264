import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationGuard } from '@roctavian-abstractions/identity';
import { UserAttestationCompleteGuard } from '../shared/guards/user-attestation-complete.guard';
import { CompleteLabOrderPageComponent } from './pages/complete-lab-order-page/complete-lab-order-page.component';
import { EditLabTestPageComponent } from './pages/order-edit-page/order-edit-page.component';
import { OrdersPageComponent } from './pages/orders-page/orders-page.component';
import { RegisterPatientPageComponent } from './pages/register-patient-page/register-patient-page.component';
import { UserManagementPageComponent } from './pages/user-management-page/user-management-page.component';

const ROUTES = [
  {
    path: 'laboratory-user',
    canActivate: [UserAttestationCompleteGuard],
    children: [
      { path: '', redirectTo: 'orders', pathMatch: 'full' },
      {
        path: 'orders',
        canActivate: [AuthenticationGuard],
        children: [
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full',
          },
          {
            path: 'list',
            component: OrdersPageComponent,
          },
          {
            path: ':id',
            children: [
              { path: 'edit', component: EditLabTestPageComponent },
              { path: 'complete', component: CompleteLabOrderPageComponent },
            ],
          },
        ],
      },
      {
        path: 'user-management',
        component: UserManagementPageComponent,
        canActivate: [AuthenticationGuard],
      },
      { path: 'register-patient', component: RegisterPatientPageComponent },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class LaboratoryUserRoutingModule {}
