import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { UserSecurityInformation } from '../../../shared/models/user-security-information.model';
import { PrescriberClient } from '../../clients/prescriber.client';
import { PrescriberRegistration } from '../../models/prescriber-registration.model';
import { Prescriber } from '../../models/prescriber.model';

@Component({
  selector: 'roctavian-eumea-portal-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit, OnDestroy {
  prescriberInformation: Prescriber;
  userSecurityInformation: UserSecurityInformation;
  private subscriptions = new Array<Subscription>();

  constructor(
    private client: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {}

  onPrescriberFormCompleted(prescriber: Prescriber) {
    this.prescriberInformation = prescriber;
  }

  onSecurityInformationFormComplete(userSecurityInformation: UserSecurityInformation) {
    this.userSecurityInformation = userSecurityInformation;

    this.subscriptions.push(
      this.client.registerPrescriber(this.getModel()).subscribe(
        outcome => {
          this.subscriptions.push(
            this.authenticationService
              .login(
                this.prescriberInformation.stakeholder.email,
                this.userSecurityInformation.securitySetup.password,
                null
              )
              .subscribe(result => {
                this.router.navigate(['/prescriber']);
              })
          );
        },
        error => {
          this.handleError(error);
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      3000
    );
  }

  private getModel(): PrescriberRegistration {
    const model = new PrescriberRegistration(
      this.userSecurityInformation.securitySetup,
      this.prescriberInformation
    );
    model.prescriber.stakeholder.localeId = this.userSecurityInformation.localeId;

    return model;
  }
}
