import { Component, Input, OnInit } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Patient } from '../../../patient/models/patient.model';
import { UtilityService } from '../../../shared/services/utility.service';

@Component({
  selector: 'prescriber-delegate-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss'],
})
export class PatientDetailComponent implements OnInit {
  @Input() patient: Patient;

  constructor(
    public translate: AppTranslationService,
    public utilityService: UtilityService
  ) {}

  ngOnInit() {}
}
