import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { PagedQuery } from '../../shared';
import { UtilityService } from '../../shared/services/utility.service';
import { LabTest } from '../models/lab-test.model';
import { LabUserDelegate } from '../models/lab-user-delegate.model';
import { LabUserLabPagedQuery } from '../models/lab-user-lab-paged-query.model';
import { LabUser, LabUserLab } from '../models/lab-user.model';

@Injectable({
  providedIn: 'root',
})
export class LaboratoryUserClient {
  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService,
    private translate: AppTranslationService
  ) {}

  getLabTestsByLabUser(
    pagedQuery: PagedQuery,
    id: string
  ): Observable<ValueOutcome<PaginatedList<LabTest>>> {
    const url = `${Endpoints.labUser}/${id}/lab-test`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabTest>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getLabUserListPaged(
    pagedQuery: LabUserLabPagedQuery,
    labId: string
  ): Observable<ValueOutcome<PaginatedList<LabUserLab>>> {
    const url = `${Endpoints.lab}/${labId}/LabUser`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabUserLab>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getLabUserLabByEmail(email: string): Observable<ValueOutcome<LabUserLab>> {
    const url = `${Endpoints.lab}/LabUser?email=${encodeURIComponent(email)}`;
    return this.httpClient.get<ValueOutcome<LabUserLab>>(url);
  }

  disassociateLabUser(labId: string, labUserId: string): Observable<Outcome> {
    const url = `${Endpoints.lab}/${labId}/LabUser/Disassociate/${labUserId}`;
    return this.httpClient.delete<Outcome>(url, {});
  }

  getEmailDuplicateCheck(email: string): Observable<ValueOutcome<boolean>> {
    const url = `${Endpoints.labUser}/duplicateEmailCheck?email=${encodeURIComponent(
      email
    )}`;
    return this.httpClient.get<ValueOutcome<boolean>>(url);
  }

  createLabUser(labuser: LabUser): Observable<ValueOutcome<string>> {
    return this.httpClient.post<ValueOutcome<string>>(Endpoints.labUser, labuser);
  }

  createLabUserLabAssociation(labUserLab: LabUserLab): Observable<Outcome> {
    const url = `${Endpoints.lab}/LabUser/Associate`;
    return this.httpClient.post<Outcome>(url, labUserLab);
  }

  getLabTest(labTestId: string): Observable<ValueOutcome<LabTest>> {
    const url = `${Endpoints.labTest}/${labTestId}`;
    return this.httpClient.get<ValueOutcome<LabTest>>(url);
  }

  updateLabTest(labTest: LabTest): Observable<Outcome> {
    const url = `${Endpoints.labTest}/${labTest.id}/public`;
    return this.httpClient.put<Outcome>(url, labTest);
  }

  downloadShipmentLabel(labTestId: string) {
    const url = `${Endpoints.labTest}/${labTestId}/shipment-label`;
    return this.httpClient.get(url, { responseType: 'blob' }).subscribe(blob => {
      saveAs(
        blob,
        new Date().getTime() +
          '_' +
          this.translate.getTranslation('LabUser.ShipmentLabelDoc'),
        {
          type: 'application/pdf',
        }
      );
    });
  }

  getPrescriberPagedList(
    pagedQuery: PagedQuery,
    labUserId: string
  ): Observable<ValueOutcome<PaginatedList<LabUserDelegate>>> {
    const url = `${Endpoints.labUser}/${labUserId}/Prescriber/Get`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabUserDelegate>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }
}
