import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatPaginatorIntl,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AdminModule } from '@roctavian-abstractions/admin';
import { CoreDirectivesModule } from '@roctavian-abstractions/core';
import {
  AuthenticationEventHandler,
  IdentityModule,
  SessionManagementEventHandler,
} from '@roctavian-abstractions/identity';
import { TasksModule } from '@roctavian-abstractions/tasks';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './home/home.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
import localeBr from '@angular/common/locales/br';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { LaboratoryUserModule } from './modules/laboratory-user/laboratory-user.module';
import { PatientModule } from './modules/patient/patient.module';
import { PrescriberModule } from './modules/prescriber/prescriber.module';
import { PublicInsecureModule } from './modules/public-insecure/public-insecure.module';
import { CompleteSecuritySetupPageComponent } from './modules/shared/pages/complete-security-setup-page/complete-security-setup-page.component';
import { ForgotPasswordPageComponent } from './modules/shared/pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './modules/shared/pages/reset-password-page/reset-password-page.component';
import { UserAttestationPageComponent } from './modules/shared/pages/user-attestation-page/user-attestation-page.component';
import { PaginatorI18n } from './modules/shared/services/paginatorI18n.service';
import { SharedModule } from './modules/shared/shared.module';
import { RoctavianAuthenticationEventHandler } from './roctavian-authentication-event.handler';
import { RoctavianSessionManagementEventHandler } from './roctavian-session-management-event.handler';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeBr, 'br');

const globalRippleConfig: RippleGlobalOptions = {
  disabled: false,
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'window.location.hostname',
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#fff',
      border: '#f8e71c',
    },
    button: {
      background: '#014889',
      text: '#fff',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message:
      'This website uses cookies to improve website functionality. You may delete or block cookies by accessing your preferences. For more information, please see our Privacy Policy and Terms and Conditions. By using this website, you consent to our use of cookies.',
    dismiss: 'Ok',
    link: '',
  },
};

@NgModule({
  declarations: [AppComponent, HomeComponent, NavbarComponent, FooterComponent],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'public', pathMatch: 'full' },
      {
        path: 'complete-user-setup',
        component: CompleteSecuritySetupPageComponent,
      },
      {
        path: 'complete-user-attestation',
        component: UserAttestationPageComponent,
      },
      { path: 'forgot-password', component: ForgotPasswordPageComponent },
      { path: 'reset-password', component: ResetPasswordPageComponent },
    ]),
    CoreDirectivesModule,
    AdminModule,
    IdentityModule,
    TasksModule,
    PublicInsecureModule,
    PrescriberModule,
    LaboratoryUserModule,
    PatientModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: AuthenticationEventHandler,
      useClass: RoctavianAuthenticationEventHandler,
    },
    {
      provide: SessionManagementEventHandler,
      useClass: RoctavianSessionManagementEventHandler,
    },
    { provide: MatPaginatorIntl, useClass: PaginatorI18n },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
