import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  BaseAppComponent,
  ConfigService,
  GoogleAnalyticsService,
} from '@roctavian-abstractions/core';
import {
  AuthenticationService,
  SessionManagementService,
} from '@roctavian-abstractions/identity';
import { Subscription } from 'rxjs';
import { LaboratoryUserService } from './modules/laboratory-user/service/laboratory-user.service';
import { PatientService } from './modules/patient/service/PatientService';
import { PrescriberDelegateService } from './modules/prescriber-delegate/services/prescriber-delegate.service';
import { PrescriberService } from './modules/prescriber/services/prescriber.service';
import { LocaleService } from './modules/shared/services/locale.service';
import { StakeholderService } from './modules/shared/services/stakeholder.service';

@Component({
  selector: 'roctavian-eumea-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseAppComponent
  implements OnInit, OnDestroy {
  private subscriptions = new Array<Subscription>();
  isAuthenticated = false;
  timeout: string;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private prescriberService: PrescriberService,
    private stakeholderService: StakeholderService,
    private laboratoryUserService: LaboratoryUserService,
    private prescriberDelegateService: PrescriberDelegateService,
    private patientService: PatientService,
    private sessionService: SessionManagementService,
    private configService: ConfigService,
    private localeService: LocaleService
  ) {
    super();
  }

  ngOnInit() {
    this.localeService.loadIPAddress();
    this.enableSessionTimeout();

    this.subscriptions.push(
      this.authService.isLoggedIn().subscribe(authenticated => {
        if (!authenticated) {
          this.clearServiceValues();
          return;
        }

        let authService = this.authService;

        this.stakeholderService.loadStakeholderByEmail(
          this.authService.userInfo.email
        );

        if (
          this.authService.claimsPrincipal.hasRole('Prescriber') &&
          !this.prescriberService.loggedInPrescriberSubject.value
        ) {
          this.prescriberService.loadAuthenticatedPrescriber();
        } else if (
          this.authService.claimsPrincipal.hasRole('LaboratoryUser') &&
          !this.laboratoryUserService.loggedInLaboratoryUserLab
        ) {
          this.laboratoryUserService.loadAuthenticatedLabUser();
        } else if (
          this.authService.claimsPrincipal.hasRole('PrescriberDelegate') &&
          !this.prescriberDelegateService.loggedInPrescriberDelegateSubject
            .value
        ) {
          this.prescriberDelegateService.loadAuthenticatedPrescriberDelegate();
        }
      })
    );

    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.googleAnalyticsService.submitPageView(event.urlAfterRedirects);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private clearServiceValues() {
    this.stakeholderService.clearService();
    this.laboratoryUserService.clearService();
    this.prescriberService.clearService();
    this.prescriberDelegateService.clearService();
    this.patientService.clearService();
  }

  hasSessionStarted() {
    return this.sessionService.sessionStatus;
  }

  enableSessionTimeout() {
    if (this.timeout && !isNaN(+this.timeout)) {
      this.configService.update('sessionTimeout', this.timeout);
    }

    this.sessionService.enableSessionManagement();
  }

  disableSessionTimeout() {
    this.sessionService.disableSessionManagement();
  }

  logout(): void {
    this.authService.logout();
  }

  handleClipboardCopy(event) {
    console.log('Text has been copied!');
  }
}
