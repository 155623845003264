import { ChangeReasonTypes, StakeholderTypes, StatusTypes } from '../enums';

export class StatusChangeReasonType {
  constructor(
    public stakeholderType: StakeholderTypes,
    public statusType: StatusTypes,
    public changeReasonType: ChangeReasonTypes,
    public name: string
  ) {}
}

export const StatusChangeReasonTypes: StatusChangeReasonType[] = [
  new StatusChangeReasonType(
    StakeholderTypes.Prescriber,
    StatusTypes.Inactive,
    ChangeReasonTypes.IncompleteRegistrationDDExhausted,
    getStatusChangeReasonName(ChangeReasonTypes.IncompleteRegistrationDDExhausted)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Prescriber,
    StatusTypes.Inactive,
    ChangeReasonTypes.DeclinedToCompleteEnrollment,
    getStatusChangeReasonName(ChangeReasonTypes.DeclinedToCompleteEnrollment)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Prescriber,
    StatusTypes.Incomplete,
    ChangeReasonTypes.NoLongerActive,
    getStatusChangeReasonName(ChangeReasonTypes.NoLongerActive)
  ),

  new StatusChangeReasonType(
    StakeholderTypes.Patient,
    StatusTypes.Inactive,
    ChangeReasonTypes.IncompleteRegistrationDDExhausted,
    getStatusChangeReasonName(ChangeReasonTypes.IncompleteRegistrationDDExhausted)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Patient,
    StatusTypes.Inactive,
    ChangeReasonTypes.DeclinedToCompleteEnrollment,
    getStatusChangeReasonName(ChangeReasonTypes.DeclinedToCompleteEnrollment)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Patient,
    StatusTypes.Incomplete,
    ChangeReasonTypes.NoLongerActive,
    getStatusChangeReasonName(ChangeReasonTypes.NoLongerActive)
  ),

  new StatusChangeReasonType(
    StakeholderTypes.Laboratory,
    StatusTypes.Inactive,
    ChangeReasonTypes.IncompleteRegistrationDDExhausted,
    getStatusChangeReasonName(ChangeReasonTypes.IncompleteRegistrationDDExhausted)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Laboratory,
    StatusTypes.Inactive,
    ChangeReasonTypes.DeclinedToCompleteEnrollment,
    getStatusChangeReasonName(ChangeReasonTypes.DeclinedToCompleteEnrollment)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Laboratory,
    StatusTypes.Incomplete,
    ChangeReasonTypes.NoLongerActive,
    getStatusChangeReasonName(ChangeReasonTypes.NoLongerActive)
  ),
];

export function getStatusChangeReasonName(changeReasonType: ChangeReasonTypes): string {
  switch (changeReasonType) {
    case ChangeReasonTypes.IncompleteRegistrationDDExhausted:
      return 'Incomplete Registration DD Exhausted';
    case ChangeReasonTypes.DeclinedToCompleteEnrollment:
      return 'Declined to Complete Enrollment';
    case ChangeReasonTypes.NoLongerActive:
      return 'No Longer Active';
    case ChangeReasonTypes.Unknown:
    default:
      return '';
  }
}
