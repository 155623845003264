import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Document } from '@app/core/models';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Attestation, FileUploadMessageService } from '../../../shared';
import { AttestationClient } from '../../../shared/clients/attestation.client';
import { AttestationTypes } from '../../../shared/enums';
import { DocumentService } from '../../../shared/services/document.service';
import { PatientClient } from '../../clients/patient.client';
import { Patient } from '../../models/patient.model';
import { PatientService } from '../../service/PatientService';

@Component({
  selector: 'patient-register-patient-consent',
  templateUrl: './register-patient-consent.component.html',
  styleUrls: ['./register-patient-consent.component.scss'],
})
export class RegisterPatientConsentComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // new patient creation along with document
  isConsentCompleted = false;
  patient: Patient;
  private patientId: string;
  isProcessing = false;
  isUploadingDocument = false;
  selectedFiles: FileList | null = null;

  constructor(
    private translate: AppTranslationService,
    private documentService: DocumentService,
    private readonly fileUploadMessageService: FileUploadMessageService,
    private messageService: MessageService,
    private patientClient: PatientClient,
    private attestationClient: AttestationClient,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService
  ) {}

  ngOnInit() {
    this.fileUploadMessageService.uploading$
      .pipe(
        takeUntil(this.destroy$),
        tap(isUploading => {
          this.isUploadingDocument = isUploading;
          this.isProcessing = isUploading;
        })
      )
      .subscribe();

    this.fileUploadMessageService.uploaded$
      .pipe(
        takeUntil(this.destroy$),
        tap(document => this.associateDocument(document))
      )
      .subscribe();

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.patientId = params['id'];
      this.loadPatient();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  filesSelected(files: FileList | null) {
    this.selectedFiles = files;
  }

  private loadPatient() {
    this.patient = null;

    if (!this.patientId) {
      return;
    }

    this.patientClient
      .getPatient(this.patientId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }

          this.patient = outcome.value;
        },
        error => this.handleError(error)
      );
  }

  private handleError(outcome: Outcome) {
    this.isProcessing = false;
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
  }

  private associateDocument(document: Document) {
    
    this.patientClient
      .addDocumentToPatient(this.route.snapshot.params.id, document)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }
          this.patient.isPCFUploaded = true;
          this.patientService.patientConsentFormUploaded.next(true);
          this.addPatientAttestation();
        },
        error => this.handleError(error)
      );
  }

  private updatePatient() {
    this.patientClient
      .updatePatient(this.patient)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        outcome => {
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }
          this.messageService.open(
            this.translate.getTranslation('Patient.DocumentUploadedSuccessfully'),
            5000
          );
          this.isConsentCompleted = true;
          this.isProcessing = false;
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      );
  }

  private addPatientAttestation() {
    const attestation = new Attestation();
    attestation.attestationType = AttestationTypes.PatientRegistration;
    attestation.guardianName = 'none';
    attestation.hasSignature = true;
    let utcDate = new Date();   
    
    attestation.signatureDate = utcDate.toISOString().replace(/T.*/, 'T00:00:00.000Z');
    attestation.isCurrent = true;
    attestation.hasConsent = true;
    attestation.stakeholderId = this.patient.stakeholder.id;
    attestation.changeReasonDescription = 'portal submission';

    this.attestationClient
      .saveAttestation(attestation)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }
          this.isProcessing = false;
          this.updatePatient();
        },
        error => {
          this.handleError(error);
        }
      );
  }

  continue() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.patientId = params['id'];
    });
    this.router.navigate(['/patient', 'register', this.patientId, 'lab-information'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  downloadPatientConsentForm() {
    this.patientClient.downloadPatientConsentForm(this.patientId);
  }

  get patientConsentFormHref(): string {
    return `../../../../../assets/pdf/${this.translate.getTranslation(
      'Patient.PatientConsentForm'
    )}`;
  }
}
