import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { regexValidator } from '@roctavian-abstractions/core';
import {
  PasswordConfigModel,
  PasswordErrorKeys,
  ResetPasswordFormSubmitEvent,
} from '@roctavian-abstractions/identity';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, flatMap, map } from 'rxjs/operators';

@Component({
  selector: 'roctavian-eumea-portal-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {
  subject = new Subject<any>();
  confirmationPasswordDifferent = false;
  private subscriptions = new Array<Subscription>();

  @Input() disabled = false;
  @Input() passwordConfig: PasswordConfigModel;

  @Output() submitted = new EventEmitter<ResetPasswordFormSubmitEvent>();

  validKeys: string[] = [];
  invalidKeys: string[] = [];

  form: FormGroup;

  constructor(private builder: FormBuilder) {
    this.subscriptions.push(
      this.subject
        .pipe(
          map(event => event.target.value),
          debounceTime(250),
          distinctUntilChanged(),
          flatMap(text => of(text))
        )
        .subscribe(text => {
          this.confirmationPasswordDifferent = this.form.hasError('noMatch')
            ? true
            : false;
        })
    );
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  buildForm() {
    const validators = [Validators.required];
    validators.push(...this.getActiveValidators());
    this.form = this.builder.group({
      newPassword: ['', validators],
      confirmationPassword: ['', Validators.required],
    });
    this.form.setValidators(this.passwordsAreEqual);
  }

  handleFormSubmission(form: FormGroup) {
    if (!form.valid) {
      return;
    }

    const newPassword = form.get('newPassword').value;
    const confirmationPassword = form.get('confirmationPassword').value;
    const event = new ResetPasswordFormSubmitEvent(newPassword, confirmationPassword);

    this.submitted.emit(event);
  }

  private passwordsAreEqual(control: FormControl) {
    const newPassword = control.get('newPassword');
    const confirmationPassword = control.get('confirmationPassword');

    if (!newPassword || !confirmationPassword) {
      return null;
    }
    if (newPassword.value === '' || confirmationPassword.value === '') {
      return null;
    }

    return newPassword.value === confirmationPassword.value ? null : { noMatch: true };
  }

  getUnsatisfiedRuleKeys(): string[] {
    const invalidRules: string[] = [];
    if (this.form.get('newPassword').hasError('digit')) {
      invalidRules.push('digit');
    }
    if (this.form.get('newPassword').hasError('alpha')) {
      invalidRules.push('alpha');
    }
    if (this.form.get('newPassword').hasError('lower')) {
      invalidRules.push('lower');
    }
    if (this.form.get('newPassword').hasError('upper')) {
      invalidRules.push('upper');
    }
    if (this.form.get('newPassword').hasError('minlength')) {
      invalidRules.push('length');
    }
    return invalidRules;
  }

  getSatisfiedRuleKeys(): string[] {
    const validRules: string[] = [];

    const value = this.form.get('newPassword').value;

    if (value && !this.form.get('newPassword').hasError('digit')) {
      validRules.push('digit');
    }
    if (value && !this.form.get('newPassword').hasError('alpha')) {
      validRules.push('alpha');
    }
    if (value && !this.form.get('newPassword').hasError('lower')) {
      validRules.push('lower');
    }
    if (value && !this.form.get('newPassword').hasError('upper')) {
      validRules.push('upper');
    }
    if (value && !this.form.get('newPassword').hasError('minlength')) {
      validRules.push('length');
    }

    return validRules.slice();
  }

  getActiveValidators(): ValidatorFn[] {
    const activeValidators = [];

    activeValidators.push(Validators.minLength(this.passwordConfig.requiredLength));

    if (this.passwordConfig.requireDigit) {
      activeValidators.push(regexValidator(new RegExp('[0-9]'), { digit: true }));
    }
    if (this.passwordConfig.requireNonAlphanumeric) {
      activeValidators.push(regexValidator(new RegExp('[^a-zA-Z0-9]'), { alpha: true }));
    }
    if (this.passwordConfig.requireLowercase) {
      activeValidators.push(regexValidator(new RegExp('[a-z]'), { lower: true }));
    }
    if (this.passwordConfig.requireUppercase) {
      activeValidators.push(regexValidator(new RegExp('[A-Z]'), { upper: true }));
    }

    return activeValidators;
  }

  displayMinimumErrorMessage(): boolean {
    const keys = [
      PasswordErrorKeys.Digit,
      PasswordErrorKeys.Alphanumeric,
      PasswordErrorKeys.Lowercase,
      PasswordErrorKeys.Uppercase,
      PasswordErrorKeys.MinimumLength,
    ];

    const control = this.form.get('newPassword');
    const value = control.value;

    if (!value) {
      return false;
    }

    let minimumRequirementsError = false;
    for (const key of keys) {
      if (control.hasError(key)) {
        minimumRequirementsError = true;
      }
    }

    return minimumRequirementsError;
  }
}
