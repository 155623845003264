import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AppTranslationService,
  Country,
  MessageService,
} from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { forkJoin, Subscription } from 'rxjs';
import { LabTestClient } from '../../../lab-test/clients/lab-test.client';
import { LabClient } from '../../../lab/clients/lab.client';
import { LabDuplicateCheckQuery } from '../../../lab/models/lab-duplicate-check-query.model';
import { Lab } from '../../../lab/models/lab.model';
import { LaboratoryUserClient } from '../../../laboratory-user/clients/laboratory-user.client';
import { LabUser, LabUserLab } from '../../../laboratory-user/models/lab-user.model';
import { Address } from '../../../shared';
import { StakeholderTypes } from '../../../shared/enums';
import { LocaleService } from '../../../shared/services/locale.service';
import { UtilityService } from '../../../shared/services/utility.service';

@Component({
  selector: 'enter-lab-details',
  templateUrl: './enter-lab-details.component.html',
  styleUrls: ['./enter-lab-details.component.scss'],
})
export class EnterLabDetailsComponent implements OnInit, OnDestroy {
  @Output() onLabCreated = new EventEmitter<Lab>();
  private createdLabId: string;
  private createdLabUserId: string;

  form: FormGroup;
  isProcessing = false;
  isDuplicateLabFound = false;
  isDuplicateEmailFound = false;
  private subscriptions = new Array<Subscription>();
  isNationalIdVerified = false;
  countryList: Country[] = this.localeService.supportedCountryList;

  constructor(
    private formBuilder: FormBuilder,
    private labClient: LabClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    utilityService: UtilityService,
    private labUserClient: LaboratoryUserClient,
    private labTestClient: LabTestClient,
    private localeService: LocaleService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.initializeDuplicateEmailCheck();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      lab: this.formBuilder.group({
        nationalId: this.formBuilder.control(null, Validators.required),
        labName: this.formBuilder.control(null, Validators.required),
        stakeholder: this.formBuilder.group({
          address: this.formBuilder.group({
            countryId: this.formBuilder.control(null, Validators.required),
            address1: this.formBuilder.control(null, Validators.required),
            address2: this.formBuilder.control(null),
            city: this.formBuilder.control(null, Validators.required),
            postalCode: this.formBuilder.control(null, Validators.required),
          }),
          phone: this.formBuilder.control(null, Validators.required),
          fax: this.formBuilder.control(null),
          email: this.formBuilder.control(null, [Validators.required, Validators.email]),
        }),
      }),
      labUserLab: this.formBuilder.group({
        isAuthorizedRepresentative: this.formBuilder.control(true, Validators.required),
        labUser: this.formBuilder.group({
          stakeholder: this.formBuilder.group({
            firstName: this.formBuilder.control(null, Validators.required),
            lastName: this.formBuilder.control(null, Validators.required),
            email: this.formBuilder.control(null, [
              Validators.required,
              Validators.email,
            ]),
            confirmEmail: this.formBuilder.control(null, [
              Validators.required,
              Validators.email,
            ]),
          }),
        }),
      }),
    });
  }

  verifyNationalId() {
    this.form.get('lab.nationalId').markAsTouched();
    if (this.form.get('lab.nationalId').invalid) {
      return;
    }

    this.subscriptions.push(
      this.labClient
        .getLabDuplicates(
          new LabDuplicateCheckQuery(
            1,
            5,
            null,
            'id',
            false,
            null,
            this.form.get('lab.nationalId').value,
            null
          )
        )
        .subscribe(
          outcome => {
            if (!outcome.success) {
              this.handleError(outcome);
              return;
            }

            this.isDuplicateLabFound = outcome.value.totalCount > 0;
            this.isNationalIdVerified = !this.isDuplicateLabFound;
          },
          error => this.handleError(error)
        )
    );
  }

  private handleError(outcome: Outcome) {
    this.isProcessing = false;
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
  }

  get isDataValid(): boolean {
    return this.form.valid;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.subscriptions.push(
      forkJoin({
        createLabOutcome: this.labClient.createLab(this.getLabModel()),
        createLabUserOutcome: this.labUserClient.createLabUser(this.getLabUserModel()),
      }).subscribe(
        result => {
          this.createdLabId = result.createLabOutcome.value;
          this.createdLabUserId = result.createLabUserOutcome.value;

          if (!result.createLabOutcome.success || !result.createLabUserOutcome.success) {
            this.handleError(result.createLabOutcome);
            return;
          }
          this.associateLabUserLab();
        },
        error => this.handleError(error)
      )
    );
  }

  private associateLabUserLab() {
    this.subscriptions.push(
      this.labClient.createLabUserLabAssociation(this.getLabUserLabModel()).subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }

          this.onLabCreated.emit(this.getLabModel());
        },
        error => this.handleError(error)
      )
    );
  }

  private getLabModel(): Lab {
    const lab: Lab = this.form.getRawValue().lab;
    lab.id = this.createdLabId;
    lab.stakeholder.stakeholderType = StakeholderTypes.Laboratory;
    return lab;
  }

  private getLabUserModel(): LabUser {
    const labUser: LabUser = this.form.getRawValue().labUserLab.labUser;
    labUser.stakeholder.stakeholderType = StakeholderTypes.LaboratoryUser;
    labUser.stakeholder.address = new Address();
    labUser.stakeholder.address.countryId = this.form.get(
      'lab.stakeholder.address.countryId'
    ).value;

    labUser.localeIdentifier = this.translate.getCurrentLanguage();

    return labUser;
  }

  private getLabUserLabModel(): LabUserLab {
    const labUserLab = new LabUserLab();
    labUserLab.isAuthorizedRepresentative = this.form.get(
      'labUserLab.isAuthorizedRepresentative'
    ).value;
    labUserLab.labId = this.createdLabId;
    labUserLab.labUserId = this.createdLabUserId;

    return labUserLab;
  }

  private initializeDuplicateEmailCheck() {
    this.subscriptions.push(
      this.form
        .get('labUserLab.labUser.stakeholder.email')
        .valueChanges.subscribe(email => {
          this.performEmailDuplicateCheck();
        })
    );
  }

  private performEmailDuplicateCheck() {
    this.isDuplicateEmailFound = false;

    const email = this.form.get('labUserLab.labUser.stakeholder.email').value;
    if (!email || email.length < 1) {
      return;
    }

    this.subscriptions.push(
      this.labUserClient.getEmailDuplicateCheck(email).subscribe(outcome => {
        if (!outcome.success) {
          return;
        }

        if (outcome.value) {
          this.form
            .get('labUserLab.labUser.stakeholder.email')
            .setErrors({ duplicateEmail: true });
        }
      })
    );
  }
}
