import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { LabTest } from '../../../laboratory-user/models/lab-test.model';
import { UtilityService } from '../../../shared/services/utility.service';

@Component({
  selector: 'prescriber-lab-order-edit',
  templateUrl: './lab-order-edit.component.html',
  styleUrls: ['./lab-order-edit.component.scss'],
})
export class LabOrderEditComponent implements OnInit, OnChanges {
  @Input() labTest: LabTest;

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService,
    private datePipe: DatePipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labTest && changes.labTest.currentValue) {
      this.setFromModel();
    }
  }

  ngOnInit() {
    this.parentFormGroupDirective.form.addControl(
      'numberOfSpecimensSubmitted',
      new FormControl(null, Validators.required)
    );
    this.parentFormGroupDirective.form.addControl(
      'specimenCollectionDate',
      new FormControl(null, Validators.required)
    );
    this.parentFormGroupDirective.form.addControl(
      'specimenCollectionTime',
      new FormControl(null, Validators.required)
    );
    
  }

  disableControls(): void {
    this.parentFormGroupDirective.form.controls['numberOfSpecimensSubmitted'].disable();
    this.parentFormGroupDirective.form.controls['specimenCollectionDate'].disable();
    this.parentFormGroupDirective.form.controls['specimenCollectionTime'].disable();
  }

  getModel(): LabTest {
    const formValue = this.parentFormGroupDirective.form.getRawValue();
    const labTest: LabTest = formValue;

    labTest.specimenCollectionDateTime = this.getCombinedDateTime(
      formValue.specimenCollectionDate,
      formValue.specimenCollectionTime
    );

    if (this.labTest) {
      labTest.id = this.labTest.id;
      labTest.actualSpecimenArrivalDateTime = this.labTest.actualSpecimenArrivalDateTime;
      labTest.specimenTransportPickupDateTime = this.labTest.specimenTransportPickupDateTime;
      labTest.appointmentDateTime = this.labTest.appointmentDateTime;
      labTest.estimatedSpecimenArrivalDateTime = this.labTest.estimatedSpecimenArrivalDateTime;
      labTest.labId = this.labTest.labId;
      labTest.labTestComplete = this.labTest.labTestComplete;
      labTest.requiresSampleShippingKit = this.labTest.requiresSampleShippingKit;
      labTest.specimenTransportDispatchDateTime = this.labTest.specimenTransportDispatchDateTime;
      labTest.specimenTransportPickupDateTime = this.labTest.specimenTransportPickupDateTime;
      labTest.trackingNumber = this.labTest.trackingNumber;
      labTest.patientId = this.labTest.patientId;
      labTest.prescriberId = this.labTest.prescriberId;
    }
    return labTest;
  }

  private getCombinedDateTime(date: Date, time: Date): Date {
    let result = date;
    if (time) {
      result = new Date(`${date.toDateString()} ${time}` + ' UTC');
    }

    return result;
  }

  setFromModel() {
    if (!this.labTest) {
      return;
    }

    setTimeout(() => {
      this.parentFormGroupDirective.form.patchValue({
        ...this.labTest,
      });

      if(this.labTest !== null){
        let statuses = this.utilityService.getCurrentStatus(this.labTest.stakeholder.statuses);
        //statuses.statusType = 9; //to test out disabled state
        let currentStatus = this.utilityService.getStakeholderStatus(statuses);
        if(currentStatus === 'Specimen Shipped'){
          this.disableControls();
        }
      }

      if (this.labTest.specimenCollectionDateTime) {
        this.parentFormGroupDirective.form
          .get('specimenCollectionDate')
          .setValue(new Date(this.labTest.specimenCollectionDateTime));
        this.parentFormGroupDirective.form
          .get('specimenCollectionTime')
          .setValue(
            this.datePipe.transform(this.labTest.specimenCollectionDateTime, 'hh:mm a')
          );
      }
    }, 1000);
  }

  get collectionAndShippingHref(): string {
    return `assets/pdf/${this.translate.getTranslation(
      'LabUser.EditLabOrder.CollectionAndShippingPdfName'
    )}`;
  }
}
