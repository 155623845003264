import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ContactUsPageComponent, OrderSampleShippingKitCompletePageComponent, OrderSampleShippingKitPageComponent, ResourcesPageComponent } from './pages';

const ROUTES = [
  {
    path: 'public',
    // canActivate: [AuthenticationGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomePageComponent },
      { path: 'order-sample-shipping-kit', component: OrderSampleShippingKitPageComponent },
      { path: 'order-sample-shipping-kit-complete', component: OrderSampleShippingKitCompletePageComponent },
      { path: 'resources', component: ResourcesPageComponent },
      { path: 'contact-us', component: ContactUsPageComponent }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class PublicInsecureRoutingModule {}
