import { Injectable, OnDestroy } from '@angular/core';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PagedQuery } from '../../shared';
import { LaboratoryUserClient } from '../clients/laboratory-user.client';
import { LabTest } from '../models/lab-test.model';
import { LabUserLabPagedQuery } from '../models/lab-user-lab-paged-query.model';
import { LabUserLab } from '../models/lab-user.model';

@Injectable({
  providedIn: 'root',
})
export class LaboratoryUserService implements OnDestroy {
  private subscriptions = new Array<Subscription>();

  labTestPagedListSubject = new BehaviorSubject<PaginatedList<LabTest>>(
    new PaginatedList<LabTest>()
  );
  labUserPagedListSubject = new BehaviorSubject<PaginatedList<LabUserLab>>(
    new PaginatedList<LabUserLab>()
  );
  loggedInLaboratoryUserLabSubject = new BehaviorSubject<LabUserLab>(null);

  selectedLabTestSubject = new BehaviorSubject<LabTest>(null);

  isLoadingSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private labUserClient: LaboratoryUserClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  setLabTestPage(pagedQuery: PagedQuery, id: string) {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labUserClient.getLabTestsByLabUser(pagedQuery, id).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.labTestPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  setLabUserPage(pagedQuery: LabUserLabPagedQuery, labId: string) {
    if (!labId) {
      this.labUserPagedListSubject.next(new PaginatedList<LabUserLab>());
      return;
    }

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labUserClient.getLabUserListPaged(pagedQuery, labId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.labUserPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  loadLabTest(labTestId: string, forceRefresh: boolean = false) {
    if (
      !labTestId ||
      (this.selectedLabTestSubject.value &&
        this.selectedLabTestSubject.value.id === labTestId &&
        !forceRefresh)
    ) {
      return;
    }

    this.clearSubscriptions();

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labUserClient.getLabTest(labTestId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.selectedLabTestSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  clearService() {
    this.labTestPagedListSubject.next(new PaginatedList<LabTest>());
    this.labUserPagedListSubject.next(new PaginatedList<LabUserLab>());
    this.loggedInLaboratoryUserLabSubject.next(null);
    this.selectedLabTestSubject.next(null);
  }

  loadAuthenticatedLabUser(forceRefresh: boolean = false) {
    if (!this.authenticationService.claimsPrincipal.hasRole('LaboratoryUser')) {
      this.loggedInLaboratoryUserLabSubject.next(null);
      return;
    }

    if (
      !forceRefresh &&
      this.loggedInLaboratoryUserLabSubject.value &&
      this.authenticationService.userInfo.email.toLowerCase() ===
        this.loggedInLaboratoryUserLabSubject.value.labUser.stakeholder.email.toLowerCase()
    ) {
      return;
    }

    this.loggedInLaboratoryUserLabSubject.next(null);

    this.subscriptions.push(
      this.labUserClient
        .getLabUserLabByEmail(this.authenticationService.userInfo.email)
        .subscribe(
          outcome => {
            if (!outcome.success) {
              return;
            }

            this.loggedInLaboratoryUserLabSubject.next(outcome.value);
          },
          error => {
            this.handleError(error);
          }
        )
    );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
    this.isLoadingSubject.next(false);
  }

  get isLoading(): boolean {
    return this.isLoadingSubject.value;
  }

  get labUserPagedList(): PaginatedList<LabUserLab> {
    return this.labUserPagedListSubject.value;
  }

  get labTestPagedList(): PaginatedList<LabTest> {
    return this.labTestPagedListSubject.value;
  }

  get loggedInLaboratoryUserLab(): LabUserLab {
    return this.loggedInLaboratoryUserLabSubject.value;
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.subscriptions = new Array<Subscription>();
  }
}
