import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import {
  CoreDirectivesModule,
  MaterialModule,
  PipeModule,
} from '@roctavian-abstractions/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PrescriberDelegateModule } from '../prescriber-delegate/prescriber-delegate.module';
import { SharedModule } from '../shared/shared.module';
import { CancelLabTestEditDialogComponent } from './components/cancel-lab-test-edit-dialog/cancel-lab-test-edit-dialog.component';
import { LabOrderEditComponent } from './components/lab-order-edit/lab-order-edit.component';
import { LabOrderListComponent } from './components/lab-order-list/lab-order-list.component.';
import { OfficeContactDetailComponent } from './components/office-contact-detail/office-contact-detail.component';
import { OfficeContactListComponent } from './components/office-contact-list/office-contact-list.component';
import { PatientDetailComponent } from './components/patient-detail/patient-detail.component';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { RegistrationStepOneComponent } from './components/registration-step-one/registration-step-one.component';
import { RegistrationStepTwoComponent } from './components/registration-step-two/registration-step-two.component';
import { RemoveOfficeContactAssociationDialogComponent } from './components/remove-office-contact-association-dialog/remove-office-contact-association-dialog.component';
import { CompleteAttestationPageComponent } from './pages/complete-attestation-page/complete-attestation-page.component';
import { CompleteLabOrderPageComponent } from './pages/complete-lab-order-page/complete-lab-order-page.component';
import { ManageLabDelegatesPageComponent } from './pages/manage-lab-delegates-page/manage-lab-delegates-page.component';
import { ManageOfficeContactsPageComponent } from './pages/manage-office-contacts-page/manage-office-contacts-page.component';
import { MyPatientsPageComponent } from './pages/my-patients-page/my-patients-page.component';
import { EditLabTestPageComponent } from './pages/order-edit-page/order-edit-page.component';
import { OrdersPageComponent } from './pages/orders-page/orders-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { formatDatePipe } from './pipes/format-date.pipe';
import { PrescriberRoutingModule } from './prescriber-routing.module';
import { PrescriberService } from './services/prescriber.service';

@NgModule({
  declarations: [
    OrdersPageComponent,
    CompleteLabOrderPageComponent,
    EditLabTestPageComponent,
    LabOrderListComponent,
    LabOrderEditComponent,
    CancelLabTestEditDialogComponent,
    MyPatientsPageComponent,
    ManageOfficeContactsPageComponent,
    ManageLabDelegatesPageComponent,
    RegisterPageComponent,
    RegistrationStepOneComponent,
    RegistrationStepTwoComponent,
    CompleteAttestationPageComponent,
    OfficeContactListComponent,
    OfficeContactDetailComponent,
    RemoveOfficeContactAssociationDialogComponent,
    PatientListComponent,
    PatientDetailComponent,
    formatDatePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    PrescriberRoutingModule,
    CoreDirectivesModule,
    SharedModule,
    MatExpansionModule,
    PrescriberDelegateModule,
    NgxMaterialTimepickerModule,
  ],
  providers: [
    PrescriberService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false },
    },
  ],
  exports: [],
  entryComponents: [
    RemoveOfficeContactAssociationDialogComponent,
    CancelLabTestEditDialogComponent,
  ],
})
export class PrescriberModule {}
