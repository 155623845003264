import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService, GoogleAnalyticsService } from '@roctavian-abstractions/core';
import {
  AuthenticationService,
  SessionManagementService,
} from '@roctavian-abstractions/identity';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roctavian-eumea-portal-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  isAuthenticated = false;

  htmlPipeTest1: string =
    '<span style=\'color: #3998ff\'>This is text with inline style that has been sanitized.</span>';

  htmlPipeTest2: string =
    '<span style=\'color: #3998ff\'>This is text with inline style passed through the safeHtml pipe.</span>';

  timeout: string;
  private subscriptions = new Array<Subscription>();

  constructor(
    private authService: AuthenticationService,
    private configService: ConfigService,
    private sessionService: SessionManagementService,
    private googleService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.authService.isLoggedIn().subscribe(data => {
        this.isAuthenticated = data;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isGoogleAnalyticsConfigured() {
    return this.googleService.isConfigured;
  }

  hasSessionStarted() {
    return this.sessionService.sessionStatus;
  }

  enableSessionTimeout() {
    if (this.timeout && !isNaN(+this.timeout)) {
      this.configService.update('sessionTimeout', this.timeout);
    }

    this.sessionService.enableSessionManagement();
  }

  disableSessionTimeout() {
    this.sessionService.disableSessionManagement();
  }

  logout(): void {
    this.authService.logout();
  }

  handleClipboardCopy(event) {
    console.log('Text has been copied!');
  }

  sendTestGAEvent() {
    this.googleService.submitEvent('Testing Category', 'Testing Action', 'Testing Label');
  }
}
