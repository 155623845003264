import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PatientPrescriber } from '../../../patient/models/patient-prescriber.model';
import { PagedQuery } from '../../../shared';
import { UtilityService } from '../../../shared/services/utility.service';
import { Prescriber } from '../../models/prescriber.model';
import { PrescriberService } from '../../services/prescriber.service';

@Component({
  selector: 'patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent implements OnInit, OnDestroy {
  pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  private authenticatedPrescriber: Prescriber;

  displayedColumns = [
    'name',
    'id',
    'birthDate',
    'stakeholder.attestations.hasConsent',
    'stakeholder.statuses.statusType',
  ];

  constructor(
    public prescriberService: PrescriberService,
    public utilityService: UtilityService
  ) {}

  refreshList() {
    if (this.authenticatedPrescriber) {
      this.prescriberService.setPatientPrescribersPageByPrescriberId(
        this.pagedQuery,
        this.authenticatedPrescriber.id
      );
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.prescriberService.loggedInPrescriberSubject.subscribe(prescriber => {
        this.authenticatedPrescriber = prescriber;
        this.refreshList();
      })
    );

    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.patientPrescriberPagedListSubject.next(
      new PaginatedList<PatientPrescriber>()
    );
  }

  onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }
}
