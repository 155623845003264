import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, CanActivate } from '@angular/router';
import { AuthenticationGuard } from '@roctavian-abstractions/identity';
import { UserAttestationCompleteGuard } from '../shared/guards/user-attestation-complete.guard';
import { OrdersPageComponent } from './pages/orders-page/orders-page.component';
import { EditLabTestPageComponent } from './pages/order-edit-page/order-edit-page.component';
import { CompleteLabOrderPageComponent } from './pages/complete-lab-order-page/complete-lab-order-page.component';
import { PrescriberDelegateMyPatientsPageComponent } from './pages/my-patients-page/my-patients-page.component';

const ROUTES = [
    {
        path: 'prescriber-delegate',
        canActivate: [UserAttestationCompleteGuard],
        children: [
            { path: '', redirectTo: 'my-patients', pathMatch: 'full' },
            { path: 'my-patients', component: PrescriberDelegateMyPatientsPageComponent, canActivate: [AuthenticationGuard] },
            {
                path: 'orders',
                canActivate: [AuthenticationGuard],
                children: [
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  },
                  {
                    path: 'list',
                    component: OrdersPageComponent
                  },
                  {
                    path: ':id',
                    children: [
                      { path: 'edit', component: EditLabTestPageComponent },
                      { path: 'complete', component: CompleteLabOrderPageComponent }
                    ]
                  }
                ]
              }
        ]
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class PrescriberDelegateRoutingModule { }
