import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'public-insecure-leaving-site-dialog',
  templateUrl: './leaving-site-dialog.component.html',
  styleUrls: ['./leaving-site-dialog.component.scss'],
})
export class LeavingSiteDialogComponent implements OnInit {
  constructor(public reference: MatDialogRef<LeavingSiteDialogComponent>) {}

  ngOnInit() {}
}
