import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { LocaleService } from '../../../shared/services/locale.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { FirstVisitDialogComponent } from '../../components';

@Component({
  selector: 'roctavian-eumea-portal-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  dialogSeen = this.utilityService.hasDialogBeenSeen();

  constructor(
    private translate: AppTranslationService,
    private localeService: LocaleService,
    private matDialog: MatDialog,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    if (!this.dialogSeen) {
      const dialogRef = this.matDialog.open(FirstVisitDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
        width: '500px',
      });
    }
  }

  get stepsImgName(): string {
    return `/assets/images/${this.translate.getTranslation('Home.stepsImgName')}`;
  }

  public get isFR(): boolean {
    if (!this.localeService.ipBasedCountry) {
      return false;
    }

    if (
      this.localeService.ipBasedCountry &&
      (this.localeService.ipBasedCountry.name.toLowerCase() == 'fr' ||
        this.translate.getTranslation('Home.IntroText3') == '')
    ) {
      return true;
    }

    return false;
  }
}
