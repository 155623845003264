export enum ContentTypes {
  bmp,
  docx,
  gif,
  jpg,
  jpeg,
  json,
  pdf,
  png,
  pptx,
  text,
  xlsx,
}
