import { Pipe, PipeTransform } from '@angular/core';
import { DocumentTypes } from '@app/core/enums';
import { documentTypeNames } from '@app/core/models';

@Pipe({ name: 'documentTypeString' })
export class DocumentTypeStringPipe implements PipeTransform {
  transform(value: DocumentTypes): string {
    return documentTypeNames[DocumentTypes[value]];
  }
}
