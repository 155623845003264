import { ContentTypes } from '../enums';

/**
 * Content types mapped by @ref ContentTypes enum
 *
 * @type {*} */
export const contentTypeDescriptions = new Map<ContentTypes, string>([
  [ContentTypes.bmp, 'Bitmap image'],
  [
    ContentTypes.docx,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  [ContentTypes.gif, 'image/gif'],
  [ContentTypes.jpg, 'image/jpeg'],
  [ContentTypes.jpeg, 'image/jpeg'],
  [ContentTypes.json, 'application/json'],
  [ContentTypes.pdf, 'application/pdf'],
  [ContentTypes.png, 'image/png'],
  [
    ContentTypes.pptx,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  [ContentTypes.text, 'text/plain'],
  [
    ContentTypes.xlsx,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
]);
