import { Prescriber } from '../../prescriber/models/prescriber.model';
import { Auditable, Stakeholder } from '../../shared';

export class PrescriberDelegate extends Auditable {
  id: string;
  stakeholder: Stakeholder;
  prescriberDelegatePrescribers: PrescriberDelegatePrescriber[];
}

export class PrescriberDelegatePrescriber extends Auditable {
  id: string;

  isCurrent?: boolean;

  prescriberDelegateId?: string;

  prescriberId?: string;

  prescriberDelegate?: PrescriberDelegate;
  prescriber: Prescriber;
}
