import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyEquals'
})
export class PropertyEqualsPipe implements PipeTransform {

  transform(items: any[], property: string, filterValue: any): any {
    return filterValue ? items.filter(value => value[property] == filterValue) : items;
  }

}
