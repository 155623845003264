import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppTranslationService,
  Country,
  MessageService
} from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import RestrictedCountryElementsJSON from '../../../../../assets/country/restricted.json';
import { LabTestClient } from '../../../lab-test/clients/lab-test.client';
import { LabClient } from '../../../lab/clients/lab.client';
import { LabPagedQuery } from '../../../lab/models/lab-paged-query.model';
import { Lab } from '../../../lab/models/lab.model';
import { LabTest } from '../../../laboratory-user/models/lab-test.model';
import { LabUser, LabUserLab } from '../../../laboratory-user/models/lab-user.model';
import { LaboratoryUserService } from '../../../laboratory-user/service/laboratory-user.service';
import { PrescriberDelegateService } from '../../../prescriber-delegate/services/prescriber-delegate.service';
import { PrescriberClient } from '../../../prescriber/clients/prescriber.client';
import { PrescriberLab } from '../../../prescriber/models/prescriber-lab.model';
import { Prescriber } from '../../../prescriber/models/prescriber.model';
import { PrescriberService } from '../../../prescriber/services/prescriber.service';
import { Address, PagedQuery, Stakeholder } from '../../../shared';
import { StakeholderTypes } from '../../../shared/enums';
import { CountryType } from '../../../shared/enums/countries.enum';
import { RestrictedElements } from '../../../shared/models/restrincted-elements.model';
import { LocaleService } from '../../../shared/services/locale.service';
import { StakeholderService } from '../../../shared/services/stakeholder.service';
import { UtilityService } from '../../../shared/services/utility.service';
import { PatientClient } from '../../clients/patient.client';
import { Patient } from '../../models/patient.model';
import { CancelPatientRegistrationDialogComponent } from '../cancel-patient-registration-dialog/cancel-patient-registration-dialog.component';
import { EnterLabDetailsComponent } from '../enter-lab-details/enter-lab-details.component';

@Component({
  selector: 'patient-register-lab-information',
  templateUrl: './register-lab-information.component.html',
  styleUrls: ['./register-lab-information.component.scss'],
})
export class RegisterLabInformationComponent implements OnInit, OnDestroy {
  @ViewChild('enterLabDetails', { static: false })
  enterLabDetails: EnterLabDetailsComponent;
  showPrescriberDetails = false;
  latestLabTest: LabTest;
  latestLabPrescriber: PrescriberLab;
  loggedInLabUserLab: LabUserLab;

  private labTestId: string;

  prescriber: Prescriber;
  labUser: LabUser;
  private patientId: string;
  patient: Patient;
  isProcessing = false;
  private subscriptions = new Array<Subscription>();
  form: FormGroup;
  labList: PaginatedList<Lab>;
  isLoadingLabList = false;
  selectedLab: Lab;
  showOtherLab = true;
  showSampleShipmentKitBanner = true;
  showDoesLabRequireSampleShipmentKit = true;
  country: Country;
  countryType: CountryType;
  restrictedDataElements: RestrictedElements[] = RestrictedCountryElementsJSON;
  countryElements: RestrictedElements = null;

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private patientClient: PatientClient,
    public utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private labClient: LabClient,
    private authService: AuthenticationService,
    private prescriberService: PrescriberService,
    private localeService: LocaleService,
    private labTestClient: LabTestClient,
    private prescriberClient: PrescriberClient,
    private matDialog: MatDialog,
    private router: Router,
    private laboratoryUserService: LaboratoryUserService,
    private stakeholderService: StakeholderService,
    private datePipe: DatePipe,
    private prescriberDelegateService: PrescriberDelegateService
  ) {}

  ngOnInit() {
    this.localeService.loadIPAddress();
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.patientId = params['id'];
        this.labTestId = params['labOrderId'];

        this.loadPrescriber(this.route.snapshot.queryParams['prescriberId']);
        this.loadPatient();
      })
    );

    this.buildForm();
    this.initializeLabAutocomplete();

    this.subscriptions.push(
      this.form.get('prescriberLab.isOtherLab').valueChanges.subscribe(value => {
        if (value) {
          this.form.get('prescriberLab.lab').clearValidators();
          this.form.get('prescriberLab.lab').setErrors(null);
        } else {
          this.form.get('prescriberLab.lab').setValidators(Validators.required);
        }
      })
    );

    this.subscriptions.push(
      this.form.get('prescriberLab.lab').valueChanges.subscribe((value: Lab) => {
        this.selectedLab = value;
      })
    );

    this.subscriptions.push(
      this.form.get('prescriberLab.isPrescriberOffice').valueChanges.subscribe(value => {
        if (value == true) {
          this.form.get('prescriberLab.lab').clearValidators();
          this.form.get('prescriberLab.lab').setErrors(null);
        } else {
          this.form.get('prescriberLab.lab').setValidators(Validators.required);
        }
      })
    );

    if (this.authService.claimsPrincipal.hasRole('Prescriber')) {
      this.subscriptions.push(
        this.prescriberService.loggedInPrescriberSubject.subscribe(prescriber => {
          if (!prescriber) {
            return;
          }

          this.prescriber = prescriber;
          if (this.prescriber != null) {
            this.country = prescriber.institution.stakeholder.address.country;
            this.countryElements = this.restrictedDataElements.find(
              item =>
                item.countryName ==
                prescriber.institution.stakeholder.address.country.description
            );

            if (this.countryElements != null) {
              this.showOtherLab = this.countryElements.showOtherLab;
              this.showDoesLabRequireSampleShipmentKit = this.countryElements.showDoesLabRequireSampleShipmentKit;
              this.showSampleShipmentKitBanner = this.countryElements.showSampleShipmentKitBanner;
              if (!this.countryElements.requireSpecimenShipmentKit) {
                this.form
                  .get('labTest.requiresSampleShippingKit')
                  .setValue(this.countryElements.requireSpecimenShipmentKit);
              }
            }
          }

          this.form.get('prescriberLab.prescriberId').setValue(prescriber.id);
        })
      );
    } else if (this.authService.claimsPrincipal.hasRole('LaboratoryUser')) {
      this.laboratoryUserService.loggedInLaboratoryUserLabSubject.subscribe(labUser => {
        if (!labUser) {
          return;
        }

        this.country = labUser.labUser.stakeholder.address.country;
        this.countryElements = this.restrictedDataElements.find(
          item =>
            item.countryName == labUser.labUser.stakeholder.address.country.description
        );
        if (this.countryElements != null) {
          this.showOtherLab = this.countryElements.showOtherLab;
          this.showDoesLabRequireSampleShipmentKit = this.countryElements.showDoesLabRequireSampleShipmentKit;
          this.showSampleShipmentKitBanner = this.countryElements.showSampleShipmentKitBanner;
          if (!this.countryElements.requireSpecimenShipmentKit) {
            this.form
              .get('labTest.requiresSampleShippingKit')
              .setValue(this.countryElements.requireSpecimenShipmentKit);
          }
        }
      });

      this.showPrescriberDetails = true;
    } else if (this.authService.claimsPrincipal.hasRole('PrescriberDelegate')) {
      this.prescriberDelegateService.loggedInPrescriberDelegateSubject.subscribe(
        prescriberDelegate => {
          if (!prescriberDelegate) {
            return;
          }

          this.country = prescriberDelegate.stakeholder.address.country;
          this.countryElements = this.restrictedDataElements.find(
            item => item.countryName == this.country.description
          );
          if (this.countryElements != null) {
            this.showOtherLab = this.countryElements.showOtherLab;
            this.showDoesLabRequireSampleShipmentKit = this.countryElements.showDoesLabRequireSampleShipmentKit;
            this.showSampleShipmentKitBanner = this.countryElements.showSampleShipmentKitBanner;
            if (!this.countryElements.requireSpecimenShipmentKit) {
              this.form
                .get('labTest.requiresSampleShippingKit')
                .setValue(this.countryElements.requireSpecimenShipmentKit);
            }
          }
        }
      );

      this.showPrescriberDetails = true;
    }

    this.subscriptions.push(
      this.laboratoryUserService.loggedInLaboratoryUserLabSubject.subscribe(
        labUserLab => {
          this.loggedInLabUserLab = labUserLab;
        }
      )
    );
  }

  private initializeLabAutocomplete() {
    this.subscriptions.push(
      this.form
        .get('prescriberLab.lab')
        .valueChanges.pipe(
          debounceTime(300),
          tap(() => (this.isLoadingLabList = true)),
          switchMap(value =>
            this.labClient
              .getPagedList(
                new LabPagedQuery(
                  1,
                  10,
                  value,
                  'labName',
                  false,
                  null,
                  null,
                  this.countryId
                )
              )
              .pipe(finalize(() => (this.isLoadingLabList = false)))
          )
        )
        .subscribe(labPagedCollection => (this.labList = labPagedCollection.value))
    );
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      prescriberLab: this.formBuilder.group({
        isPrescriberOffice: this.formBuilder.control(null, Validators.required),   
        prescriberId: this.formBuilder.control(null, Validators.required),
        lab: this.formBuilder.control(null, Validators.required),
        isOtherLab: this.formBuilder.control(false),
      }),
      labTest: this.formBuilder.group({
        appointmentDateTime: this.formBuilder.control(null),
        requiresSampleShippingKit: this.formBuilder.control(null),
        cdcField: this.formBuilder.control(null, Validators.required),
      }),
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private loadPatient() {
    this.patient = null;

    if (!this.patientId) {
      return;
    }

    this.subscriptions.push(
      this.patientClient.getPatient(this.patientId).subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }

          this.patient = outcome.value;
          this.prescriber = this.patient.patientsPrescribers.find(
            prescriber => prescriber.isCurrent === true
          ).prescriber;
          if (!this.prescriber) {
            return;
          }
          this.loadPrescriber(this.prescriber.id);
        },
        error => this.handleError(error)
      )
    );
  }

  private loadLatestLabInformation() {
    this.latestLabTest = null;

    this.subscriptions.push(
      forkJoin({
        latestPrescriberLabOutcome: this.prescriberClient.getPrescriberLabListPaged(
          new PagedQuery(1, 1, null, 'addDate', true),
          this.prescriber.id
        ),
        latestPatientLabTestOutcome: this.patientClient.getPatientLabTestsByPatientId(
          this.patientId,
          new PagedQuery(1, 1, null, 'addDate', true)
        ),
      }).subscribe(
        results => {
          if (
            results.latestPatientLabTestOutcome.success &&
            results.latestPatientLabTestOutcome.value.items &&
            results.latestPatientLabTestOutcome.value.items.length > 0
          ) {
            this.latestLabTest = results.latestPatientLabTestOutcome.value.items[0];

            if (this.latestLabTest.labId) {
              this.subscriptions.push(
                this.prescriberClient
                  .getPrescriberLabListPaged(
                    new PagedQuery(1, 1, this.latestLabTest.labId, 'addDate', true),
                    this.prescriber.id
                  )
                  .subscribe(
                    matchingPrescriberLabOutcome => {
                      if (
                        matchingPrescriberLabOutcome.success &&
                        matchingPrescriberLabOutcome.value &&
                        matchingPrescriberLabOutcome.value.items &&
                        matchingPrescriberLabOutcome.value.items.length > 0
                      ) {
                        this.latestLabPrescriber =
                          matchingPrescriberLabOutcome.value.items[0];
                      } else if (
                        results.latestPrescriberLabOutcome.success &&
                        results.latestPrescriberLabOutcome.value.items &&
                        results.latestPrescriberLabOutcome.value.items.length > 0
                      ) {
                        this.latestLabPrescriber =
                          results.latestPrescriberLabOutcome.value.items[0];
                      }

                      this.populateLatestData();
                    },
                    error => this.handleError(error)
                  )
              );

              // Make sure to return and populate latest inside the get
              return;
            }
          }
          if (
            results.latestPrescriberLabOutcome.success &&
            results.latestPrescriberLabOutcome.value.items &&
            results.latestPrescriberLabOutcome.value.items.length > 0
          ) {
            this.latestLabPrescriber = results.latestPrescriberLabOutcome.value.items[0];
          }

          this.populateLatestData();
        },
        error => this.handleError(error)
      )
    );
  }

  private populateLatestData() {
    if (!this.latestLabTest) {
      return;
    }

    if (this.latestLabTest) {
      this.form
        .get('labTest.appointmentDateTime')
        .setValue(this.latestLabTest.appointmentDateTime);
      this.form
        .get('labTest.requiresSampleShippingKit')
        .setValue(this.latestLabTest.requiresSampleShippingKit);

      if (this.latestLabPrescriber) {
        this.form
          .get('prescriberLab.isPrescriberOffice')
          .setValue(
            this.latestLabPrescriber.isPrescriberOffice &&
              this.latestLabPrescriber.labId === this.latestLabTest.labId
          );
        if (
          this.latestLabPrescriber.labId !== this.latestLabTest.labId ||
          !this.form.get('prescriberLab.lab').value
        ) {
          this.form.get('prescriberLab.lab').setValue(this.latestLabTest.lab);
        }
      }
    }
  }

  private handleError(outcome: Outcome) {
    this.isProcessing = false;
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
  }

  displayLabFn(lab: Lab): string {
    if (!lab) {
      return '';
    }

    return lab.labName;
  }

  get isDataValid(): boolean {
    if (this.form.invalid) {
      return false;
    }
    if (
      this.form.get('prescriberLab.isOtherLab').value == true &&
      this.enterLabDetails &&
      !this.enterLabDetails.isDataValid
    ) {
      return false;
    }
    if (
      !this.form.get('prescriberLab.isPrescriberOffice').value &&
      !this.form.get('prescriberLab.isOtherLab').value &&
      (!this.form.get('prescriberLab.lab').value ||
        !this.form.get('prescriberLab.lab').value.id)
    ) {
      return false;
    }

    return true;
  }

  submit() {
    this.isProcessing = true;

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.isProcessing = false;
      return;
    }

    if (this.loggedInLabUserLab) {
      this.labCreated(this.loggedInLabUserLab.lab);
      this.isProcessing = false;
      return;
    }

    if (this.form.get('prescriberLab.isPrescriberOffice').value == true) {
      this.subscriptions.push(
        this.prescriberClient
          .getPrescriberLabListPaged(
            new PagedQuery(1, 999, null, 'id', false),
            this.form.get('prescriberLab.prescriberId').value
          )
          .subscribe(
            outcome => {
              if (!outcome.success) {
                this.handleError(outcome);
                return;
              }

              let lab: Lab = null;

              if (outcome.value.items) {
                const prescriberLab = outcome.value.items.find(
                  prescriberLab => prescriberLab.isPrescriberOffice
                );
                if (prescriberLab) {
                  lab = prescriberLab.lab;
                }
              }

              if (lab) {
                this.saveLabTest(lab.id);
                this.isProcessing = false;
                return;
              }

              this.createNewLabAndAssociate();
            },
            error => this.handleError(error)
          )
      );

      return;
    }

    if (this.form.get('prescriberLab.isOtherLab').value == true) {
      this.enterLabDetails.submit();
    } else {
      this.saveLabTest();
    }
    // save cds info
    

    this.isProcessing = false;
  }

  private createNewLabAndAssociate() {
    const lab = this.generateNewLabFromPrescriber(this.prescriber);
    this.subscriptions.push(
      this.labClient.createLab(lab).subscribe(
        createLabOutcome => {
          if (!createLabOutcome.success) {
            this.handleError(createLabOutcome);
            return;
          }

          const prescriberLab = new PrescriberLab();
          prescriberLab.isPrescriberOffice = true;
          prescriberLab.labId = createLabOutcome.value;
          prescriberLab.prescriberId = this.prescriber.id;

          this.subscriptions.push(
            this.prescriberClient.createPrescriberLabAssociation(prescriberLab).subscribe(
              associateOutcome => {
                if (!associateOutcome.success) {
                  this.handleError(associateOutcome);
                  return;
                }

                this.saveLabTest(prescriberLab.labId);
              },
              error => this.handleError(error)
            )
          );
        },
        error => this.handleError(error)
      )
    );
  }

  private generateNewLabFromPrescriber(prescriber: Prescriber): Lab {
    const lab = new Lab();
    lab.nationalId = this.prescriber.nationalId;
    lab.labName = this.prescriber.institution.stakeholder.address.name;
    lab.stakeholder = new Stakeholder();
    lab.stakeholder.stakeholderType = StakeholderTypes.Laboratory;
    lab.stakeholder.address = new Address();
    lab.stakeholder.address.address1 = this.prescriber.institution.stakeholder.address.address1;
    lab.stakeholder.address.address2 = this.prescriber.institution.stakeholder.address.address2;
    lab.stakeholder.address.city = this.prescriber.institution.stakeholder.address.city;
    lab.stakeholder.address.postalCode = this.prescriber.institution.stakeholder.address.postalCode;
    lab.stakeholder.address.countryId = this.prescriber.institution.stakeholder.address.countryId;
    lab.stakeholder.phone = this.prescriber.stakeholder.phone;
    lab.stakeholder.email = this.prescriber.stakeholder.email;

    return lab;
  }

  labCreated(lab: Lab) {
    this.form.get('prescriberLab.lab').setValue(lab);
    this.form.get('prescriberLab.isOtherLab').setValue(false);
    this.saveLabTest(lab.id);
  }

  saveLabTest(selectedLabIdOverride?: string) {
    const labTest: LabTest = this.form.get('labTest').value;
    labTest.appointmentDateTime = this.datePipe.transform(
      labTest.appointmentDateTime,
      'MM/dd/yyyy'
    );
    labTest.id = this.labTestId;

    if (selectedLabIdOverride) {
      labTest.labId = selectedLabIdOverride;
    } else {
      labTest.labId = this.form.get('prescriberLab.lab').value.id;
    }

    labTest.patientId = this.patientId;
    labTest.prescriberId = this.form.get('prescriberLab.prescriberId').value;
    labTest.stakeholder = new Stakeholder();
    labTest.stakeholder.stakeholderType = StakeholderTypes.LabTest;
    labTest.cdcStatement = this.form.get('labTest.cdcField').value;

    const prescriberLab = new PrescriberLab();
    prescriberLab.labId = labTest.labId;
    prescriberLab.prescriberId = labTest.prescriberId;
    prescriberLab.isPrescriberOffice = this.form.get(
      'prescriberLab.isPrescriberOffice'
    ).value;
    if (this.loggedInLabUserLab) {
      prescriberLab.isPrescriberOffice = this.loggedInLabUserLab.lab.isPrescriberOffice;
    }

    if (labTest.id) {
      this.subscriptions.push(
        forkJoin({
          updateLabTestOutcome: this.labTestClient.updateLabTest(labTest),
          associatePrescriberLabOutcome: this.prescriberClient.createPrescriberLabAssociation(
            prescriberLab
          ),
        }).subscribe(
          results => {
            if (!results.updateLabTestOutcome.success) {
              this.handleError(results.updateLabTestOutcome);
              return;
            }

            if (this.loggedInLabUserLab) {
              this.router.navigate(['laboratory-user', 'orders', 'list']);
            } else {
              this.router.navigate(['/patient', 'register', this.patientId, 'complete']);
            }
          },
          error => this.handleError(error)
        )
      );
    } else {
      this.subscriptions.push(
        forkJoin({
          createLabTestOutcome: this.labTestClient.createLabTest(labTest),
          associatePrescriberLabOutcome: this.prescriberClient.createPrescriberLabAssociation(
            prescriberLab
          ),
        }).subscribe(
          results => {
            if (!results.createLabTestOutcome.success) {
              this.handleError(results.createLabTestOutcome);
              return;
            }

            if (this.loggedInLabUserLab) {
              this.router.navigate(['laboratory-user', 'orders', 'list']);
            } else {
              this.router.navigate(['/patient', 'register', this.patientId, 'complete']);
            }
          },
          error => this.handleError(error)
        )
      );
    }
  }

  cancel() {
    const dialogRef = this.matDialog.open(CancelPatientRegistrationDialogComponent, {
      data: {},
      width: '1000px',
    });

    dialogRef.componentInstance.onYesClicked.subscribe(() => {
      if (this.authService.claimsPrincipal.hasRole('Prescriber')) {
        this.router.navigate(['/prescriber', 'my-patients']);
      } else if (this.authService.claimsPrincipal.hasRole('LaboratoryUser')) {
        this.router.navigate(['laboratory-user', 'orders', 'list']);
      } else if (this.authService.claimsPrincipal.hasRole('PrescriberDelegate')) {
        this.router.navigate(['/prescriber-delegate', 'my-patients']);
      }
    });
  }

  private loadPrescriber(prescriberId: string) {
    if (!prescriberId) {
      return;
    }
    this.subscriptions.push(
      this.prescriberClient.getPrescriber(prescriberId).subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }
          this.prescriber = outcome.value;

          this.form.get('prescriberLab.prescriberId').setValue(this.prescriber.id);

          if (this.authService.claimsPrincipal.hasRole('LaboratoryUser')) {
            this.form.get('prescriberLab.isPrescriberOffice').clearValidators();
            this.form.get('prescriberLab.isPrescriberOffice').setErrors(null);
            this.form.get('prescriberLab.lab').setValue(this.loggedInLabUserLab.lab);
          }

          if (this.authService.claimsPrincipal.hasRole('PrescriberDelegate')) {
            this.form.get('prescriberLab.prescriberId').setValue(this.prescriber.id);
          }
        },
        error => this.handleError(error)
      )
    );
  }

  get countryId(): string {
    if (this.prescriber) {
      return this.prescriber.institution.stakeholder.address.countryId;
    } else if (this.stakeholderService.currentStakeholder) {
      return this.stakeholderService.currentStakeholder.address.countryId;
    } else if (this.laboratoryUserService.loggedInLaboratoryUserLabSubject.value) {
      return this.laboratoryUserService.loggedInLaboratoryUserLabSubject.value.labUser
        .stakeholder.address.countryId;
    } else if (this.prescriberService.loggedInPrescriberSubject.value) {
      return this.prescriberService.loggedInPrescriberSubject.value.stakeholder.address
        .countryId;
    } else {
      return null;
    }
  }
}
