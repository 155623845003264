import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import { ValueOutcome } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { Stakeholder } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StakeholderClient {
  constructor(private httpClient: HttpClient) {}

  getByEmail(email: string): Observable<ValueOutcome<Stakeholder>> {
    const url = `${Endpoints.stakeholder}/getByEmail?email=${encodeURIComponent(
      email
    )}`;
    return this.httpClient.get<ValueOutcome<Stakeholder>>(url);
  }

  getUserByEmail(email: string): Observable<ValueOutcome<Stakeholder>> {
    const url = `${
      Endpoints.stakeholder
    }/getUserByEmail?email=${encodeURIComponent(email)}`;
    return this.httpClient.get<ValueOutcome<Stakeholder>>(url);
  }
}
