import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { LocaleService } from '../../../shared/services/locale.service';

@Component({
  selector: 'roctavian-eumea-portal-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss']
})
export class ContactUsPageComponent implements OnInit {

  constructor(
    private translate: AppTranslationService,
    private localeService: LocaleService
  ) { }

  ngOnInit() {
  }

  get showContactNumbers(): boolean {
    if (!this.localeService.ipBasedCountry) {
      return false;
    }

    if (
      this.localeService.ipBasedCountry &&
      (this.localeService.ipBasedCountry.name.toLowerCase() == 'br' ||
      this.translate.getTranslation('ContactUsPage.RegionalPhone') == '')
    ) {
      return false;
    }

    return true;
  }

}
