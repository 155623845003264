import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { LaboratoryUserClient } from '../../clients/laboratory-user.client';
import { LabUserLab } from '../../models/lab-user.model';

@Component({
  selector: 'roctavian-eumea-portal-remove-lab-user-dialog',
  templateUrl: './remove-lab-user-dialog.component.html',
  styleUrls: ['./remove-lab-user-dialog.component.scss'],
})
export class RemoveLabUserDialogComponent implements OnInit, OnDestroy {
  labUserLab: LabUserLab;
  @Output() labUserDisassociated = new EventEmitter();
  errorMessages: string[] = [];
  private subscriptions = new Array<Subscription>();
  isProcessing = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<RemoveLabUserDialogComponent>,
    private client: LaboratoryUserClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {
    this.labUserLab = data.labUserLab;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  removeAssociation() {
    this.errorMessages = [];
    this.isProcessing = true;

    this.subscriptions.push(
      this.client
        .disassociateLabUser(this.labUserLab.labId, this.labUserLab.labUserId)
        .subscribe(
          outcome => {
            this.isProcessing = false;
            if (outcome.success) {
              this.messageService.open(
                this.translate.getTranslation('Common.SuccessfullyRemoved'),
                3000
              );
              this.labUserDisassociated.emit();
              this.reference.close();
              return;
            }

            this.errorMessages = outcome.messages;
          },
          (error: Outcome) => {
            this.isProcessing = false;
            this.messageService.open(
              this.translate.getTranslation('Common.ErrorProcessingRequest'),
              5000
            );
          }
        )
    );
  }
}
