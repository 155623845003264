import { User } from '@roctavian-abstractions/identity';
import { AttestationTypes } from '../enums/attestation-types.enum';
import { Auditable } from '../interfaces/auditable.interface';

export class Attestation implements Auditable {
  id: string;
  isCurrent: boolean;
  hasSignature: boolean;
  signatureDate: Date | string;
  guardianName: string;
  hasConsent: boolean;
  changeReasonDescription: string;
  attestationType: AttestationTypes;
  stakeholderId: string;
  addUser: User;
  addDate: Date;
  editUser: User;
  editDate: Date;
}
