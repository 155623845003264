import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { PatientService } from '../../service/PatientService';

@Component({
  selector: 'roctavian-eumea-portal-register-patient-complete',
  templateUrl: './register-patient-complete.component.html',
  styleUrls: ['./register-patient-complete.component.scss'],
})
export class RegisterPatientCompleteComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private translate: AppTranslationService,
    private patientService: PatientService
  ) {}

  ngOnInit() {}

  continue() {
    this.patientService.patientConsentFormUploaded.next(false);
    if (this.authService.claimsPrincipal.hasRole('LaboratoryUser')) {
      this.router.navigate(['laboratory-user', 'orders', 'list']);
    } else if (this.authService.claimsPrincipal.hasRole('PrescriberDelegate')) {
      this.router.navigate(['/prescriber-delegate', 'my-patients']);
    } else {
      this.router.navigate(['/prescriber', 'my-patients']);
    }
  }

  get continueButtonTest(): string {
    if (this.authService.claimsPrincipal.hasRole('LaboratoryUser')) {
      return this.translate.getTranslation('LabUser.MyLabOrders');
    } else {
      return this.translate.getTranslation('Patient.PatientListing');
    }
  }
}
