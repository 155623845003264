import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatDate'
})
export class formatDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService){
  }

  transform(value: any): string {
    return Intl.DateTimeFormat(this.translateService.currentLang, {}).format(new Date(value));
  }

}
