import { Injectable, OnDestroy } from '@angular/core';
import {
  AppTranslationService,
  MessageService,
} from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PatientPrescriber } from '../../patient/models/patient-prescriber.model';
import { PagedQuery } from '../../shared';
import { PrescriberDelegateClient } from '../clients/prescriber-delegate.client';
import { PrescriberDelegate } from '../models/prescriber-delegate.model';

@Injectable({
  providedIn: 'root',
})
export class PrescriberDelegateService implements OnDestroy {
  loggedInPrescriberDelegateSubject = new BehaviorSubject<PrescriberDelegate>(
    null
  );
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  patientPrescriberDelegatePagedListSubject = new BehaviorSubject<
    PaginatedList<PatientPrescriber>
  >(new PaginatedList<PatientPrescriber>());

  private subscriptions = new Array<Subscription>();

  constructor(
    private client: PrescriberDelegateClient,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  loadAuthenticatedPrescriberDelegate(forceRefresh: boolean = false) {
    if (
      !this.authenticationService.claimsPrincipal.hasRole('PrescriberDelegate')
    ) {
      this.loggedInPrescriberDelegateSubject.next(null);
      return;
    }

    if (
      !forceRefresh &&
      this.loggedInPrescriberDelegateSubject.value &&
      this.authenticationService.userInfo.email.toLowerCase() ==
        this.loggedInPrescriberDelegateSubject.value.stakeholder.email.toLowerCase()
    ) {
      return;
    }

    this.subscriptions.push(
      this.client
        .getPrescriberDelegateByEmail(this.authenticationService.userInfo.email)
        .subscribe(
          outcome => {
            if (!outcome.success) {
              this.loggedInPrescriberDelegateSubject.next(null);
              return;
            }

            this.loggedInPrescriberDelegateSubject.next(outcome.value);
          },
          error => {
            this.loggedInPrescriberDelegateSubject.next(null);
            this.handleError(error);
          }
        )
    );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
    this.isLoadingSubject.next(false);
  }

  setPatientPrescribersPageByPrescriberDelegateId(
    pagedQuery: PagedQuery,
    prescriberDelegateId: string
  ) {
    if (!prescriberDelegateId) {
      this.patientPrescriberDelegatePagedListSubject.next(
        new PaginatedList<PatientPrescriber>()
      );
      return;
    }

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.client
        .getPatientPrescriberDelegateListPaged(pagedQuery, prescriberDelegateId)
        .subscribe(
          outcome => {
            if (!outcome.success || outcome.failure) {
              this.patientPrescriberDelegatePagedListSubject.next(
                new PaginatedList<PatientPrescriber>()
              );
              this.handleError(outcome);
              return;
            }
            this.patientPrescriberDelegatePagedListSubject.next(outcome.value);
            this.isLoadingSubject.next(false);
          },
          (error: Outcome) => {
            this.handleError(error);
          }
        )
    );
  }

  get patientPrescriberDelegatePagedList(): PaginatedList<PatientPrescriber> {
    return this.patientPrescriberDelegatePagedListSubject.value;
  }

  clearService() {
    this.loggedInPrescriberDelegateSubject.next(null);
    this.patientPrescriberDelegatePagedListSubject.next(
      new PaginatedList<PatientPrescriber>()
    );
  }
}
