import { Lab } from '../../lab/models/lab.model';
import { Patient } from '../../patient/models/patient.model';
import { Prescriber } from '../../prescriber/models/prescriber.model';
import { Auditable } from '../../shared/models/auditable.model';
import { Stakeholder } from '../../shared/models/stakeholder.model';

export class LabTest extends Auditable {
  id: string;

  prescriberId: string;

  prescriber: Prescriber;

  labId: string;

  lab: Lab;

  appointmentDateTime: string;

  patientId: string;

  patient: Patient;

  requiresSampleShippingKit: boolean;

  labTestComplete: boolean;

  trackingNumber: string;

  numberOfSpecimensSubmitted: number;

  specimenCollectionDateTime: Date;

  specimenTransportPickupDateTime: Date;

  specimenTransportDispatchDateTime: Date;

  estimatedSpecimenArrivalDateTime: Date;

  actualSpecimenArrivalDateTime: Date;

  stakeholder: Stakeholder;

  arupAccession: string;

  arupPatientid: string;

  arupOrderDate: Date;

  arupReceivedDateTime: Date;

  resultVerificationDate: Date;

  testResult: string;

  cdcStatement: boolean;


}
