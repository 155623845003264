import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { PrescriberClient } from '../../../prescriber/clients/prescriber.client';
import { PrescriberService } from '../../../prescriber/services/prescriber.service';
import { Address } from '../../../shared';
import { PrescriberDelegateClient } from '../../clients/prescriber-delegate.client';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';
import { PrescriberDelegateFormComponent } from '../prescriber-delegate-form/prescriber-delegate-form.component';

@Component({
  selector: 'add-prescriber-delegate',
  templateUrl: './add-prescriber-delegate.component.html',
  styleUrls: ['./add-prescriber-delegate.component.scss'],
})
export class AddPrescriberDelegateComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('prescriberDelegateForm', { static: false })
  prescriberDelegateForm: PrescriberDelegateFormComponent;
  errorMessages: string[];
  isProcessing = false;

  private subscriptions = new Array<Subscription>();
  @Output() onPrescriberDelegateSaved = new EventEmitter();
  @Output() onCancelled = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private client: PrescriberDelegateClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private prescriberClient: PrescriberClient,
    private prescriberService: PrescriberService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.prescriberDelegateForm.initializeDuplicateEmailCheck();
  }

  submit() {
    const prescriber = this.prescriberService.loggedInPrescriberSubject.value;
    this.errorMessages = [];

    this.prescriberDelegateForm.form.markAllAsTouched();
    if (this.prescriberDelegateForm.form.invalid || !prescriber) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(
      this.client.createPrescriberDelegate(this.getModel()).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.subscriptions.push(
            this.prescriberClient
              .createPrescriberDelegateAssociation(prescriber.id, outcome.value)
              .subscribe(
                associationOutcome => {
                  if (!associationOutcome.success) {
                    this.handleError(associationOutcome);
                    return;
                  }

                  this.messageService.open(
                    this.translate.getTranslation('PrescriberDelegate.SuccessfullySaved'),
                    5000
                  );

                  this.onPrescriberDelegateSaved.emit();
                },
                error => {
                  this.handleError(error);
                }
              )
          );
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    this.isProcessing = false;
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [
        this.translate.getTranslation('Common.ErrorProcessingRequest'),
      ];
    } else {
      this.errorMessages = error.messages;
    }
  }

  cancel() {
    this.onCancelled.emit();
  }

  private getModel(): PrescriberDelegate {
    const prescriber = this.prescriberService.loggedInPrescriberSubject.value;

    const model = this.prescriberDelegateForm.getModel();
    model.stakeholder.address = new Address();
    model.stakeholder.address.countryId =
      prescriber.institution.stakeholder.address.countryId;
    return model;
  }
}
