import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Address } from '../../../shared';
import { UserSecurityInformation } from '../../../shared/models/user-security-information.model';

@Component({
  selector: 'prescriber-registration-step-two',
  templateUrl: './registration-step-two.component.html',
  styleUrls: ['./registration-step-two.component.scss'],
})
export class RegistrationStepTwoComponent {
  @Output() onFormCompleted: EventEmitter<UserSecurityInformation> = new EventEmitter<
    UserSecurityInformation
  >();
  @Input() stakeholderAddress: Address;

  formCompleted(userSecurityInformation: UserSecurityInformation) {
    this.onFormCompleted.emit(userSecurityInformation);
  }
}
