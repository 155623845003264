import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import {
  Outcome,
  PaginatedList,
  ValueOutcome
} from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { Lab } from '../../lab/models/lab.model';
import { LabTest } from '../../laboratory-user/models/lab-test.model';
import { Patient } from '../../patient/models/patient.model';
import { PagedQuery } from '../../shared';
import { UtilityService } from '../../shared/services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class LabTestClient {
  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) {}

  getLabTestListPaged(
    pagedQuery: PagedQuery
  ): Observable<ValueOutcome<PaginatedList<LabTest>>> {
    const url = `${Endpoints.labTest}/list`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabTest>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createLabTest(labTest: LabTest): Observable<Outcome> {
    return this.httpClient.post<Outcome>(Endpoints.labTest, labTest);
  }

  updateLabTest(labTest: LabTest): Observable<Outcome> {
    const url = `${Endpoints.labTest}/${labTest.id}`;
    return this.httpClient.put<Outcome>(url, labTest);
  }

  getLabTest(labTestId: string): Observable<ValueOutcome<LabTest>> {
    const url = `${Endpoints.labTest}/${labTestId}`;
    return this.httpClient.get<ValueOutcome<LabTest>>(url);
  }

  getPatientListPaged(
    pagedQuery: PagedQuery
  ): Observable<ValueOutcome<PaginatedList<Patient>>> {
    const url = `${Endpoints.labTest}/patient`;
    return this.httpClient.get<ValueOutcome<PaginatedList<Patient>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getLabListPaged(
    pagedQuery: PagedQuery
  ): Observable<ValueOutcome<PaginatedList<Lab>>> {
    const url = `${Endpoints.labTest}/lab`;
    return this.httpClient.get<ValueOutcome<PaginatedList<Lab>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getExportFile() {
    const url = `${Endpoints.labTest}/export`;
    return this.httpClient.get(url, { responseType: 'blob' });
  }
}
