import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../../../shared/services/utility.service';
import { Patient } from '../../models/patient.model';

@Component({
  selector: 'patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss'],
})
export class PatientDetailComponent implements OnInit {
  @Input() patient: Patient;
  prescriberName: string;

  constructor(public utilityService: UtilityService) {}

  ngOnInit() {
    this.prescriberName = `${
      this.patient.patientsPrescribers[0].prescriber.stakeholder.firstName
    } ${this.patient.patientsPrescribers[0].prescriber.stakeholder.lastName}`;
  }
}
