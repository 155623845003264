import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import { Document } from '@app/core/models';
import { AppTranslationService } from '@roctavian-abstractions/core';
import {
  Outcome,
  PaginatedList,
  ValueOutcome,
} from '@roctavian-abstractions/web';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { LabTest } from '../../laboratory-user/models/lab-test.model';
import { PagedQuery } from '../../shared';
import { UtilityService } from '../../shared/services/utility.service';
import { PatientPagedQuery } from '../models/patient-paged-query.model';
import { Patient } from '../models/patient.model';

@Injectable({
  providedIn: 'root',
})
export class PatientClient {
  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService,
    private translate: AppTranslationService
  ) {}

  createPatient(patient: Patient): Observable<ValueOutcome<string>> {
    return this.httpClient.post<ValueOutcome<string>>(
      Endpoints.patient,
      patient
    );
  }

  updatePatient(patient: Patient): Observable<Outcome> {
    return this.httpClient.put<Outcome>(Endpoints.patient, patient);
  }

  getPatient(patientId: string): Observable<ValueOutcome<Patient>> {
    const url = `${Endpoints.patient}/${patientId}`;
    return this.httpClient.get<ValueOutcome<Patient>>(url);
  }

  getPagedList(
    pagedQuery: PatientPagedQuery
  ): Observable<ValueOutcome<PaginatedList<Patient>>> {
    const params = this.utilityService.toHttpParams(pagedQuery);
    const url = `${Endpoints.patient}`;
    return this.httpClient.get<ValueOutcome<PaginatedList<Patient>>>(url, {
      params,
    });
  }

  getLabTestResultForPatient(
    labTestId: string
  ): Observable<ValueOutcome<string>> {
    const url = `${Endpoints.patient}/${labTestId}/lab-test-result`;
    return this.httpClient.get<ValueOutcome<string>>(url);
  }

  getPatientLabTestsByPatientId(
    patientId: string,
    pagedQuery: PagedQuery
  ): Observable<ValueOutcome<PaginatedList<LabTest>>> {
    const params = this.utilityService.toHttpParams(pagedQuery);

    const url = `${Endpoints.patient}/${patientId}/lab-test`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabTest>>>(url, {
      params,
    });
  }

  addDocumentToPatient(
    patientId: string,
    document: Document
  ): Observable<Outcome> {
    const patient = new Patient();
    patient.id = patientId;

    return this.httpClient.post<Outcome>(`${Endpoints.documentPatient}`, {
      patient,
      document,
    });
  }

  downloadPatientConsentForm(patientId: string) {
    const url = `${
      Endpoints.patient
    }/${patientId}/downloadConsentForm?locale=${this.translate.getCurrentLanguage()}`;

    return this.httpClient
      .get(url, { responseType: 'blob' })
      .subscribe(blob => {
        saveAs(
          blob,
          this.translate.getTranslation('Patient.PatientConsentForm'),
          {
            type: 'application/pdf',
          }
        );
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   // window.navigator.msSaveOrOpenBlob(
        //   //   blob,
        //   //   this.translate.getTranslation('Patient.PatientConsentForm')
        //   // );
        // }
      });
  }
}
