import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';

@Component({
  selector: 'roctavian-eumea-portal-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class ResetPasswordPageComponent implements OnInit {
  username: string;
  token: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: MessageService,
    private translate: AppTranslationService
  ) {}

  ngOnInit() {
    // Grab the reset token and username which should be provided in the url:
    this.route.queryParams.subscribe(params => (this.username = params['username']));
    this.route.queryParams.subscribe(params => (this.token = params['token']));
  }

  handleSuccessfulReset() {
    this.service.open(
      this.translate.getTranslation('PasswordReset.PasswordResetSuccessfully'),
      5000
    );
    this.router.navigate(['/']);
  }
}
