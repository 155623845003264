import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { ContentTypes } from '@app/core/enums';
import { Document, DownloadDocument } from '@app/core/models';
import { DocumentStorageService } from '@app/core/services';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDownloadComponent implements OnDestroy {
  private readonly destroyed$ = new Subject();
  private _disabled = false;
  private _placeholder = '';

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = coerceBooleanProperty(value);
      this.stateChanged();
    }
  }

  @Input() document: Document;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    if (this._placeholder !== value) {
      this._placeholder = value;
      this.stateChanged();
    }
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly documentStorageService: DocumentStorageService
  ) {}

  ngOnDestroy() {
    this.destroyed$.unsubscribe();
  }

  /**
   * Download the document.
   *
   */
  downloadFile() {
    const { documentPath, documentType } = this.document;
    const document: DownloadDocument = {
      contentType: ContentTypes.pdf,
      fileName: documentPath,
      documentType: documentType,
    };

    this.documentStorageService
      .download(document)
      .pipe(
        takeUntil(this.destroyed$),
        tap(url => window.open(url, '_blank'))
      )
      .subscribe();
  }

  private stateChanged() {
    this.changeDetectorRef.markForCheck();
  }
}
