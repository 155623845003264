import { StakeholderTypes, StatusTypes } from '../enums';

export class StakeholderStatusType {
  constructor(public stakeholderType: StakeholderTypes, public statusType: StatusTypes) {}
}

export const StakeholderStatusTypes: StakeholderStatusType[] = [
  new StakeholderStatusType(StakeholderTypes.Prescriber, StatusTypes.Inactive),
  new StakeholderStatusType(StakeholderTypes.Prescriber, StatusTypes.Incomplete),

  new StakeholderStatusType(StakeholderTypes.Patient, StatusTypes.Inactive),
  new StakeholderStatusType(StakeholderTypes.Patient, StatusTypes.Incomplete),

  new StakeholderStatusType(StakeholderTypes.Laboratory, StatusTypes.Inactive),
  new StakeholderStatusType(StakeholderTypes.Laboratory, StatusTypes.Incomplete),

  new StakeholderStatusType(StakeholderTypes.LabTest, StatusTypes.LabTestCancelled),
  new StakeholderStatusType(StakeholderTypes.LabTest, StatusTypes.LabTestSampleShipped),
  new StakeholderStatusType(StakeholderTypes.LabTest, StatusTypes.LabTestSampleReceived),
  new StakeholderStatusType(StakeholderTypes.LabTest, StatusTypes.LabTestResultReceived),
  new StakeholderStatusType(StakeholderTypes.LabTest, StatusTypes.LabTestOrdered),
];
