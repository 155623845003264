import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { Outcome } from '@roctavian-abstractions/web';
import { forkJoin, Subscription } from 'rxjs';
import { PrescriberClient } from '../../../prescriber/clients/prescriber.client';
import { Prescriber } from '../../../prescriber/models/prescriber.model';
import { StakeholderClient } from '../../clients/stakeholder.client';
import { UserSecurityClient } from '../../clients/user-security.client';
import { StakeholderTypes } from '../../enums';
import { Address, Stakeholder } from '../../models';
import { UserSecurityInformation } from '../../models/user-security-information.model';

@Component({
  selector: 'roctavian-eumea-portal-complete-security-setup-page',
  templateUrl: './complete-security-setup-page.component.html',
  styleUrls: ['./complete-security-setup-page.component.scss'],
})
export class CompleteSecuritySetupPageComponent implements OnInit, OnDestroy {
  username: string;
  token: string;
  isTokenVerified = false;
  isTokenValid = false;
  stakeholder: Stakeholder;
  prescriber: Prescriber;

  private subscriptions = new Array<Subscription>();

  constructor(
    private route: ActivatedRoute,
    private userSecurityClient: UserSecurityClient,
    private messageService: MessageService,
    private router: Router,
    private translate: AppTranslationService,
    private authService: AuthenticationService,
    private stakeholderClient: StakeholderClient,
    private prescriberClient: PrescriberClient
  ) {}

  ngOnInit() {
    if (this.authService.authenticated) {
      this.authService.logout();
    }

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.username = params['username'];
        this.token = params['token'];

        forkJoin({
          getStakeholderOutcome: this.stakeholderClient.getByEmail(this.username),
          verifyTokenOutcome: this.userSecurityClient.verifyToken(
            this.username,
            this.token
          ),
        }).subscribe(
          results => {
            this.isTokenVerified = true;
            this.isTokenValid = results.verifyTokenOutcome.value;
            this.stakeholder = results.getStakeholderOutcome.value;

            if (this.stakeholder.stakeholderType == StakeholderTypes.Prescriber) {
              this.subscriptions.push(
                this.prescriberClient
                  .getPrescriberByEmail(this.username)
                  .subscribe(getPrescriberOutcome => {
                    if (getPrescriberOutcome.success) {
                      this.prescriber = getPrescriberOutcome.value;
                    }
                  })
              );
            }
          },
          error => {
            this.isTokenVerified = true;
            this.isTokenValid = false;
          }
        );
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  formCompleted(userSecurityInformation: UserSecurityInformation) {
    userSecurityInformation.email = this.username;
    userSecurityInformation.token = this.token;

    this.subscriptions.push(
      this.userSecurityClient.completeUserSecurity(userSecurityInformation).subscribe(
        outcome => {
          if (!outcome.success) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.subscriptions.push(
            this.authService
              .login(
                userSecurityInformation.email,
                userSecurityInformation.securitySetup.password,
                null
              )
              .subscribe(result => {
                if (this.authService.claimsPrincipal.hasRole('Prescriber')) {
                  this.router.navigate(['/prescriber']);
                } else if (
                  this.authService.claimsPrincipal.hasRole('PrescriberDelegate')
                ) {
                  this.router.navigate(['/prescriber-delegate']);
                } else if (this.authService.claimsPrincipal.hasRole('LaboratoryUser')) {
                  this.router.navigate(['/laboratory-user']);
                } else {
                  this.router.navigate(['/']);
                }
              })
          );
        },
        (error: Outcome) => {
          this.messageService.open(
            this.translate.getTranslation('Common.ErrorProcessingRequest'),
            5000
          );
          return;
        }
      )
    );
  }

  get stakeholderAddress(): Address {
    if (
      !this.stakeholder ||
      (this.stakeholder.stakeholderType == StakeholderTypes.Prescriber &&
        !this.prescriber)
    ) {
      return null;
    }

    switch (this.stakeholder.stakeholderType) {
      case StakeholderTypes.Prescriber:
        return this.prescriber.institution.stakeholder.address;
      default:
        return this.stakeholder.address;
    }
  }
}
