import { AttestationTypes, StakeholderTypes } from '../enums';

export class StakeholderAttestationType {
  constructor(
    public stakeholderType: StakeholderTypes,
    public attestationType: AttestationTypes,
    public name: string
  ) {}
}

export const StakeholderAttestationTypes: StakeholderAttestationType[] = [
  new StakeholderAttestationType(
    StakeholderTypes.Prescriber,
    AttestationTypes.PrescriberRegistration,
    'Prescriber Registration'
  ),
  new StakeholderAttestationType(
    StakeholderTypes.Patient,
    AttestationTypes.PatientRegistration,
    'Patient Registration'
  ),
  new StakeholderAttestationType(
    StakeholderTypes.PrescriberDelegate,
    AttestationTypes.PrescriberDelegateRegistration,
    'Prescriber Delegate Registration'
  ),
  new StakeholderAttestationType(
    StakeholderTypes.LaboratoryUser,
    AttestationTypes.LabUserRegistration,
    'Laboratory User Registration'
  ),
];
