import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import { Outcome, ValueOutcome } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { UserSecurityInformation } from '../models/user-security-information.model';

@Injectable({
  providedIn: 'root',
})
export class UserSecurityClient {
  constructor(private httpClient: HttpClient) {}

  completeUserSecurity(
    userSecurityInformation: UserSecurityInformation
  ): Observable<Outcome> {
    return this.httpClient.post<Outcome>(
      Endpoints.userSecurity,
      userSecurityInformation
    );
  }

  verifyToken(email: string, token: string): Observable<ValueOutcome<boolean>> {
    const url = `${Endpoints.userSecurity}/verifyToken`;
    return this.httpClient.post<ValueOutcome<boolean>>(url, { email, token });
  }
}
