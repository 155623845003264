import { LabTest } from '../../laboratory-user/models/lab-test.model';
import { Prescriber } from '../../prescriber/models/prescriber.model';
import { Auditable } from '../../shared';
import { Stakeholder } from '../../shared/models/stakeholder.model';
import { Status } from '../../shared/models/status.model';
import { GenderTypes } from '../enums/gender-types.enum';
import { PatientPrescriber } from './patient-prescriber.model';

export class Patient extends Auditable {
  id: string;
  stakeholder: Stakeholder;
  patientsPrescribers: PatientPrescriber[];
  birthDate: string;
  gender: GenderTypes;
  status: Status;
  prescriber: Prescriber;
  currentLabTest: LabTest;
  drugInclusion: boolean;
  isPCFUploaded: boolean;

  changeReasonDescription: string;
}
