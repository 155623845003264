import { Lab } from '../../lab/models/lab.model';
import { Auditable, Stakeholder } from '../../shared';
import { LabUserDelegate } from './lab-user-delegate.model';

export class LabUser {
  id: string;
  stakeholder: Stakeholder;

  labUserLabs: LabUserLab[];

  labUserDelegates: LabUserDelegate[];

  isAuthorizedRepresentative?: boolean;
  localeIdentifier: string;
}

export class LabUserLab extends Auditable {
  id: string;

  labUserId: string;

  labUser: LabUser;

  labId: string;

  lab: Lab;

  isAuthorizedRepresentative: boolean;
}
