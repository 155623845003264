import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MyPatientsPageComponent } from './pages/my-patients-page/my-patients-page.component';
import { ManageOfficeContactsPageComponent } from './pages/manage-office-contacts-page/manage-office-contacts-page.component';
import { ManageLabDelegatesPageComponent } from './pages/manage-lab-delegates-page/manage-lab-delegates-page.component';
import { PrescriberGuard } from './guards/prescriber.guard';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { AuthenticationGuard } from '@roctavian-abstractions/identity';
import { UserAttestationCompleteGuard } from '../shared/guards/user-attestation-complete.guard';
import { PatientListComponent } from './components/patient-list/patient-list.component';

import { CompleteLabOrderPageComponent } from './pages/complete-lab-order-page/complete-lab-order-page.component';
import { OrdersPageComponent } from './pages/orders-page/orders-page.component';
import { EditLabTestPageComponent } from './pages/order-edit-page/order-edit-page.component';


const ROUTES = [
    {
        path: 'prescriber',
        children: [
            { path: '', redirectTo: 'my-patients', pathMatch: 'full' },
            { path: 'my-patients', component: MyPatientsPageComponent, canActivate: [AuthenticationGuard, PrescriberGuard, UserAttestationCompleteGuard] },
            { path: 'manage-office-contacts', component: ManageOfficeContactsPageComponent, canActivate: [AuthenticationGuard, PrescriberGuard, UserAttestationCompleteGuard] },
            { path: 'manage-lab-delegates', component: ManageLabDelegatesPageComponent, canActivate: [AuthenticationGuard, PrescriberGuard, UserAttestationCompleteGuard] },
            { path: 'register', component: RegisterPageComponent },
            {
                path: 'orders',
                canActivate: [AuthenticationGuard],
                children: [
                  {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                  },
                  {
                    path: 'list',
                    component: OrdersPageComponent
                  },
                  {
                    path: ':id',
                    children: [
                      { path: 'edit', component: EditLabTestPageComponent },
                      { path: 'complete', component: CompleteLabOrderPageComponent }
                    ]
                  }
                ]
              }
        ]
    }
];

@NgModule({
    imports: [CommonModule, RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class PrescriberRoutingModule { }
