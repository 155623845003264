import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AppTranslationService,
  ConfigService,
  MessageService,
} from '@roctavian-abstractions/core';
import {
  AccountClient,
  ForgotPasswordRequestModel,
  IdentityConfigClient,
} from '@roctavian-abstractions/identity';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roctavian-eumea-portal-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnChanges, OnDestroy {
  disabled = false;
  form: FormGroup;

  @Input() enableRecaptcha = false;
  @Input() recaptchaKey: string;

  @Output() reset = new EventEmitter();
  private subscriptions = new Array<Subscription>();

  constructor(
    private service: MessageService,
    private client: AccountClient,
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private recaptchaService: ReCaptchaV3Service,
    private configClient: IdentityConfigClient,
    public translate: AppTranslationService
  ) {
    this.recaptchaKey = this.configService.get('recaptchaKey');
  }

  ngOnInit() {
    this.subscriptions.push(
      this.configClient.getIdentityConfig().subscribe(config => {
        this.enableRecaptcha = config.enableRecaptcha;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(): void {
    this.form = this.formBuilder.group({
      username: this.formBuilder.control('', Validators.required),
    });
  }

  handleForgotPasswordSubmission() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.disabled = true;

    if (this.enableRecaptcha) {
      this.recaptchaService.execute(this.recaptchaKey, 'homepage', token => {
        this.callForgotPasswordApi(token);
      });
    } else {
      this.callForgotPasswordApi();
    }
  }

  callForgotPasswordApi(token?: string) {
    const model = new ForgotPasswordRequestModel();
    model.username = this.form.get('username').value;
    model.recaptchaToken = token;
    model.localeIdentifier = this.translate.getCurrentLanguage();

    this.subscriptions.push(
      this.client.forgotPassword(model).subscribe(
        () => {
          this.disabled = false;
          this.reset.emit();
        },
        () => {
          this.disabled = false;
          const message = this.translate.getTranslation('Common.ErrorProcessingRequest');
          this.service.open(message, 5000);
        }
      )
    );
  }
}
