import { Prescriber } from '../../prescriber/models/prescriber.model';
import { Auditable } from '../../shared/models/auditable.model';
import { Patient } from './patient.model';

export class PatientPrescriber extends Auditable {
  patientId: number;
  patient: Patient;
  prescriberId: string;
  prescriber: Prescriber;
  isCurrent: boolean;
}
