import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@roctavian-abstractions/identity';

@Injectable({
  providedIn: 'root'
})
export class PrescriberGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authenticationService.claimsPrincipal.hasRole('Prescriber');
  }

}
