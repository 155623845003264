import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import { Outcome } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { LabKitOrder } from '../../shared/models/lab-kit-order.model';

@Injectable({
  providedIn: 'root',
})
export class OrderSampleShippingKitClient {
  constructor(private httpClient: HttpClient) {}

  createLabKitOrder(labKitOrder: LabKitOrder): Observable<Outcome> {
    return this.httpClient.post<Outcome>(Endpoints.labKitOrder, labKitOrder);
  }
}
