import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationEventHandler,
  TwoFactorVerificationRequiredEvent,
} from '@roctavian-abstractions/identity';

@Component({
  selector: 'public-insecure-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
})
export class LoginRegisterComponent {
  constructor(private router: Router, private handler: AuthenticationEventHandler) {}

  /**
   * Handles a successful login attempt event.
   */
  handleLoginSuccess() {
    this.handler.handleSuccessfulLogin();
  }

  /**
   * Handles the initial account lock event.
   */
  handleInitialAccountLock() {
    this.handler.handleInitialLockout();
  }

  /**
   * Handles the account locked event. This
   * event will be fired when the user's login
   * attempt results in a locked event, after
   * the initial lock event.
   */
  handleLockedAccount() {
    this.handler.handleAccountLocked();
  }

  /**
   * Handles the password expired event.
   */
  handleExpiredPassword() {
    this.handler.handleExpiredPassword();
  }

  /**
   * Handles the email not confirmed event.
   */
  handleEmailNotConfirmed() {
    this.handler.handleEmailNotConfirmed();
  }

  /**
   * Handles the two-factor verification required event.
   */
  handleTwoFactorRequired(event: TwoFactorVerificationRequiredEvent) {
    this.router.navigateByUrl('/two-factor/verify', {
      state: { username: event.username, password: event.password },
    });
  }
}
