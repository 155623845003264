import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { LaboratoryUserClient } from '../../../laboratory-user/clients/laboratory-user.client';
import { LabTest } from '../../../laboratory-user/models/lab-test.model';
import { LaboratoryUserService } from '../../../laboratory-user/service/laboratory-user.service';
import { StatusTypes } from '../../../shared/enums';
import { UtilityService } from '../../../shared/services/utility.service';
import { CancelLabTestEditDialogComponent } from '../../components/cancel-lab-test-edit-dialog/cancel-lab-test-edit-dialog.component';
import { LabOrderEditComponent } from '../../components/lab-order-edit/lab-order-edit.component';

@Component({
  selector: 'roctavian-eumea-portal-prescriber-delegate-edit-lab-test-page',
  templateUrl: './order-edit-page.component.html',
  styleUrls: ['./order-edit-page.component.scss'],
})
export class EditLabTestPageComponent implements OnInit, OnDestroy {
  @ViewChild(LabOrderEditComponent, { static: false }) labTestForm: LabOrderEditComponent;

  form: FormGroup;
  labTest: LabTest;
  isProcessing = false;
  errorMessages = [];
  statusTypes = StatusTypes;
  pageHeader = '';

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private labUserService: LaboratoryUserService,
    private labUserClient: LaboratoryUserClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    private router: Router,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    this.subscriptions.push(
      this.labUserService.selectedLabTestSubject.subscribe(
        labTest => (this.labTest = labTest)
      )
    );

    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        const id = String(params.get('id'));
        this.labUserService.loadLabTest(id);
      })
    );
  }

  submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.errorMessages = [];
    const updatedLabTest = this.labTestForm.getModel();

    this.subscriptions.push(
      this.labUserClient.updateLabTest(updatedLabTest).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.messageService.open(
            this.translate.getTranslation('LabTest.LabTestSuccessfullyUpdated'),
            5000
          );
          this.labUserService.loadLabTest(this.labTest.id, true);
          this.router.navigate(['/laboratory-user/orders', this.labTest.id, 'complete']);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [
        this.translate.getTranslation('Common.ErrorProcessingRequest'),
      ];
    } else {
      this.errorMessages = error.messages;
    }
  }

  cancel() {
    const dialogRef = this.matDialog.open(CancelLabTestEditDialogComponent, {
      data: {},
      width: '1000px',
    });

    this.subscriptions.push(
      dialogRef.componentInstance.onYesClicked.subscribe(() => {
        dialogRef.close();
        this.router.navigate(['/laboratory-user', 'orders']);
      })
    );
  }
}
