import { DocumentTypeRecord } from '@app/core/types';

export const documentTypeNames: DocumentTypeRecord = {
  labTestOrderForm: 'Lab Test Order Form',
  labTestRequisitionForm: 'Lab Test Requisition Form',
  labRegistrationForm: 'Lab Registration Form',
  labTestDocument: 'Lab Test Document',
  labTestResultPdf: 'Lab Test Result PDF',
  labDocument: 'Lab Document',
  patientConsentForm: 'Patient Consent Form',
  patientDocument: 'Patient Document',
  prescriberRegistrationForm: 'Prescriber Registration Form',
  prescriberDocument: 'Prescriber Document',
  patientRegistrationForm: 'Patient Registration Form',
};
