import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Document } from '@app/core/models';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { OrderPipe } from 'ngx-order-pipe';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { LabTest } from '../../../laboratory-user/models/lab-test.model';
import {
  Attestation,
  AttestationClient,
  FileUploadMessageService,
  PagedQuery,
} from '../../../shared';
import { AttestationTypes } from '../../../shared/enums/attestation-types.enum';
import { PatientClient } from '../../clients/patient.client';
import { Patient } from '../../models/patient.model';
import { PatientService } from '../../service/PatientService';

@Component({
  selector: 'roctavian-eumea-portal-patient-detail-page',
  templateUrl: './patient-detail-page.component.html',
  styleUrls: ['./patient-detail-page.component.scss'],
})
export class PatientDetailPageComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  labTestCollection: LabTest[];
  patient: Patient;
  isProcessing = false;
  isUploadingDocument = false;
  selectedFiles: FileList | null = null;
  isConsentCompleted = false;

  get mostRecentLabTest(): LabTest {
    if (!this.labTestCollection || this.labTestCollection.length === 0) {
      return null;
    }

    const sortedList = this.orderPipe.transform(this.labTestCollection, 'addDate', true);
    return sortedList[0];
  }

  get historyLabTests(): LabTest[] {
    if (!this.labTestCollection || this.labTestCollection.length === 0) {
      return [];
    }

    const sortedList = this.orderPipe.transform(this.labTestCollection, 'addDate', true);

    return sortedList.slice(1, sortedList.length);
  }

  get areAllLabTestCompleted(): boolean {
    if (!this.labTestCollection || this.labTestCollection.length === 0) {
      return true;
    }

    const areAllLabTestsCompleted =
      this.labTestCollection.filter(
        l => !l.resultVerificationDate || !l.testResult || !l.labTestComplete
      ).length < 1;
    return areAllLabTestsCompleted;
  }

  constructor(
    private attestationClient: AttestationClient,
    private readonly fileUploadMessageService: FileUploadMessageService,
    private messageService: MessageService,
    private orderPipe: OrderPipe,
    private patientClient: PatientClient,
    private route: ActivatedRoute,
    private translate: AppTranslationService,
    private patientService: PatientService
  ) {}

  ngOnInit() {
    this.fileUploadMessageService.uploading$
      .pipe(
        takeUntil(this.destroy$),
        tap(isUploading => {
          this.isUploadingDocument = isUploading;
          this.isProcessing = isUploading;
        })
      )
      .subscribe();

    this.fileUploadMessageService.uploaded$
      .pipe(
        takeUntil(this.destroy$),
        tap(document => this.associateDocument(document))
      )
      .subscribe();

    this.route.paramMap
      .pipe(
        takeUntil(this.destroy$),
        tap(params => {
          const id = params.get('id');
          this.loadPatient(id);
          this.loadLabTests(id);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  filesSelected(files: FileList | null) {
    this.selectedFiles = files;
  }

  private loadPatient(id: string) {
    this.patientClient
      .getPatient(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }
          this.patient = outcome.value;
          if(this.patient.isPCFUploaded && this.patient.isPCFUploaded===true) {
            this.patientService.patientConsentFormUploaded.next(true)
          }
          else {
            this.patientService.patientConsentFormUploaded.next(false)
          }
          
        },
        error => {
          this.handleError(error);
        }
      );
  }

  private loadLabTests(id: string) {
    this.patientClient
      .getPatientLabTestsByPatientId(id, new PagedQuery(1, 999, null, 'addDate', true))
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }

          this.labTestCollection = outcome.value.items;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
  }

  downloadPatientConsentForm() {
    this.patientClient.downloadPatientConsentForm(this.patient.id);
  }

  private associateDocument(document: Document) {
    this.patientClient
      .addDocumentToPatient(this.route.snapshot.params.id, document)
      .subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }
          this.patient.isPCFUploaded = true;
          this.patientService.patientConsentFormUploaded.next(true);
          this.addPatientAttestation();
        },
        error => this.handleError(error)
      );
  }

  private addPatientAttestation() {
    const attestation = new Attestation();
    attestation.attestationType = AttestationTypes.PatientRegistration;
    attestation.guardianName = 'none';
    attestation.hasSignature = true;
    let utcDate = new Date();   
    
    attestation.signatureDate = utcDate.toISOString().replace(/T.*/, 'T00:00:00.000Z');
    attestation.isCurrent = true;
    attestation.hasConsent = true;
    attestation.stakeholderId = this.patient.stakeholder.id;
    attestation.changeReasonDescription = 'portal submission';

    this.attestationClient.saveAttestation(attestation).subscribe(
      outcome => {
        if (!outcome.success) {
          this.handleError(outcome);
          return;
        }
        this.updatePatient();
      },
      error => {
        this.handleError(error);
      }
    );
  }

  private updatePatient() {
    this.patientClient
      .updatePatient(this.patient)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        outcome => {
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }
          this.messageService.open(
            this.translate.getTranslation('Patient.DocumentUploadedSuccessfully'),
            5000
          );
          this.isConsentCompleted = true;
          this.isProcessing = false;
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      );
  }
}
