import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { StakeholderService } from '../services/stakeholder.service';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { StakeholderClient } from '../clients/stakeholder.client';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserAttestationCompleteGuard implements CanActivate {

  constructor(private stakeholderService: StakeholderService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private stakeholderClient: StakeholderClient) { }

  canActivate(): Observable<boolean> {

    if (!this.authenticationService.userInfo || !this.authenticationService.userInfo.email) {
      return of(false);
    }

    if (this.stakeholderService.currentStakeholder) {

      if (this.stakeholderService.currentStakeholder.attestations && this.stakeholderService.currentStakeholder.attestations.find(attestation => attestation.isCurrent && attestation.signatureDate && attestation.hasSignature)) {
        return of(true);
      }

      this.router.navigate(['/complete-user-attestation']);
      return of(false);
    }

    return this.stakeholderClient.getUserByEmail(this.authenticationService.userInfo.email).pipe(map(outcome => {

      if (!outcome.success) {
        return false;
      }

      const stakeholder = outcome.value;
      if (!stakeholder) {
        return false;
      }

      if (stakeholder.attestations && stakeholder.attestations.find(attestation => attestation.isCurrent && attestation.signatureDate && attestation.hasSignature)) {
        return true;
      }

      this.router.navigate(['/complete-user-attestation']);
      return false;

    }));

  }

}
