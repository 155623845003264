import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSort, PageEvent } from '@angular/material';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LaboratoryUserClient } from '../../../laboratory-user/clients/laboratory-user.client';
import { LabTest } from '../../../laboratory-user/models/lab-test.model';
import { PagedQuery } from '../../../shared';
import { UtilityService } from '../../../shared/services/utility.service';
import { PrescriberLab } from '../../models/prescriber-lab.model';
import { Prescriber } from '../../models/prescriber.model';
import { PrescriberService } from '../../services/prescriber.service';

@Component({
  selector: 'prescriber-lab-order-list',
  templateUrl: './lab-order-list.component.html',
  styleUrls: ['./lab-order-list.component.scss'],
})
export class LabOrderListComponent implements OnInit, OnDestroy {
  prescriber: Prescriber;
  pagedQuery = new PagedQuery(1, 10, null, 'id', true);
  prescriberOffice: PrescriberLab;

  filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  displayedColumns = [
    'patient.stakeholder.id',
    'patient.currentLabTest.id',
    'patient.stakeholder.firstName',
    'patient.stakeholder.lastName',
    'prescriber.stakeholder.firstName',
    'prescriber.stakeholder.lastName',
    'prescriber.id',
    'dateAdded',
    'stakeholder.statuses.statusType',
    'specimenCollectionDateTime',
    'menu',
  ];

  constructor(
    public utilityService: UtilityService,
    public prescriberService: PrescriberService,
    private labUserClient: LaboratoryUserClient
  ) {}

  private refreshList() {
    if (!this.prescriberOffice) {
      return;
    }
    this.prescriberService.setLabTestPage(this.pagedQuery, this.prescriberOffice.labId);
  }

  ngOnInit() {
    this.prescriberService.loggedInPrescriberSubject.next(null);
    this.prescriberService.loadAuthenticatedPrescriber();
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.prescriberService.loggedInPrescriberSubject.subscribe(prescriber => {
        this.prescriber = prescriber;
        if (this.prescriber != null) {
          prescriber.prescriberLabs.forEach(lab => {
            if (lab.isPrescriberOffice) {
              // get associated lab orders
              this.prescriberOffice = lab;
              this.refreshList();
            }
          });
        }
      })
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.labTestPagedListSubject.next(new PaginatedList<LabTest>());
  }

  onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'patient.stakeholder.id':
        this.pagedQuery.sortColumn = 'patient.stakeholder.externalId';
        break;
      case 'patient.currentLabTest.id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      case 'prescriber.id':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.externalId';
        break;
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn =
          'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn =
          'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  printLabTest(labTestId: string) {
    this.labUserClient.downloadShipmentLabel(labTestId);
  }
}
