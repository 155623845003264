import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ContentTypes, DocumentTypes } from '@app/core/enums';
import { DownloadDocument } from '@app/core/models';
import { DocumentStorageService } from '@app/core/services';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil, tap } from 'rxjs/operators';
import { LabClient } from '../../../lab/clients/lab.client';
import { LabTest } from '../../../laboratory-user/models/lab-test.model';
import { PatientClient } from '../../clients/patient.client';
import { ViewLabDetailsDialogComponent } from '../view-lab-details-dialog/view-lab-details-dialog.component';

@Component({
  selector: 'lab-test-detail',
  templateUrl: './lab-test-detail.component.html',
  styleUrls: ['./lab-test-detail.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabTestDetailComponent implements OnDestroy {
  @Input() isHistoryRecord = true;
  private subscriptions = new Array<Subscription>();
  private readonly unsubscribe$ = new Subject();
  private _labTest: LabTest;

  @Input()
  get labTest(): LabTest {
    return this._labTest;
  }
  set labTest(value: LabTest) {
    if (this._labTest !== value) {
      this._labTest = value;
      this.testResultState =
        !value || !value.testResult || !value.resultVerificationDate ? 'clear' : 'done';
      this._changeDetectorRef.markForCheck();
    }
  }

  testResultState: 'done' | 'clear' = 'clear';

  completeState = 'done';
  incompleteState = 'clear';

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private readonly documentStorageService: DocumentStorageService,
    private labClient: LabClient,
    private patientClient: PatientClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  get patientRegisterDate(): Date {
    if (!this.labTest || !this.labTest.patient) {
      return null;
    }

    return this.labTest.patient.addDate;
  }

  get patientConsentDate(): Date|string {
    if (
      !this.labTest ||
      !this.labTest.patient ||
      !this.labTest.patient.stakeholder ||
      !this.labTest.patient.stakeholder.attestations
    ) {
      return null;
    }

    const consent = this.labTest.patient.stakeholder.attestations.find(a => a.hasConsent);
    if (!consent) {
      return null;
    }

    return consent.signatureDate;
  }

  get patientInformationState(): string {
    if (!this.labTest || !this.labTest.patient) {
      return this.incompleteState;
    }

    return this.completeState;
  }

  get patientConsentState(): string {
    if (!this.patientConsentDate) {
      return this.incompleteState;
    }

    return this.completeState;
  }

  get labInformationState(): string {
    if (!this.labTest || !this.labTest.lab) {
      return this.incompleteState;
    }

    return this.completeState;
  }

  get sampleObtainedShippedStatus(): string {
    if (!this.labTest || !this.labTest.specimenTransportPickupDateTime) {
      return this.incompleteState;
    }

    return this.completeState;
  }

  get sampleReceivedState(): string {
    if (!this.labTest || !this.labTest.arupReceivedDateTime) {
      return this.incompleteState;
    }

    return this.completeState;
  }

  viewLabDetails() {
    this.subscriptions.push(
      this.labClient.getLab(this.labTest.labId).subscribe(
        outcome => {
          if (!outcome.success) {
            this.handleError(outcome);
            return;
          }

          const dialogRef = this.matDialog.open(ViewLabDetailsDialogComponent, {
            data: {
              lab: outcome.value,
            },
            width: '1000px',
          });
        },
        error => this.handleError(error)
      )
    );
  }

  viewLabTestResultsPdf(id: string) {
    this.labClient
      .getLabTestResultDocumentStorageUri(id)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(outcome => {
          const storageDocument: DownloadDocument = {
            contentType: ContentTypes.pdf,
            documentType: DocumentTypes.labTestResultPdf,
            fileName: outcome.value,
          };

          this.documentStorageService
            .download(storageDocument)
            .pipe(
              takeUntil(this.unsubscribe$),
              tap(url => window.open(url, '_blank'))
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  get canEnterLabDetails(): boolean {
    return (
      this.labTest.specimenCollectionDateTime === null ||
      !this.labTest ||
      !this.labTest.lab ||
      !this.labTest.appointmentDateTime
    );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
  }
}
