import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import { Outcome } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { Attestation } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AttestationClient {
  constructor(private httpClient: HttpClient) {}

  saveAttestation(attestation: Attestation): Observable<Outcome> {
    return this.httpClient.post<Outcome>(Endpoints.attestation, attestation);
  }
}
