import { PagedQuery } from '../../shared';

export class LabUserLabPagedQuery extends PagedQuery {
  constructor(
    public pageIndex: number,
    public pageSize: number,
    public filterText: string,
    public sortColumn: string,
    public isSortDesc: boolean,
    public excludeLabUserId: string
  ) {
    super(pageIndex, pageSize, filterText, sortColumn, isSortDesc);
  }
}
