import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(private configService: ConfigService) {}

  get recapthcaKey(): string {
    return this.configService.get('recaptchaKey');
  }

  get prescriberUrl(): string {
    return this.configService.getEndpointPath('prescriber');
  }

  get labKitOrderUrl(): string {
    return this.configService.getEndpointPath('lab-kit-order');
  }

  get addressUrl(): string {
    return this.configService.getEndpointPath('address');
  }

  get labUserUrl(): string {
    return this.configService.getEndpointPath('labuser');
  }

  get labTestUrl(): string {
    return this.configService.getEndpointPath('lab-test');
  }

  get labUrl(): string {
    return this.configService.getEndpointPath('lab');
  }

  get localeUrl(): string {
    return this.configService.getEndpointPath('locale');
  }

  get attestationUrl(): string {
    return this.configService.getEndpointPath('attestation');
  }

  get prescriberDelegateUrl(): string {
    return this.configService.getEndpointPath('prescriber-delegate');
  }

  get userSecurityUrl(): string {
    return this.configService.getEndpointPath('user-security');
  }

  get stakeholderUrl(): string {
    return this.configService.getEndpointPath('stakeholder');
  }

  get patientUrl(): string {
    return this.configService.getEndpointPath('patient');
  }

  get documentPatientUrl(): string {
    return this.configService.getEndpointPath('document-patient');
  }

  get documentLabTestUrl(): string {
    return this.configService.getEndpointPath('document-lab-test');
  }

  get documentUrl(): string {
    return this.configService.getEndpointPath('document');
  }
}
