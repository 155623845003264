import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';

@Component({
  selector: 'roctavian-eumea-portal-order-sample-shipping-kit-complete-page',
  templateUrl: './order-sample-shipping-kit-complete-page.component.html',
  styleUrls: ['./order-sample-shipping-kit-complete-page.component.scss'],
})
export class OrderSampleShippingKitCompletePageComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private translate: AppTranslationService
  ) {}

  ngOnInit() {}

  continue() {
    this.router.navigate(['/']);
  }
}
