import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { Attestation } from '../../../shared';
import { AttestationClient } from '../../../shared/clients/attestation.client';
import { AttestationTypes } from '../../../shared/enums';
import { Prescriber } from '../../models/prescriber.model';
import { PrescriberService } from '../../services/prescriber.service';

@Component({
  selector: 'roctavian-eumea-portal-complete-attestation-page',
  templateUrl: './complete-attestation-page.component.html',
  styleUrls: ['./complete-attestation-page.component.scss'],
})
export class CompleteAttestationPageComponent implements OnInit, OnDestroy {
  private subscriptions = new Array<Subscription>();
  private prescriber: Prescriber;

  constructor(
    private translate: AppTranslationService,
    private prescriberService: PrescriberService,
    private attestationClient: AttestationClient,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.prescriberService.loggedInPrescriberSubject.subscribe(prescriber => {
        this.prescriber = prescriber;
        this.router.navigate(['/prescriber']);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  submit() {
    const attestation = new Attestation();
    attestation.stakeholderId = this.prescriber.stakeholder.id;
    attestation.isCurrent = true;
    let utcDate = new Date();   
    
    attestation.signatureDate = utcDate.toISOString().replace(/T.*/, 'T00:00:00.000Z');
    attestation.hasSignature = true;
    attestation.attestationType = AttestationTypes.PrescriberRegistration;
    attestation.changeReasonDescription = this.translate.getTranslation(
      'Attestation.AttestationChangeReasonDescription'
    );

    this.subscriptions.push(
      this.attestationClient.saveAttestation(attestation).subscribe(
        outcome => {
          if (!outcome.success) {
            this.messageService.open(
              this.translate.getTranslation('Common.ErrorProcessingRequest'),
              3000
            );
            return;
          }

          this.prescriberService.loadAuthenticatedPrescriber(true);
        },
        error => {
          this.messageService.open(
            this.translate.getTranslation('Common.ErrorProcessingRequest'),
            5000
          );
        }
      )
    );
  }
}
