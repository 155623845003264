import { Injectable, OnDestroy } from '@angular/core';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { AuthenticationService } from '@roctavian-abstractions/identity';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LabClient } from '../../lab/clients/lab.client';
import { PatientPrescriber } from '../../patient/models/patient-prescriber.model';
import { PrescriberDelegateClient } from '../../prescriber-delegate/clients/prescriber-delegate.client';
import { PrescriberDelegatePrescriber } from '../../prescriber-delegate/models/prescriber-delegate.model';
import { PagedQuery } from '../../shared';
import { PrescriberClient } from '../clients/prescriber.client';
import { LabTest } from '../models/lab-test.model';
import { Prescriber } from '../models/prescriber.model';

@Injectable({
  providedIn: 'root',
})
export class PrescriberService implements OnDestroy {
  labTestPagedListSubject = new BehaviorSubject<PaginatedList<LabTest>>(
    new PaginatedList<LabTest>()
  );
  selectedLabTestSubject = new BehaviorSubject<LabTest>(null);
  loggedInPrescriberSubject = new BehaviorSubject<Prescriber>(null);
  prescriberDelegatePagedListSubject = new BehaviorSubject<
    PaginatedList<PrescriberDelegatePrescriber>
  >(new PaginatedList<PrescriberDelegatePrescriber>());
  prescriberPagedListSubject = new BehaviorSubject<PaginatedList<Prescriber>>(
    new PaginatedList<Prescriber>()
  );
  patientPrescriberPagedListSubject = new BehaviorSubject<
    PaginatedList<PatientPrescriber>
  >(new PaginatedList<PatientPrescriber>());
  isLoadingSubject = new BehaviorSubject<boolean>(false);

  private subscriptions = new Array<Subscription>();

  constructor(
    private client: PrescriberClient,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private prescriberDelegateClient: PrescriberDelegateClient,
    private labClient: LabClient
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  loadAuthenticatedPrescriber(forceRefresh: boolean = false) {
    if (!this.authenticationService.claimsPrincipal.hasRole('Prescriber')) {
      this.loggedInPrescriberSubject.next(null);
      return;
    }

    if (
      !forceRefresh &&
      this.loggedInPrescriberSubject.value &&
      this.authenticationService.userInfo.email.toLowerCase() ==
        this.loggedInPrescriberSubject.value.stakeholder.email.toLowerCase()
    ) {
      return;
    }

    this.subscriptions.push(
      this.client
        .getPrescriberByEmail(this.authenticationService.userInfo.email)
        .subscribe(
          outcome => {
            if (!outcome.success) {
              this.loggedInPrescriberSubject.next(null);
              return;
            }

            this.loggedInPrescriberSubject.next(outcome.value);
          },
          error => {
            this.loggedInPrescriberSubject.next(null);
            this.handleError(error);
          }
        )
    );
  }

  setLabTestPage(pagedQuery: PagedQuery, id: string) {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labClient.getLabTestsByLab(pagedQuery, id).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.labTestPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  setLabsTestPageForPrescriberDelegate(pagedQuery: PagedQuery, id: string) {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labClient.getLabsTestPageForPrescriberDelegate(pagedQuery, id).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.labTestPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  loadLabTest(labTestId: string, forceRefresh: boolean = false) {
    if (
      !labTestId ||
      (this.selectedLabTestSubject.value &&
        this.selectedLabTestSubject.value.id === labTestId &&
        !forceRefresh)
    ) {
      return;
    }

    this.clearService();

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.client.getLabTest(labTestId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.selectedLabTestSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  setPrescriberDelegatePage(pagedQuery: PagedQuery, prescriberId: string) {
    if (!prescriberId) {
      this.prescriberDelegatePagedListSubject.next(
        new PaginatedList<PrescriberDelegatePrescriber>()
      );
      return;
    }

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.client.getPrescriberDelegateListPaged(pagedQuery, prescriberId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.prescriberDelegatePagedListSubject.next(
              new PaginatedList<PrescriberDelegatePrescriber>()
            );
            this.handleError(outcome);
            return;
          }
          this.prescriberDelegatePagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  setPrescriberPage(pagedQuery: PagedQuery, prescriberId: string) {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.client.getPrescriberListPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.prescriberPagedListSubject.next(new PaginatedList<Prescriber>());
            this.handleError(outcome);
            return;
          }
          this.prescriberPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  setPatientPrescribersPageByPrescriberId(pagedQuery: PagedQuery, prescriberId: string) {
    if (!prescriberId) {
      this.patientPrescriberPagedListSubject.next(new PaginatedList<PatientPrescriber>());
      return;
    }

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.client.getPatientPrescriberListPaged(pagedQuery, prescriberId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.patientPrescriberPagedListSubject.next(
              new PaginatedList<PatientPrescriber>()
            );
            this.handleError(outcome);
            return;
          }
          this.patientPrescriberPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  private handleError(outcome: Outcome) {
    this.prescriberDelegatePagedListSubject.next(
      new PaginatedList<PrescriberDelegatePrescriber>()
    );
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
    this.isLoadingSubject.next(false);
  }

  get patientPrescriberPagedList(): PaginatedList<PatientPrescriber> {
    return this.patientPrescriberPagedListSubject.value;
  }

  get labTestPagedList(): PaginatedList<LabTest> {
    return this.labTestPagedListSubject.value;
  }

  clearService() {
    this.labTestPagedListSubject.next(new PaginatedList<LabTest>());
    this.loggedInPrescriberSubject.next(null);
    this.prescriberDelegatePagedListSubject.next(
      new PaginatedList<PrescriberDelegatePrescriber>()
    );
    this.prescriberPagedListSubject.next(new PaginatedList<Prescriber>());
    this.patientPrescriberPagedListSubject.next(new PaginatedList<PatientPrescriber>());
    this.selectedLabTestSubject.next(null);
  }

  get loggedInPrescriber(): Prescriber {
    return this.loggedInPrescriberSubject.value;
  }
}
