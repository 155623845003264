import { Country } from '@roctavian-abstractions/core';
import { User } from '@roctavian-abstractions/identity';
import { Auditable } from '../interfaces/auditable.interface';
import { State } from './state.model';

export class Address implements Auditable {
  id: string;

  countryId: string;
  country: Country;

  name: string;

  address1: string;

  address2: string;

  city: string;

  stateId: string;
  state: State;

  postalCode: string;

  addUser: User;
  addDate: Date;
  editUser: User;
  editDate: Date;
}
