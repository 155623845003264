import { Injectable } from '@angular/core';
import { Document } from '@app/core/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadMessageService {
  private readonly uploaded = new Subject<Document>();
  private readonly uploading = new Subject<boolean>();

  readonly uploaded$ = this.uploaded.asObservable();
  readonly uploading$ = this.uploading.asObservable();

  uploadComplete(document: Document) {
    this.uploaded.next(document);
  }

  uploadingComplete(value: boolean) {
    this.uploading.next(value);
  }
}
