import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { LabKitOrder } from '../../../shared/models/lab-kit-order.model';
import { LocaleService } from '../../../shared/services/locale.service';
import { OrderSampleShippingKitClient } from '../../clients/order-sample-shipping-kit.client';

@Component({
  selector: 'roctavian-eumea-portal-order-sample-shipping-kit-page',
  templateUrl: './order-sample-shipping-kit-page.component.html',
  styleUrls: ['./order-sample-shipping-kit-page.component.scss'],
})
export class OrderSampleShippingKitPageComponent implements OnInit, OnDestroy {
  isProcessing = false;

  form: FormGroup;
  myGroup: FormGroup;
  private subscriptions = new Array<Subscription>();
  countryList = null;
  europe = ['Austria', 'France', 'Germany', 'Italy', 'Portugal', 'Spain'];

  constructor(
    private client: OrderSampleShippingKitClient,
    private translate: AppTranslationService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private router: Router,
    private localeService: LocaleService
  ) {}

  ngOnInit() {
    this.countryList = this.localeService.supportedCountryList
    .filter(country => this.europe.includes(country.description))
    .sort((a, b) => a.description > b.description ? 1 : -1);

    this.buildForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      quantity: this.formBuilder.control(null, Validators.required),
      nationalId: this.formBuilder.control(null, Validators.required),
      stakeholder: this.formBuilder.group({
        address: this.formBuilder.group({
          countryId: this.formBuilder.control(null, Validators.required),
          name: this.formBuilder.control(null, Validators.required),
          address1: this.formBuilder.control(null, Validators.required),
          address2: this.formBuilder.control(null),
          city: this.formBuilder.control(null, Validators.required),
          postalCode: this.formBuilder.control(null, Validators.required),
        }),
        phone: this.formBuilder.control(null, [Validators.required]),
        email: this.formBuilder.control(null, {
          validators: [Validators.email],
          updateOn: 'blur',
        }),
      }),
    });
  }

  getModel(): LabKitOrder {
    const sampleKit: LabKitOrder = this.form.getRawValue();
    return sampleKit;
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.isProcessing = true;

    this.subscriptions.push(
      this.client.createLabKitOrder(this.getModel()).subscribe(outcome => {
        this.isProcessing = false;
        if (outcome.failure) {
          this.handleError(outcome);
          return;
        }
        this.router.navigate(['/public', 'order-sample-shipping-kit-complete']);
      })
    );
  }
  private handleError(error: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      3000
    );
  }
}
