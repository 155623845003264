import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CoreDirectivesModule,
  MaterialModule,
  PipeModule,
} from '@roctavian-abstractions/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PatientModule } from '../patient/patient.module';
import { SharedModule } from '../shared/shared.module';
import { AddLabUserDialogComponent } from './components/add-lab-user-dialog/add-lab-user-dialog.component';
import { AddLabUserComponent } from './components/add-lab-user/add-lab-user.component';
import { CancelLabTestEditDialogComponent } from './components/cancel-lab-test-edit-dialog/cancel-lab-test-edit-dialog.component';
import { LabOrderEditComponent } from './components/lab-order-edit/lab-order-edit.component';
import { LabOrderListComponent } from './components/lab-order-list/lab-order-list.component.';
import { LabUserFormComponent } from './components/lab-user-form/lab-user-form.component';
import { LabUserListItemComponent } from './components/lab-user-list-item/lab-user-list-item.component';
import { LabUserListComponent } from './components/lab-user-list/lab-user-list.component';
import { RegisterPatientComponent } from './components/register-patient/register-patient.component';
import { RemoveLabUserDialogComponent } from './components/remove-lab-user-dialog/remove-lab-user-dialog.component';
import { LaboratoryUserRoutingModule } from './laboratory-user-routing.module';
import { CompleteLabOrderPageComponent } from './pages/complete-lab-order-page/complete-lab-order-page.component';
import { EditLabTestPageComponent } from './pages/order-edit-page/order-edit-page.component';
import { OrdersPageComponent } from './pages/orders-page/orders-page.component';
import { RegisterPatientPageComponent } from './pages/register-patient-page/register-patient-page.component';
import { UserManagementPageComponent } from './pages/user-management-page/user-management-page.component';

@NgModule({
  declarations: [
    OrdersPageComponent,
    UserManagementPageComponent,
    LabOrderListComponent,
    EditLabTestPageComponent,
    LabOrderEditComponent,
    LabUserListComponent,
    LabUserListItemComponent,
    AddLabUserDialogComponent,
    RemoveLabUserDialogComponent,
    AddLabUserComponent,
    LabUserFormComponent,
    RegisterPatientComponent,
    RegisterPatientPageComponent,
    CompleteLabOrderPageComponent,
    CancelLabTestEditDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    LaboratoryUserRoutingModule,
    CoreDirectivesModule,
    SharedModule,
    NgxMaterialTimepickerModule,
    PatientModule,
  ],
  providers: [DatePipe],
  exports: [OrdersPageComponent],
  entryComponents: [
    AddLabUserDialogComponent,
    RemoveLabUserDialogComponent,
    CancelLabTestEditDialogComponent,
  ],
})
export class LaboratoryUserModule {}
