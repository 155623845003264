import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Stakeholder } from '../../shared';
import { StakeholderClient } from '../clients/stakeholder.client';

@Injectable({
  providedIn: 'root',
})
export class StakeholderService implements OnDestroy {
  private currentStakeholderSubject = new BehaviorSubject<Stakeholder>(null);
  private isLoadingSubject = new BehaviorSubject<boolean>(false);

  private subscriptions = new Array<Subscription>();

  constructor(
    private client: StakeholderClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  loadStakeholderByEmail(email: string, navigateToRouteOnLoad?: any[]) {
    this.currentStakeholderSubject.next(null);
    if (!email) {
      return;
    }

    this.subscriptions.push(
      this.client.getUserByEmail(email).subscribe(
        outcome => {
          if (!outcome.success) {
            return;
          }

          this.currentStakeholderSubject.next(outcome.value);
          if (outcome.value && outcome.value.localeId) {
            this.translate.setLocaleById(outcome.value.localeId);
          }

          if (navigateToRouteOnLoad && navigateToRouteOnLoad.length > 0) {
            this.router.navigate(navigateToRouteOnLoad);
          }
        },
        error => {
          this.handleError(error);
        }
      )
    );
  }

  get currentStakeholder(): Stakeholder {
    return this.currentStakeholderSubject.value;
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000
    );
    this.isLoadingSubject.next(false);
  }

  clearService() {
    this.currentStakeholderSubject.next(null);
  }
}
