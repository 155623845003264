import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { AttestationClient } from '../../clients/attestation.client';
import { getAttestationTypeForStakeholder, StakeholderTypes } from '../../enums';
import { Attestation } from '../../models';
import { StakeholderService } from '../../services/stakeholder.service';

@Component({
  selector: 'roctavian-eumea-portal-user-attestation-page',
  templateUrl: './user-attestation-page.component.html',
  styleUrls: ['./user-attestation-page.component.scss'],
})
export class UserAttestationPageComponent implements OnInit, OnDestroy {
  isPrivacyPolicyViewed = false;
  private subscriptions = new Array<Subscription>();

  constructor(
    private translate: AppTranslationService,
    private attestationClient: AttestationClient,
    private messageService: MessageService,
    private router: Router,
    private stakeholderService: StakeholderService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  submit() {
    if (!this.stakeholderService.currentStakeholder) {
      this.messageService.open(
        this.translate.getTranslation('Common.ErrorProcessingRequest'),
        5000
      );
      return;
    }

    const attestation = new Attestation();
    attestation.stakeholderId = this.stakeholderService.currentStakeholder.id;
    attestation.isCurrent = true;
    let utcDate = new Date();   
    
    attestation.signatureDate = utcDate.toISOString().replace(/T.*/, 'T00:00:00.000Z');
    attestation.hasSignature = true;
    attestation.attestationType = getAttestationTypeForStakeholder(
      this.stakeholderService.currentStakeholder
    );
    attestation.changeReasonDescription = this.translate.getTranslation(
      'Attestation.AttestationChangeReasonDescription'
    );

    this.subscriptions.push(
      this.attestationClient.saveAttestation(attestation).subscribe(
        outcome => {
          if (!outcome.success) {
            this.messageService.open(
              this.translate.getTranslation('Common.ErrorProcessingRequest'),
              3000
            );
            return;
          }

          switch (this.stakeholderService.currentStakeholder.stakeholderType) {
            case StakeholderTypes.Prescriber:
              this.stakeholderService.loadStakeholderByEmail(
                this.stakeholderService.currentStakeholder.email,
                ['/prescriber']
              );
              break;
            case StakeholderTypes.PrescriberDelegate:
              this.stakeholderService.loadStakeholderByEmail(
                this.stakeholderService.currentStakeholder.email,
                ['/prescriber-delegate']
              );
              break;
            case StakeholderTypes.LaboratoryUser:
              this.stakeholderService.loadStakeholderByEmail(
                this.stakeholderService.currentStakeholder.email,
                ['/laboratory-user']
              );
              break;
            case StakeholderTypes.LaboratoryUserDelegate:
              this.stakeholderService.loadStakeholderByEmail(
                this.stakeholderService.currentStakeholder.email,
                ['/laboratory-user-delegate']
              );
              break;
            default:
              this.stakeholderService.loadStakeholderByEmail(
                this.stakeholderService.currentStakeholder.email
              );
              break;
          }
        },
        error => {
          this.messageService.open(
            this.translate.getTranslation('Common.ErrorProcessingRequest'),
            5000
          );
        }
      )
    );
  }

  openPrivacyPolicy() {
    window.open(
      `assets/pdf/${this.translate.getTranslation('Common.PrivacyPolicyPdfName')}`,
      '_blank'
    );
    this.isPrivacyPolicyViewed = true;
  }
}
