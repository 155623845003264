import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { Address } from '../../../shared';
import { LaboratoryUserClient } from '../../clients/laboratory-user.client';
import { LabUser, LabUserLab } from '../../models/lab-user.model';
import { LaboratoryUserService } from '../../service/laboratory-user.service';
import { LabUserFormComponent } from '../lab-user-form/lab-user-form.component';

@Component({
  selector: 'add-lab-user',
  templateUrl: './add-lab-user.component.html',
  styleUrls: ['./add-lab-user.component.scss'],
})
export class AddLabUserComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('labUserForm', { static: false }) labUserForm: LabUserFormComponent;
  errorMessages: string[];
  isProcessing = false;

  private subscriptions = new Array<Subscription>();
  @Output() onSaved = new EventEmitter();
  @Output() onCancelled = new EventEmitter();

  constructor(
    private client: LaboratoryUserClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private laboratoryUserService: LaboratoryUserService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.labUserForm.initializeDuplicateEmailCheck();
  }

  submit() {
    const labUserLab = this.laboratoryUserService.loggedInLaboratoryUserLab;
    this.errorMessages = [];

    this.labUserForm.form.markAllAsTouched();
    if (this.labUserForm.form.invalid || !labUserLab) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(
      this.client.createLabUser(this.getModel()).subscribe(
        outcome => {
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          const newLabUserLab = new LabUserLab();
          newLabUserLab.labId = labUserLab.labId;
          newLabUserLab.labUserId = outcome.value;

          this.subscriptions.push(
            this.client.createLabUserLabAssociation(newLabUserLab).subscribe(
              associationOutcome => {
                this.isProcessing = false;
                if (!associationOutcome.success) {
                  this.handleError(associationOutcome);
                  return;
                }

                this.messageService.open(
                  this.translate.getTranslation('Common.SuccessfullySaved'),
                  5000
                );

                this.onSaved.emit();
              },
              error => {
                this.handleError(error);
              }
            )
          );
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    this.isProcessing = false;
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [
        this.translate.getTranslation('Common.ErrorProcessingRequest'),
      ];
    } else {
      this.errorMessages = error.messages;
    }
  }

  cancel() {
    this.onCancelled.emit();
  }

  private getModel(): LabUser {
    const labUserLab = this.laboratoryUserService.loggedInLaboratoryUserLab;

    const model = this.labUserForm.getModel();
    model.stakeholder.address = new Address();
    model.stakeholder.address.countryId =
      labUserLab.labUser.stakeholder.address.countryId;
    return model;
  }
}
