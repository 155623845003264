import { Lab } from '../../lab/models/lab.model';
import { Auditable } from '../../shared';
import { Prescriber } from './prescriber.model';

export class PrescriberLab extends Auditable {
  id: string;
  prescriberId: string;
  prescriber: Prescriber;
  lab: Lab;
  labId: string;
  isPrescriberOffice: boolean;
}
