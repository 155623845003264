import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '@app/core/enums';
import {
  Outcome,
  PaginatedList,
  ValueOutcome
} from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { PatientPrescriber } from '../../patient/models/patient-prescriber.model';
import { PrescriberDelegatePrescriber } from '../../prescriber-delegate/models/prescriber-delegate.model';
import { PagedQuery } from '../../shared';
import { UtilityService } from '../../shared/services/utility.service';
import { LabTest } from '../models/lab-test.model';
import { PrescriberLab } from '../models/prescriber-lab.model';
import { PrescriberRegistration } from '../models/prescriber-registration.model';
import { Prescriber } from '../models/prescriber.model';

@Injectable({
  providedIn: 'root',
})
export class PrescriberClient {
  constructor(
    private httpClient: HttpClient,
    private utilityService: UtilityService
  ) {}

  getLabTestsByLab(
    pagedQuery: PagedQuery,
    id: string
  ): Observable<ValueOutcome<PaginatedList<LabTest>>> {
    const url = `${Endpoints.labTest}/${id}/lab-tests`;
    return this.httpClient.get<ValueOutcome<PaginatedList<LabTest>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createPrescriber(prescriber: Prescriber): Observable<Outcome> {
    return this.httpClient.post<Outcome>(Endpoints.prescriber, prescriber);
  }

  registerPrescriber(
    prescriberRegistration: PrescriberRegistration
  ): Observable<Outcome> {
    const url = `${Endpoints.prescriber}/register`;
    return this.httpClient.post<Outcome>(url, prescriberRegistration);
  }

  updatePrescriber(prescriber: Prescriber): Observable<Outcome> {
    return this.httpClient.put<Outcome>(Endpoints.prescriber, prescriber);
  }

  getPrescriber(prescriberId: string): Observable<Outcome> {
    const url = `${Endpoints.prescriber}/${prescriberId}`;
    return this.httpClient.get<Outcome>(url);
  }

  getPrescriberByEmail(email: string): Observable<ValueOutcome<Prescriber>> {
    const url = `${Endpoints.prescriber}/getByEmail?email=${encodeURIComponent(
      email
    )}`;
    return this.httpClient.get<Outcome>(url);
  }

  getNationalIdDuplicateCheck(
    nationalId: string
  ): Observable<ValueOutcome<boolean>> {
    const url = `${
      Endpoints.prescriber
    }/duplicateCheck?nationalId=${nationalId}`;
    return this.httpClient.get<ValueOutcome<boolean>>(url);
  }

  getEmailDuplicateCheck(email: string): Observable<ValueOutcome<boolean>> {
    const url = `${
      Endpoints.prescriber
    }/duplicateEmailCheck?email=${encodeURIComponent(email)}`;
    return this.httpClient.get<ValueOutcome<boolean>>(url);
  }

  getPrescriberDelegateListPaged(
    pagedQuery: PagedQuery,
    prescriberId: string
  ): Observable<ValueOutcome<PaginatedList<PrescriberDelegatePrescriber>>> {
    const url = `${
      Endpoints.prescriber
    }/${prescriberId}/PrescriberDelegate/Get`;
    return this.httpClient.get<
      ValueOutcome<PaginatedList<PrescriberDelegatePrescriber>>
    >(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createPrescriberDelegateAssociation(
    prescriberId: string,
    prescriberDelegateId: string
  ): Observable<Outcome> {
    const url = `${
      Endpoints.prescriber
    }/${prescriberId}/PrescriberDelegate/Associate/${prescriberDelegateId}`;
    return this.httpClient.post<Outcome>(url, {});
  }

  disassociatePrescriberDelegate(
    prescriberId: string,
    prescriberDelegateId: string
  ): Observable<Outcome> {
    const url = `${
      Endpoints.prescriber
    }/${prescriberId}/PrescriberDelegate/Disassociate/${prescriberDelegateId}`;
    return this.httpClient.delete<Outcome>(url, {});
  }

  getPrescriberLabListPaged(
    pagedQuery: PagedQuery,
    prescriberId: string
  ): Observable<ValueOutcome<PaginatedList<PrescriberLab>>> {
    const url = `${Endpoints.prescriber}/${prescriberId}/lab`;
    return this.httpClient.get<ValueOutcome<PaginatedList<PrescriberLab>>>(
      url,
      {
        params: this.utilityService.toHttpParams(pagedQuery),
      }
    );
  }

  createPrescriberLabAssociation(
    prescriberLab: PrescriberLab
  ): Observable<Outcome> {
    const url = `${Endpoints.prescriber}/AssociateLab`;
    return this.httpClient.post<Outcome>(url, prescriberLab);
  }

  getPrescriberListPaged(
    pagedQuery: PagedQuery
  ): Observable<ValueOutcome<PaginatedList<Prescriber>>> {
    const url = `${Endpoints.prescriber}`;
    return this.httpClient.get<ValueOutcome<PaginatedList<Prescriber>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPatientPrescriberListPaged(
    pagedQuery: PagedQuery,
    prescriberId: string
  ): Observable<ValueOutcome<PaginatedList<PatientPrescriber>>> {
    const url = `${Endpoints.prescriber}/${prescriberId}/prescriber-patient`;
    return this.httpClient.get<ValueOutcome<PaginatedList<PatientPrescriber>>>(
      url,
      {
        params: this.utilityService.toHttpParams(pagedQuery),
      }
    );
  }

  getLabTest(labTestId: string): Observable<ValueOutcome<LabTest>> {
    const url = `${Endpoints.labTest}/${labTestId}`;
    return this.httpClient.get<ValueOutcome<LabTest>>(url);
  }
}
