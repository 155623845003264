import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { PaginatedList } from '@roctavian-abstractions/web';
import { EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { RegisterPatientInformationComponent } from '../../../patient/components/register-patient-information/register-patient-information.component';
import { PatientPagedQuery } from '../../../patient/models/patient-paged-query.model';
import { Patient } from '../../../patient/models/patient.model';
import { PatientService } from '../../../patient/service/PatientService';
import { Prescriber } from '../../../prescriber/models/prescriber.model';
import { PrescriberService } from '../../../prescriber/services/prescriber.service';
import { PagedQuery, Stakeholder } from '../../../shared';
import { LaboratoryUserClient } from '../../clients/laboratory-user.client';
import { LaboratoryUserService } from '../../service/laboratory-user.service';

@Component({
  selector: 'lab-user-register-patient',
  templateUrl: './register-patient.component.html',
  styleUrls: ['./register-patient.component.css'],
})
export class RegisterPatientComponent implements OnInit, OnDestroy {
  form: FormGroup;
  myGroup: FormGroup;

  @ViewChild(RegisterPatientInformationComponent, { static: false })
  registerPatient!: RegisterPatientInformationComponent;

  isProcessing = false;
  private subscriptions = new Array<Subscription>();
  prescriberPagedQuery = new PagedQuery(1, 10, null, null, true);
  prescriberFormControl = new FormControl(null, Validators.required);
  prescribersList: PaginatedList<Prescriber>;
  selectedPrescriber: Prescriber;
  isPrescriberSelected = false;
  prescriberIsLoading = false;
  private lastPrescriberSearch = '';
  patientPagedQuery = new PatientPagedQuery(1, 10, null, null, true);
  patientFormControl = new FormControl(null, Validators.required);
  patientsList: PaginatedList<Patient>;
  selectedPatient: Patient;
  isPatientSelected = false;
  patientIsLoading = false;
  patientWasNotFound = false;
  patientWasSearched = false;
  private lastPatientSearch = '';

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private labUserService: LaboratoryUserService,
    private labUserClient: LaboratoryUserClient,
    private prescriberService: PrescriberService,
    private patientService: PatientService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.subscriptions.push(
      this.prescriberService.prescriberPagedListSubject.subscribe(collection => {
        this.prescribersList = collection;
      })
    );

    this.subscriptions.push(
      this.prescriberService.isLoadingSubject.subscribe(isLoading => {
        this.prescriberIsLoading = isLoading;
      })
    );

    this.subscriptions.push(
      this.patientService.pagedListSubject.subscribe(collection => {
        this.patientsList = collection;
        if (
          this.patientPagedQuery.filterText !== null &&
          this.patientPagedQuery.filterText.length > 0
        ) {
          this.patientWasNotFound = collection.items.length === 0;
        }

        if (this.patientWasNotFound) {
          this.clearSelectedPatient();
        }
      })
    );

    this.subscriptions.push(
      this.patientService.isLoadingSubject.subscribe(isLoading => {
        this.patientIsLoading = isLoading;
      })
    );

    this.subscriptions.push(
      this.form
        .get('prescriberId')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (searchText == this.getSelectedPrescriberName()) {
              return EMPTY;
            }

            if (searchText === undefined || searchText.length === 0) {
              this.clearSelectedPrescriber();
              this.clearSelectedPatient();
              this.patientWasSearched = false;
              this.patientWasNotFound = false;
            }

            this.prescriberPagedQuery.filterText = searchText;
            this.prescriberPagedQuery.pageIndex = 1;
            this.searchPrescriber();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.form
        .get('patientId')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (searchText == this.getSelectedPatientName()) {
              return EMPTY;
            }

            if (searchText === undefined || searchText.length === 0) {
              this.clearSelectedPatient();
            }

            this.patientWasSearched = true;
            this.patientPagedQuery.filterText = searchText;
            this.patientPagedQuery.pageIndex = 1;
            //     this.patientPagedQuery.PrescriberId = this.selectedPrescriber.id;
            this.searchPatient();
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.prescriberService.prescriberPagedListSubject.next(
      new PaginatedList<Prescriber>()
    );
    this.patientService.pagedListSubject.next(new PaginatedList<Patient>());
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      prescriberId: this.formBuilder.control(null, Validators.required),
      patientId: this.formBuilder.control(null, Validators.required),
      patient: this.formBuilder.group({
        firstName: this.formBuilder.control(null, Validators.required),
        middleInitial: this.formBuilder.control(null, Validators.required),
        lastName: this.formBuilder.control(null, Validators.required),
        dateOfBirth: this.formBuilder.control(null, Validators.required),
        gender: this.formBuilder.control(null, Validators.required),
        medicalRecord: this.formBuilder.control(null, Validators.required),
      }),
    });
  }

  submit() {}

  private searchPrescriber() {
    this.prescriberService.setPrescriberPage(this.prescriberPagedQuery, '');
  }

  onPrescriberSelected(prescriber: Prescriber) {
    this.isPrescriberSelected = true;
    this.selectedPrescriber = prescriber;
  }

  private clearSelectedPrescriber() {
    this.isPrescriberSelected = false;
    this.selectedPrescriber = null;
  }

  private getSelectedPrescriberName() {
    if (this.selectedPrescriber == null) {
      return '';
    }

    return `${this.selectedPrescriber.stakeholder.firstName} ${this.selectedPrescriber.stakeholder.lastName}`;
  }

  private searchPatient() {
    this.patientService.setPage(this.patientPagedQuery);
  }

  onPatientSelected(patient: Patient) {
    this.isPatientSelected = true;
    this.selectedPatient = patient;
    this.registerPatient.setPatient(patient);
  }

  private clearSelectedPatient() {
    this.isPatientSelected = false;
    this.selectedPatient = null;

    const patient = new Patient();
    patient.stakeholder = new Stakeholder();

    this.registerPatient.setPatient(patient);
    this.registerPatient.toggleFormReadonlyStatus(false);
  }

  private getSelectedPatientName() {
    if (this.selectedPatient == null) {
      return '';
    }

    return `${this.selectedPatient.stakeholder.firstName} ${this.selectedPatient.stakeholder.lastName}`;
  }
}
