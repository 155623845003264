import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { StakeholderTypes } from '../../../shared/enums';
import { LaboratoryUserClient } from '../../clients/laboratory-user.client';
import { LabUser } from '../../models/lab-user.model';

@Component({
  selector: 'lab-user-form',
  templateUrl: './lab-user-form.component.html',
  styleUrls: ['./lab-user-form.component.scss'],
})
export class LabUserFormComponent implements OnInit, OnDestroy {
  private subscriptions = new Array<Subscription>();

  @Input() form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private translate: AppTranslationService,
    private client: LaboratoryUserClient
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      stakeholder: this.formBuilder.group({
        firstName: this.formBuilder.control(null, Validators.required),
        lastName: this.formBuilder.control(null, Validators.required),
        phone: this.formBuilder.control(null, Validators.required),
        email: this.formBuilder.control(null, {
          validators: [Validators.required, Validators.email],
          updateOn: 'blur',
        }),
        confirmEmail: this.formBuilder.control(null, [
          Validators.required,
          Validators.email,
        ]),
      }),
    });
  }

  getModel(): LabUser {
    const formValue = this.form.getRawValue();
    const labUser: LabUser = formValue;
    labUser.stakeholder.stakeholderType = StakeholderTypes.LaboratoryUser;

    return labUser;
  }

  initializeDuplicateEmailCheck() {
    this.subscriptions.push(
      this.form.get('stakeholder.email').valueChanges.subscribe(enteredEmail => {
        this.performEmailDuplicateCheck();
      })
    );
  }

  private performEmailDuplicateCheck() {
    const email = this.form.get('stakeholder.email').value;
    if (!email || email.length < 1) {
      return;
    }

    this.subscriptions.push(
      this.client.getEmailDuplicateCheck(email).subscribe(outcome => {
        if (!outcome.success) {
          return;
        }

        if (outcome.value) {
          this.form.get('stakeholder.email').setErrors({ duplicateEmail: true });
        }
      })
    );
  }
}
