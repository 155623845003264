import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { PrescriberDelegatePrescriber } from '../../../prescriber-delegate/models/prescriber-delegate.model';
import { PrescriberClient } from '../../clients/prescriber.client';

@Component({
  selector: 'roctavian-eumea-portal-remove-office-contact-association-dialog',
  templateUrl: './remove-office-contact-association-dialog.component.html',
  styleUrls: ['./remove-office-contact-association-dialog.component.scss'],
})
export class RemoveOfficeContactAssociationDialogComponent implements OnInit, OnDestroy {
  prescriberDelegatePrescriber: PrescriberDelegatePrescriber;
  @Output() prescriberDelegateDisassociated = new EventEmitter();
  errorMessages: string[] = [];
  private subscriptions = new Array<Subscription>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<RemoveOfficeContactAssociationDialogComponent>,
    private client: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {
    this.prescriberDelegatePrescriber = data.prescriberDelegatePrescriber;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  removeAssociation() {
    this.errorMessages = [];

    this.subscriptions.push(
      this.client
        .disassociatePrescriberDelegate(
          this.prescriberDelegatePrescriber.prescriberId,
          this.prescriberDelegatePrescriber.prescriberDelegateId
        )
        .subscribe(
          outcome => {
            if (outcome.success) {
              this.messageService.open(
                this.translate.getTranslation('PrescriberDelegate.SuccessfullyRemoved'),
                3000
              );
              this.prescriberDelegateDisassociated.emit();
              this.reference.close();
              return;
            }

            this.errorMessages = outcome.messages;
          },
          (error: Outcome) => {
            this.messageService.open(
              this.translate.getTranslation('Common.ErrorProcessingRequest'),
              5000
            );
          }
        )
    );
  }
}
