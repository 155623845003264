import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UtilityService } from '../../../shared/services/utility.service';

@Component({
  selector: 'public-insecure-first-visit-dialog',
  templateUrl: './first-visit-dialog.component.html',
  styleUrls: ['./first-visit-dialog.component.scss'],
})
export class FirstVisitDialogComponent implements OnInit {
  constructor(
    public reference: MatDialogRef<FirstVisitDialogComponent>,
    public utilityService: UtilityService
  ) {}

  ngOnInit() {}

  markDialogAsSeen() {
    this.utilityService.markDialogAsSeen();
  }
}
