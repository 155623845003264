import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { RouterModule } from '@angular/router';
import {
  CoreDirectivesModule,
  MaterialModule,
  PipeModule,
} from '@roctavian-abstractions/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SharedModule } from '../shared/shared.module';
import { AddPrescriberDelegateDialogComponent } from './components/add-prescriber-delegate-dialog/add-prescriber-delegate-dialog.component';
import { AddPrescriberDelegateComponent } from './components/add-prescriber-delegate/add-prescriber-delegate.component';
import { CancelLabTestEditDialogComponent } from './components/cancel-lab-test-edit-dialog/cancel-lab-test-edit-dialog.component';
import { LabOrderEditComponent } from './components/lab-order-edit/lab-order-edit.component';
import { LabOrderListComponent } from './components/lab-order-list/lab-order-list.component';
import { PatientDetailComponent } from './components/patient-detail/patient-detail.component';
import { PrescriberDelegatePatientListComponent } from './components/patient-list/patient-list.component';
import { PrescriberDelegateFormComponent } from './components/prescriber-delegate-form/prescriber-delegate-form.component';
import { CompleteLabOrderPageComponent } from './pages/complete-lab-order-page/complete-lab-order-page.component';
import { PrescriberDelegateMyPatientsPageComponent } from './pages/my-patients-page/my-patients-page.component';
import { EditLabTestPageComponent } from './pages/order-edit-page/order-edit-page.component';
import { OrdersPageComponent } from './pages/orders-page/orders-page.component';
import { PrescriberDelegateRoutingModule } from './prescriber-delegate-routing.module';

@NgModule({
  declarations: [
    OrdersPageComponent,
    CompleteLabOrderPageComponent,
    EditLabTestPageComponent,
    LabOrderListComponent,
    LabOrderEditComponent,
    CancelLabTestEditDialogComponent,
    AddPrescriberDelegateComponent,
    PrescriberDelegateFormComponent,
    AddPrescriberDelegateDialogComponent,
    PatientDetailComponent,
    PrescriberDelegatePatientListComponent,
    PrescriberDelegateMyPatientsPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    PrescriberDelegateRoutingModule,
    CoreDirectivesModule,
    SharedModule,
    MatExpansionModule,
    NgxMaterialTimepickerModule,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false },
    },
  ],
  entryComponents: [
    AddPrescriberDelegateDialogComponent,
    CancelLabTestEditDialogComponent,
  ],
  exports: [AddPrescriberDelegateDialogComponent],
})
export class PrescriberDelegateModule {}
